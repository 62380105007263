
const OptionTable = {
    header: [
        { name: "Name", key: "name" },
       
        { name: "Edit", key: "edit"},
     
        { name: "Delete", key: "delete"},

    ],
   
    data: [
    ],
  
};

   
   
  




export default OptionTable













