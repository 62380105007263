import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {
  @Input() field;
  @Output() onButtonClick = new EventEmitter();
  @Output() onButtonSubmit = new EventEmitter();
  isSubmit = false;
  constructor() { 

  }

  ngOnInit() {
    if(typeof this.field.class != "undefined") {
      this.field.class.labelDivClass = 
        typeof this.field.class.labelDivClass != "undefined" ? this.field.class.labelDivClass : "col-md-4";
      this.field.class.inputDivClass = 
        typeof this.field.class.inputDivClass != "undefined" ? this.field.class.inputDivClass : "col-md-8";
    } else {
      this.field.class = {
        labelDivClass: "col-md-4",
        inputDivClass: "col-md-8",
      }
    }
    if(typeof this.field.attr != "undefined") {
      this.field.attr.color = 
        typeof this.field.attr.color != "undefined" ? this.field.attr.color : "";
        this.field.attr.type = 
        typeof this.field.attr.type != "undefined" ? this.field.attr.type : "button";
    } else {
      this.field.attr = {
        color: "",
        type: "button"
      }
    }
  }

  onClick(e,field) {
    if(field.attr.type == "button") {
      this.onButtonClick.emit({ field, event: e})
    } else {
      this.isSubmit = true;
      this.onButtonSubmit.emit({ field, event: e})
    }
  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }

  resetSubmitButton() {
    this.isSubmit = false;
  }
}
