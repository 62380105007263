<ng-container *ngIf="h.row_type == 'patient_view_link'">
    <a [routerLink]="'/patient/case/'+data.patient_case_id">View</a>
</ng-container>
<ng-container *ngIf="h.row_type == 'edit_hospital'">
    <a [routerLink]="'/hospital-branch/'+data.add_hospital_id">Edit</a>
</ng-container>
<ng-container *ngIf="h.row_type == 'branch_list'">
    <a [routerLink]="'/hospital-branch-list/'+data.add_hospital_id">List</a>
</ng-container>
<ng-container *ngIf="h.row_type == 'delete_hospital'">
    <a [routerLink]="'/delete-hospital/'+data.add_hospital_id">Delete</a>
</ng-container>


<ng-container *ngIf="h.row_type == 'update_hospital'">
    <a [routerLink]="'/hospital-formfill/update/'+data.add_hospital_id">Edit</a>
</ng-container>

<ng-container *ngIf="h.row_type == 'edit_branch'">
    <a [routerLink]="'/update-branch/'+data.admin_branch_id">Edit</a>
</ng-container>
<ng-container *ngIf="h.row_type == 'delete_branch'">
    <a [routerLink]="'/delete-branch/'+data.admin_branch_id">Delete</a>
</ng-container>

<ng-container *ngIf="h.row_type == 'delete_user'">
    <a [routerLink]="'/surgeon-delete/'+data.id">Delete</a>
</ng-container>
<ng-container *ngIf="h.row_type == 'change_password_surgeon'">
    <a [routerLink]="'/surgeon-change-password/'+data.id">Change</a>
</ng-container>

<ng-container *ngIf="h.row_type == 'update_user'">
    <a [routerLink]="'/surgeon-update/'+data.id">Update</a>
</ng-container>

<ng-container *ngIf="h.row_type == 'change_password'">
    <a [routerLink]="'/branch-change-password/'+data.admin_branch_id">Change</a>
</ng-container>

<ng-container *ngIf="h.row_type == 'patient_ipd_view_link'">
    <a [routerLink]="'/patient/case/'+data.patient_case_id+'/'+data.booking_patient_id">View</a>
</ng-container>

<ng-container *ngIf="h.row_type == 'patient_ipd_view_link_any'">
    <a class="link-common" [attr.href]="'/patient/case/'+data.patient_case_id+'/'+data.booking_patient_id">
        {{data[h.view_key]}}
    </a>
</ng-container>

<ng-container *ngIf="h.row_type == 'receipt_view_link'">
    <a href="">Receipt</a>
</ng-container>