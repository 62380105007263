import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { configuration } from 'src/app/configuration';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.css']
})
export class TimepickerComponent implements OnInit {
  @Input() field;
  @Output() onValueChange = new EventEmitter();
  form: FormGroup;
  value = "";
  timeHour = [
    "00", "01","02","03","04","05","06","07","08","09","10","11","12"
  ]
  timeMin = [
    "00", "01","02","03","04","05","06","07","08","09","10",
    "11","12","13","14","15","16","17","18","19","20",
    "21","22","23","24","25","26","27","28","29","30",
    "31","32","33","34","35","36","37","38","39","40",
    "41","42","43","44","45","46","47","48","49","50",
    "51","52","53","54","55","56","57","58","59"
  ]
  constructor(public formBuilder: FormBuilder, public _apiService: ApiService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({});
    this.form.addControl(
      this.field.name+'_h',
      new FormControl(this.field.valueH)
    );
    this.form.addControl(
      this.field.name+'_m',
      new FormControl(this.field.valueM)
    );
    this.form.addControl(
      this.field.name+'_a',
      new FormControl(this.field.valueA)
    );
    if(this.field.valueH && this.field.valueM && this.field.valueA) {
      this.value = this.field.valueH+":"+this.field.valueM+" "+this.field.valueA;
    }

    // this.form.controls[this.field.name].valueChanges.subscribe(val => {
    //   console.log(val);
    //   this.field.value = val;
    //   console.log(this.onValueChange);
    //   this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});      
    // });
  }

  setFormFieldValue(name, value) {
    this.value = value;
    // let timeVals = value.split(" ");
    // this.field.valueA = timeVals[1];
    // this.form.controls[this.field.name+"_a"].setValue(this.field.valueA);
    // let timeValsHM = timeVals[0].split(":");
    // this.field.valueH = timeValsHM[0];
    // this.form.controls[this.field.name+"_h"].setValue(this.field.valueH);
    // this.field.valueM = timeValsHM[1];
    // this.form.controls[this.field.name+"_m"].setValue(this.field.valueM);
    console.log("time picker value", value);
  } 

  _onValueChange(e) {

  }

  timeSet(e) {
    this.field.value = e;
    this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});      
  }

  changeValue() {
    this.field.value = this.form.controls[this.field.name+"_h"].value + ":" + this.form.controls[this.field.name+"_m"].value + " " + this.form.controls[this.field.name+"_a"].value;
    this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});      
  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }
}
