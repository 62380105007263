<form [formGroup]="form">
    <div class="form-group row">
        <label *ngIf="field.class" [ngClass]="field.class.offsetDivClass">
            {{field.offsetLabel}}
        </label>
        <label [ngClass]="field.class.labelDivClass">{{field.label}}</label>
        <ng-container *ngIf="field.displayType == 'table'">
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-bordered">
                        <thead>
                            <ng-container *ngIf="field.options && field.options.header">
                                <tr>
        
                                    <ng-container *ngFor="let d of field.options.header">
                                        <th>
                                            {{d}}
                                        </th>
                                    </ng-container>
                                </tr>
                            </ng-container>
        
                        </thead>
                        <tbody>
                            <ng-container *ngIf="field.options && field.options.data">
                                <ng-container *ngFor="let d of field.options.data">
                                    <tr>
                                        <td>
                                            <input
                                                [attr.checked]="(field.value == d[field.options.op_key] || (field.value+'') == d[field.options.op_key]) ? 'checked' : null"
                                                class="form-check-input" (click)="onRadioClick($event,field.options,d)"
                                                [formControlName]="field.name" [name]="field.name" [id]="field.name+'_'+d[field.options.op_key]"
                                                type="radio" [value]="d[field.options.op_key]">
                                            {{d[field.options.op_value]}}
                                        </td>
                                        <ng-container *ngFor="let tdKey of tdKeys">
                                            <td>
                                                {{d[tdKey]}}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        
        </ng-container>
        <ng-container *ngIf="!field.displayType">
            <div [ngClass]="field.class.inputDivClass">
                <ng-container *ngIf="field.options && field.options.data">
                    <ng-container *ngFor="let d of field.options.data">
                        <div class="form-check " [ngClass]="{'form-check-inline' : field.attr.isInline}">
                            <ng-container *ngIf="d.image">
                                <img [src]="d.image"  [ngClass]="d.class ? d.class : ''" />
                                <br />
                            </ng-container>
                            <input
                                [attr.checked]="(field.value == d[field.options.op_key] || (field.value+'') == d[field.options.op_key]) ? 'checked' : null"
                                class="form-check-input" (click)="onRadioClick($event,field.options,d)"
                                [formControlName]="field.name" [name]="field.name"
                                [id]="formParam.name+'_'+field.name+'_'+d[field.options.op_key]" type="radio"
                                [value]="d[field.options.op_key]">

                            <label class="form-check-label cursor"
                                [for]="formParam.name+'_'+field.name+'_'+d[field.options.op_key]">
                                {{d[field.options.op_value]}}
                            </label>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>

    </div>
</form>