import { configuration } from "src/app/configuration";

const FormNewPatient = {
    fieldClass: {
        mainDivClass: "col-md-6"
    },
    fields: [
        {
            type: configuration.FORM.FIELD.TEXT,
            label: "First Name",
            name: "first_name",
            salutation: true,
            validation: {
                required: true
            }
        },
        {
            type: configuration.FORM.FIELD.TEXT,
            label: "Middle Name",
            name: "middle_name",
            validation: {
                required: true
            }
        },
        {
            type: configuration.FORM.FIELD.TEXT,
            label: "Last  Name",
            name: "last_name",
            validation: {
                required: true
            }
        },
        {
            type: configuration.FORM.FIELD.DATEPICKER,
            label: "DOB",
            name: "dob",
            validation: {
                required: true
            }
        },
        {
            type: configuration.FORM.FIELD.NUMBER,
            label: "Age",
            name: "age",
            postfix: {
                class: "",
                text: "Yrs"
            },
            validation: {
                required: true
            }
        },
        {
            type: configuration.FORM.FIELD.RADIO,
            label: "Gender",
            name: "gender",
            options: {
                data: configuration.MALE_FEMALE
            },
            value: 0
        },
        {
            type: configuration.FORM.FIELD.NUMBER,
            label: "Height",
            name: "height",
            postfix: {
                class: "",
                text: "CM"
            },
            validation: {
                required: true
            }
        },
        {
            type: configuration.FORM.FIELD.NUMBER,
            label: "Weight",
            name: "weight",
            postfix: {
                class: "",
                text: "KG"
            },
            validation: {
                required: true
            }
        },
        {
            type: configuration.FORM.FIELD.TEXT,
            label: "BMI",
            name: "bmi",
            isDisabled: true
        },
        {
            type: configuration.FORM.FIELD.NOTE,
            label: "",
            name: "fake"
        },
        {
            type: configuration.FORM.FIELD.TEXT,
            label: "Address for Communication",
            name: "address_for_communication",
            validation: {
                required: true
            }
        },
        {
            type: configuration.FORM.FIELD.NUMBER,
            label: "Phone",
            name: "phone",
            validation: {
                required: true
            }
        },
        {
            type: configuration.FORM.FIELD.TEXT,
            label: "",
            name: "address_for_communication2"
        },
        {
            type: configuration.FORM.FIELD.TEXT,
            label: "Mobile",
            name: "mobile",
            validation: {
                required: true
            }
        },
        {
            type: configuration.FORM.FIELD.TEXT,
            label: "",
            name: "address_for_communication3"
        },
        {
            type: configuration.FORM.FIELD.TEXT,
            label: "Email address",
            name: "email_address",
            validation: {
                required: true
            }
        },
        {
            type: configuration.FORM.FIELD.TEXT,
            label: "City",
            name: "city",
            validation: {
                required: true
            }
        },
        {
            type: configuration.FORM.FIELD.NOTE,
            label: "",
            name: "fake",
        },
        {
            type: configuration.FORM.FIELD.TEXT,
            label: "State",
            name: "state",
            validation: {
                required: true
            }
        },
        {
            type: configuration.FORM.FIELD.NOTE,
            label: "",
            name: "fake",
        },
        {
            type: configuration.FORM.FIELD.TEXT,
            label: "Country",
            name: "country",
            validation: {
                required: true
            }
        },
        {
            type: configuration.FORM.FIELD.NOTE,
            label: "",
            name: "fake",
        },
        {
            type: configuration.FORM.FIELD.NOTE,
            label: "Hospital Information",
            name: "",
            class: {
                labelDivClass: "col-md-12 form-inside-label"
            }
        },
        {
            type: configuration.FORM.FIELD.NOTE,
            label: "",
            name: "fake",
        },
        {
            type: configuration.FORM.FIELD.TEXT,
            label: "UHID",
            name: "uhid",
            validation: {
                required: true
            }
        },
        {
            type: configuration.FORM.FIELD.TEXT,
            label: "Study ID",
            name: "study_id",
            isDisabled: true
        },
    ],
    isSubmitButton: false,
    isDisplayInlineError: true,
    submitButton: {
        type: configuration.FORM.FIELD.BUTTON,
        label: "Submit",
        name: "submit",
        attr: {
            type: "submit",
            color: configuration.FORM.BUTTON.COLOR.PRIMARY,
        },
        buttons: []
    }
};
export default FormNewPatient;
