<div class="container">
  <app-knee-primary-tab  *ngIf="patientData" [patientData]="patientData" [id]="id" [name]="'knee-postop-score'"></app-knee-primary-tab>
  <app-form [formParam]="_formPreopScore"></app-form>
  <h1 class="form-inside-label2">Click for Other scoring systems:</h1>
  <ng-container *ngFor="let score of scores; let i = index">
    <div class="row">
      <div class="col">

        <div class="row border-score" (click)="openModal('knee',i)">
          <div class="col-md-3">
            <img src="assets/images/client-02.png" class="media-object">
          </div>
          <div class="col-md-9">
            <h4 class="media-heading fs-18">{{score["knee"]}}</h4>
            <p class="fs-18">Knee Society Score</p>
          </div>
        </div>

      </div>
      <div class="col">

        <div class="row border-score" (click)="openModal('womac',i)">
          <div class="col-md-3">
            <img src="assets/images/client-02.png" class="media-object">
          </div>
          <div class="col-md-9">
            <h4 class="media-heading fs-18">{{score["womac"]}}</h4>
            <p class="fs-18">WOMAC score</p>
          </div>

        </div>
      </div>
      <div class="col">

        <div class="row border-score" (click)="openModal('sf36',i)">
          <div class="col-md-3">
            <img src="assets/images/client-02.png" class="media-object">
          </div>
          <div class="col-md-9">
            <h4 class="media-heading fs-18">{{score["sf36"]}}</h4>
            <p class="fs-18">SF36</p>
          </div>

        </div>
      </div>
      <div class="col">
        <div class="row border-score" (click)="openModal('kujala',i)">
          <div class="col-md-3">
            <img src="assets/images/client-02.png" class="media-object">
          </div>
          <div class="col-md-9">
            <h4 class="media-heading fs-18">{{score["kujala"]}}</h4>
            <p class="fs-18">Kujala Knee Score</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="row border-score" (click)="openModal('oxford',i)">
          <div class="col-md-3">

            <img src="assets/images/client-02.png" class="media-object">
          </div>
          <div class="col-md-9">
            <h4 class="media-heading fs-18">{{score["oxford"]}}</h4>
            <p class="fs-18">Oxford Knee score</p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row border-score" (click)="openModal('satisfaction',i)">
          <div class="col-md-3">

            <img src="assets/images/client-02.png" class="media-object">
          </div>
          <div class="col-md-9">
            <h4 class="media-heading fs-18">{{score["satisfaction"]}}</h4>
            <p class="fs-18">Satisfaction score</p>
          </div>
        </div>
      </div>
      <div class="col"></div>
      <div class="col"></div>


    </div>
  </ng-container>
  <div class="bottom">
    <button type="button" class="btn btn-primary bottom-button mr-1" (click)="addRow()">Add row</button>
    <button type="button" class="btn btn-primary bottom-button" (click)="submitForm()">Continue</button>
  </div>

</div>

<ng-container *ngIf="modal == 'knee'">
  <div (click)="modal = ''" class="modal-backdrop fade show"></div>
  <div class="modal" tabindex="-1" role="dialog" style="display: block;">
    <div class=" modal-dialog  modal-dialog-big" role="document">
      <div class="modal-content">
        <div class="modal-header grey-div">
          <div class="row c100vw">
            <div class="col-md-4">
              Name: {{patientData.first_name}} {{patientData.middle_name}} {{patientData.last_name}} <br />
              Age: <br />
              Sex:
            </div>
            <div class="col-md-4 text-center">
              <h1 class="bold">
                Knee Score
              </h1>
            </div>
            <div class="col-md-4 text-right">
              <div class="score_div">
                {{currentScore}}
              </div>
              <span class="score-box-close-icon" data-dismiss="modal" (click)="modal = ''">
                <i class="fa fa-remove"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="modal-body fixed-modal-body">
          <app-knee-primary-society-score (changeScoreFinal)="changeScoreFinal($event)"
            (changeScore)="changeScore($event)" [index]="currentIndex" [type]="'post'" [id]="id">
          </app-knee-primary-society-score>
        </div>
        <div class="modal-footer">
          <button (click)="modal = ''" type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="modal == 'womac'">
  <div (click)="modal = ''" class="modal-backdrop fade show"></div>
  <div class="modal" tabindex="-1" role="dialog" style="display: block;">
    <div class=" modal-dialog  modal-dialog-big" role="document">
      <div class="modal-content">
        <div class="modal-header grey-div">
          <div class="row c100vw">
            <div class="col-md-4">
              Name: {{patientData.first_name}} {{patientData.middle_name}} {{patientData.last_name}} <br />
              Age: <br />
              Sex:
            </div>
            <div class="col-md-4 text-center">
              <h1 class="bold">
                Womac Score
              </h1>
            </div>
            <div class="col-md-4 text-right">
              <div class="score_div">
                {{currentScore}}
              </div>
              <span class="score-box-close-icon" data-dismiss="modal" (click)="modal = ''">
                <i class="fa fa-remove"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="modal-body fixed-modal-body">
          <app-knee-primary-womac-score (changeScoreFinal)="changeScoreFinal($event)"
            (changeScore)="changeScore($event)" [index]="currentIndex" [type]="'post'" [id]="id">
          </app-knee-primary-womac-score>
        </div>
        <div class="modal-footer">
          <button (click)="modal = ''" type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="modal == 'sf36'">
  <div (click)="modal = ''" class="modal-backdrop fade show"></div>
  <div class="modal" tabindex="-1" role="dialog" style="display: block;">
    <div class=" modal-dialog  modal-dialog-big" role="document">
      <div class="modal-content">
        <div class="modal-header grey-div">

          <div class="row c100vw">
            <div class="col-md-4">
              Name: {{patientData.first_name}} {{patientData.middle_name}} {{patientData.last_name}} <br />
              Age: <br />
              Sex:
            </div>
            <div class="col-md-4 text-center">
              <h1 class="bold">
                SF36 Score
              </h1>
            </div>
            <div class="col-md-4 text-right">
              <div class="score_div">
                {{currentScore}}
              </div>
              <span class="score-box-close-icon" data-dismiss="modal" (click)="modal = ''">
                <i class="fa fa-remove"></i>
              </span>
            </div>
          </div>

        </div>
        <div class="modal-body fixed-modal-body">
          <app-knee-primary-sf36 (changeScoreFinal)="changeScoreFinal($event)" (changeScore)="changeScore($event)"
            [index]="currentIndex" [type]="'post'" [id]="id"></app-knee-primary-sf36>
        </div>
        <div class="modal-footer">
          <button (click)="modal = ''" type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="modal == 'kujala'">
  <div (click)="modal = ''" class="modal-backdrop fade show"></div>
  <div class="modal" tabindex="-1" role="dialog" style="display: block;">
    <div class=" modal-dialog  modal-dialog-big" role="document">
      <div class="modal-content">
        <div class="modal-header grey-div">

          <div class="row c100vw">
            <div class="col-md-4">
              Name: {{patientData.first_name}} {{patientData.middle_name}} {{patientData.last_name}} <br />
              Age: <br />
              Sex:
            </div>
            <div class="col-md-4 text-center">
              <h1 class="bold">
                Kujala Score
              </h1>
            </div>
            <div class="col-md-4 text-right">
              <div class="score_div">
                {{currentScore}}
              </div>
              <span class="score-box-close-icon" data-dismiss="modal" (click)="modal = ''">
                <i class="fa fa-remove"></i>
              </span>
            </div>
          </div>

        </div>
        <div class="modal-body fixed-modal-body">
          <app-knee-primary-kujala-score (changeScoreFinal)="changeScoreFinal($event)"
            (changeScore)="changeScore($event)" [index]="currentIndex" [type]="'post'" [id]="id">
          </app-knee-primary-kujala-score>
        </div>
        <div class="modal-footer">
          <button (click)="modal = ''" type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="modal == 'oxford'">
  <div (click)="modal = ''" class="modal-backdrop fade show"></div>
  <div class="modal" tabindex="-1" role="dialog" style="display: block;">
    <div class=" modal-dialog  modal-dialog-big" role="document">
      <div class="modal-content">
        <div class="modal-header grey-div">

          <div class="row c100vw">
            <div class="col-md-4">
              Name: {{patientData.first_name}} {{patientData.middle_name}} {{patientData.last_name}} <br />
              Age: <br />
              Sex:
            </div>
            <div class="col-md-4 text-center">
              <h1 class="bold">
                Oxford Score
              </h1>
            </div>
            <div class="col-md-4 text-right">
              <div class="score_div">
                {{currentScore}}
              </div>
              <span class="score-box-close-icon" data-dismiss="modal" (click)="modal = ''">
                <i class="fa fa-remove"></i>
              </span>
            </div>
          </div>

        </div>
        <div class="modal-body fixed-modal-body">
          <app-knee-primary-oxford-score (changeScoreFinal)="changeScoreFinal($event)"
            (changeScore)="changeScore($event)" [index]="currentIndex" [type]="'post'" [id]="id">
          </app-knee-primary-oxford-score>
        </div>
        <div class="modal-footer">
          <button (click)="modal = ''" type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="modal == 'satisfaction'">
  <div (click)="modal = ''" class="modal-backdrop fade show"></div>
  <div class="modal" tabindex="-1" role="dialog" style="display: block;">
    <div class=" modal-dialog  modal-dialog-big" role="document">
      <div class="modal-content">
        <div class="modal-header grey-div">
          <div class="row c100vw">
            <div class="col-md-4">
              Name: {{patientData.first_name}} {{patientData.middle_name}} {{patientData.last_name}} <br />
              Age: <br />
              Sex:
            </div>
            <div class="col-md-4 text-center">
              <h1 class="bold">
                Satisfaction Score
              </h1>
            </div>
            <div class="col-md-4 text-right">
              <div class="score_div">
                {{currentScore}}
              </div>
              <span class="score-box-close-icon" data-dismiss="modal" (click)="modal = ''">
                <i class="fa fa-remove"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="modal-body fixed-modal-body">
          <app-knee-primary-patient-satisfaction-score (changeScoreFinal)="changeScoreFinal($event)"
            (changeScore)="changeScore($event)" [index]="currentIndex" [type]="'post'" [id]="id">
          </app-knee-primary-patient-satisfaction-score>
        </div>
        <div class="modal-footer">
          <button (click)="modal = ''" type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>