import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { configuration } from 'src/app/configuration';
import { SelectComponent } from '../select/select.component';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'app-idproof',
  templateUrl: './idproof.component.html',
  styleUrls: ['./idproof.component.css']
})
export class IdproofComponent implements OnInit {
  @Input() field;
  @Output() onValueChange = new EventEmitter();
  form: FormGroup;
  fieldValue = null;
  @ViewChildren(SelectComponent) fieldselectOneComponent: QueryList<SelectComponent>;
  @ViewChildren(TextComponent) fieldtextComponent: QueryList<TextComponent>;
  fieldText;
  constructor(public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.fieldText = JSON.parse(JSON.stringify(this.field));
    this.fieldText.type = configuration.FORM.FIELD.TEXT;
    this.fieldText.name = this.fieldText.name + "_value";
    this.fieldText.isDisplayLabel =  false;
    this.field.options.op_key = 'id_proof_id';
    this.field.options.op_value = 'id_proof_name';
    this.fieldValue = JSON.parse(JSON.stringify(this.field));
    this.form = this.formBuilder.group({});
    this.fieldValue.isDisplayLabel = false;
    this.field.isDisplayLabel = false;
    // this.field.class = {
    //   labelDivClass: "",
    //   inputDivClass: "col-md-12"
    // }
    this.fieldValue.class = {
      labelDivClass: "",
      inputDivClass: "col-md-12"
    }
  }

    // Set Select box options 
    setFormFieldOptions(name, value, attrname) {
      this.field.options[attrname] = value;
    }


  setFormFieldValue(name, value) {
    if(typeof value.id_proof_id != "undefined") {
      // this.field.value = value.id_proof_id;
      // this.form.controls[this.field.name].setValue(value.id_proof_id);
      const fieldselectComponent:any = this.fieldselectOneComponent.toArray();
      if(fieldselectComponent && fieldselectComponent[0]) {
        fieldselectComponent[0].setFormFieldValue(this.field.name, value.id_proof_id);
      }
    }
    if(typeof value.id_proof_value != "undefined") {
      // this.field.value = value.id_proof_value;
      // this.form.controls[this.field.name+"_value"].setValue(value.id_proof_value);
      const fieldtextComponent:any = this.fieldtextComponent.toArray();
      if(fieldtextComponent && fieldtextComponent[0]) {
        fieldtextComponent[0].setFormFieldValue(this.field.name+"_value", value.id_proof_value);
      }
    }

  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }
}
