import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-row-function-link',
  templateUrl: './table-row-function-link.component.html',
  styleUrls: ['./table-row-function-link.component.css']
})
export class TableRowFunctionLinkComponent implements OnInit {
  @Input() h;
  @Input() data;
  // @Input() branchData;
  
  constructor() { 
  }

  ngOnInit(): void {

  }

}
