import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { configuration } from 'src/app/configuration';
import { SelectComponent } from '../select/select.component';
import { TextComponent } from '../text/text.component';
@Component({
  selector: 'app-payment-box-with-amount',
  templateUrl: './payment-box-with-amount.component.html',
  styleUrls: ['./payment-box-with-amount.component.css']
})
export class PaymentBoxWithAmountComponent implements OnInit {

  @Input() field;
  @Output() onValueChange = new EventEmitter();
  form: FormGroup;
  fieldValue = null;
  @ViewChildren(SelectComponent) fieldselectOneComponent: QueryList<SelectComponent>;
  @ViewChildren(TextComponent) fieldtextComponent: QueryList<TextComponent>;
  fieldText;
  pValue = {
    svalue: 1,
    tvalue: 0
  }
  constructor(public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.fieldText = JSON.parse(JSON.stringify(this.field));
    this.fieldText.value = this.field.tvalue ? this.field.tvalue : 0;
    this.fieldText.type = configuration.FORM.FIELD.TEXT;
    this.fieldText.name = this.fieldText.name + "_value";
    this.fieldText.isDisplayLabel =  false;
    this.field.options = {};
    this.field.options.op_key = 'master_payment_mode_id';
    this.field.options.op_value = 'master_payment_mode_name';
    this.field.value = this.field.svalue ? this.field.svalue : 0; 
    this.pValue = {
      svalue: this.field.value,
      tvalue: this.fieldText.value
    }
    this.fieldValue = JSON.parse(JSON.stringify(this.field));
    this.form = this.formBuilder.group({});
    this.fieldValue.isDisplayLabel = false;
    this.field.isDisplayLabel = false;
    // this.field.class = {
    //   labelDivClass: "",
    //   inputDivClass: "col-md-12"
    // }
    this.fieldValue.class = {
      labelDivClass: "",
      inputDivClass: "col-md-12"
    }
  }

    // Set Select box options 
    setFormFieldOptions(name, value, attrname) {
      console.log("value", value);
      this.field.options[attrname] = value;
    }


  setFormFieldValue(name, value) {
    if(typeof value.svalue != "undefined") {
      this.pValue.svalue = value.svalue;
      const fieldtextareaComponent:any = this.fieldselectOneComponent.toArray();
      if(fieldtextareaComponent && fieldtextareaComponent[0]) {
        fieldtextareaComponent[0].setFormFieldValue(this.field.name, value.svalue);
      }
    }
    if(typeof value.tvalue != "undefined") {
      this.pValue.tvalue = value.tvalue;
      const fieldtextareaComponent:any = this.fieldtextComponent.toArray();
      if(fieldtextareaComponent && fieldtextareaComponent[0]) {
        fieldtextareaComponent[0].setFormFieldValue(this.fieldText.name, value.tvalue);
      }
    }

  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }

  onValueChangeInput(e) {
    switch(e.field.name) {
      case this.field.name:
        this.pValue.svalue = e.field.value;
        break;
      case this.fieldText.name:
        this.pValue.tvalue = e.field.value;
        break;
    }
    this.field.value = this.pValue;
    this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});
  }
}
