import { configuration } from "src/app/configuration";

const FormChangePassword = {
  fields: [
    {
      type: configuration.FORM.FIELD.PASSWORD,
      label: "Current Password",
      name: "old_password",
      validation: {
        required: true
      }
    },
    {
      type: configuration.FORM.FIELD.PASSWORD,
      label: "New Password",
      name: "new_password",
      validation: {
        required: true
      }
    },
    {
      type: configuration.FORM.FIELD.PASSWORD,
      label: "Confirm Password",
      name: "confirm_password",
      validation: {
        required: true
      }
    },
  ],
  isSubmitButton: true,
  isDisplayInlineError: true,
  submitButton: {
    type: configuration.FORM.FIELD.BUTTON,
    label: "Update",
    name: "submit",
    attr: {
      type: "submit",
      color: configuration.FORM.BUTTON.COLOR.PRIMARY,
    },
    buttons: []
  }
};
export default FormChangePassword;
