import { configuration } from 'src/app/configuration';

const FormHipPrimaryHarrisSection3Score = {
  name: "harris_section_score_3",
  fields: [
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Total degrees of Flexion',
      name: 'total_degrees_of_flexion',
      options: {
        data: [
          {
            op_key: "0",
            op_value: 'None',
          },
          {
            op_key: "0.4",
            op_value: '0 > 8',
          },
          {
            op_key: "0.8",
            op_value: '8 > 16',
          },
          {
            op_key: "1.2",
            op_value: '16 > 24',
          },
          {
            op_key: "1.6",
            op_value: '24 > 32'
          },
          {
            op_key: "2.0",
            op_value: '32 > 40',
          },
          {
            op_key: "2.25",
            op_value: '40 > 45',
          },
          {
            op_key: "2.55",
            op_value: '45 > 55',
          },
          {
            op_key: "2.85",
            op_value: '55 > 65',
          },
          {
            op_key: "3",
            op_value: '65 > 70'
          },
          {
            op_key: "3.15",
            op_value: '70 > 75'
          },
          {
            op_key: "3.3",
            op_value: '75 > 80'
          },
          {
            op_key: "3.6",
            op_value: '80 > 90'
          },
          {
            op_key: "3.75",
            op_value: '90 > 100'
          },
          {
            op_key: "3.9",
            op_value: '100 > 110'
          },

        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Total degrees of Abduction',
      name: 'total_degrees_of_abduction',
      options: {
        data: [
          {
            op_key: "0",
            op_value: 'None',
          },
          {
            op_key: "0.20",
            op_value: '0 > 5',
          },
          {
            op_key: "0.40",
            op_value: '5 > 10',
          },
          {
            op_key: "0.60",
            op_value: '10 > 15',
          },
          {
            op_key: "0.65",
            op_value: '15 > 20'
          },

        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Total degrees of Ext Rotation',
      name: 'total_degrees_of_ext_rotation',
      options: {
        data: configuration.DEGREE
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Total degrees of Adduction',
      name: 'total_degrees_of_ext_adduction',
      options: {
        data: configuration.DEGREE_0
      }
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: 'Range of motion',
      name: 'range_of_motion',
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: 'The Harris Hip Score is',
      name: 'the_harris_hip_score_is',
    },
  ]
};
export default FormHipPrimaryHarrisSection3Score;
