import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-delete-hospital',
  templateUrl: './delete-hospital.component.html',
  styleUrls: ['./delete-hospital.component.css']
})
export class DeleteHospitalComponent implements OnInit {
  id = "";
  isOpenModal: any = true;
  constructor(private router: ActivatedRoute, private apiService: ApiService, public shared: SharedService, private location: Location) {
    this.id = this.router.snapshot.params.id;
    console.log(this.id)
  }

  ngOnInit(): void {
  }

  async onDelete() {
    console.log(this.id)
    console.log("delete")
    let res = await this.apiService.COMMON_API_deleteSingleRecord("hospital", this.id);
    console.log(res)
    this.isOpenModal = false;
    this.location.back();
  }

  async closeDeleteBox() {
    this.isOpenModal = false;
    this.location.back();
  }

}
