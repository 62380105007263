import { Component, OnInit } from '@angular/core';
import OptionTable from 'src/app/helpers/tables/option-table';
import AddOption from 'src/app/helpers/forms/add-options';

@Component({
  selector: 'app-primary-knee-options',
  templateUrl: './primary-knee-options.component.html',
  styleUrls: ['./primary-knee-options.component.css']
})
export class PrimaryKneeOptionsComponent implements OnInit {
  constructor() { }
  formArray = [
    { label: "Etiology", form: OptionTable, displayAdd: false },
    { label: "Current Symptoms", form: OptionTable, displayAdd: false },
    { label: "Co morbidities", form: OptionTable, displayAdd: false },
    { label: "Previous knee surgeries", form: OptionTable, displayAdd: false },
    { label: "patella", form: OptionTable, displayAdd: false },
    { label: "procedure", form: OptionTable, displayAdd: false },
    { label: "Anaesthesia", form: OptionTable, displayAdd: false },
    { label: "Arthrotomy", form: OptionTable, displayAdd: false },
    { label: "varus", form: OptionTable, displayAdd: false },
    { label: "valgus", form: OptionTable, displayAdd: false },
    { label: "Flexion", form: OptionTable, displayAdd: false },
    { label: "Defect Reconstruction", form: OptionTable, displayAdd: false },
    { label: "Antibiotic", form: OptionTable, displayAdd: false },
    { label: "Mechanical", form: OptionTable, displayAdd: false },
    { label: "Pharmacological", form: OptionTable, displayAdd: false },
  ]
 
  _addOption = AddOption
  ngOnInit(): void {
  }

}
