import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from "@angular/core";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { TimepickerComponent } from '../timepicker/timepicker.component';
import { DatepickerComponent } from '../datepicker/datepicker.component';

@Component({
  selector: 'app-dateandtime',
  templateUrl: './dateandtime.component.html',
  styleUrls: ['./dateandtime.component.css']
})
export class DateandtimeComponent implements OnInit {

  @Input() field;
  @Output() onValueChange = new EventEmitter();
  form: FormGroup;
  field1 = null;
  field2 = null;
  field3 = null;
  isDisplayValidation = false;
  @ViewChildren(DatepickerComponent) fielddateComponent: QueryList<DatepickerComponent>;
  @ViewChildren(TimepickerComponent) fieldtimeComponent: QueryList<TimepickerComponent>;

  constructor(public formBuilder: FormBuilder) {}


  ngOnInit(): void {
    this.field1 = JSON.parse(JSON.stringify(this.field));
    this.field2 = JSON.parse(JSON.stringify(this.field));
    this.field1.isDisplayLabel = false;
    this.field2.isDisplayLabel = false;
    this.field1.class = {
      inputDivClass: "col-md-12",
    };
    this.field2.class = {
      inputDivClass: "col-md-12",
    };
    this.form = this.formBuilder.group({});
    this.form.addControl(this.field.name, new FormControl(this.field.value));
    this.form.addControl(this.field1.name, new FormControl(this.field.value));
    this.form.addControl(this.field2.name, new FormControl(this.field.value));
  }

  setFormFieldValue(name, value) {
    if(value.time) {
      const formComponent:any = this.fieldtimeComponent.toArray();
      formComponent[0].setFormFieldValue(name, value.time);
    }
    // this.field.value = value;
    // this.form.controls[this.field.name] = value;
  }  

  _onValueChange(e) {

  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }

  isRequired() {
    return (this.field.validation && this.field.validation.required);
  }

  isFieldValid() {
    this.isDisplayValidation = true;
    return null;
  }

  get _form () {
    return this.form.controls;
  }
}
