<form [formGroup]="form">
    <div class="row">
        <label [ngClass]="field.class.labelDivClass" *ngIf="field.isDisplayLabel">{{field.label}}</label>
        <div [ngClass]="field.class.inputDivClass">
            <div class="row">
                <div class="col-12">
                    <app-textarea  #fieldtextareaComponent (onValueChange)="onValueChangeInput($event)" [field]="addressField"></app-textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <app-select  #fieldselectOneComponent (onValueChange)="getState($event)" [field]="countryField"></app-select>
                </div>
                <div class="col-4">
                    <app-select  #fieldselectOneComponent (onValueChange)="getCity($event)" [field]="stateField"></app-select>
                </div>
                <div class="col-4">
                    <app-select  #fieldselectOneComponent (onValueChange)="onValueChangeInput($event)" [field]="cityField"></app-select>
                </div>                
            </div>
        </div>
    </div>
</form>