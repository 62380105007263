<div class="table-responsive" [ngClass]="tableParam.class ? tableParam.class : ''">
    <table class="table-grid table common-box-shadow" [ngClass]="tableParam.class ? tableParam.class : ''"
        [dtTrigger]="dtTrigger" datatable [dtOptions]="dtOptions">
        <thead *ngIf="tableParam.header.length > 0 && tableParam.isHeader">
            <tr>
                <th *ngIf="tableParam.hasSRNo" class="no-wrap">
                    Sr. No.
                </th>
                <th [ngClass]="[h.class ? h.class : '']" class="no-wrap" *ngFor="let h of tableParam.header">
                    {{h.name}}
                </th>
            </tr>
        </thead>
        <tbody *ngIf="tableParam.data.length > 0">
            <tr [ngClass]="[currentSelectedRow == i ? 'selected-row': '', data.class ? data.class : '']"
                *ngFor="let data of tableParam.data; let i = index">
                <td *ngIf="tableParam.hasSRNo">
                    {{ i + (currentPage*recordsFiltered) + 1}}
                </td>
                <td [ngClass]="[currentSelectedColumn == j ? 'selected-row': '', h.class ? h.class : '']"
                    *ngFor="let h of tableParam.header; let j = index" (click)="selectRowColumn(i,j)">
                    <ng-container *ngIf="!h.type">
                        <ng-container *ngIf="h.key == 'row_action'">
                            <ng-container *ngIf="h.actions && h.actions.length > 0">
                                <ng-container *ngFor="let eachAction of h.actions">
                                    <ng-container *ngIf="eachAction.type == _shared._config.ROW_ACTION.EDIT">
                                        <i (click)="editRecord(eachAction, data)" class="fa fa-edit cursor mr-1"></i>
                                    </ng-container>
                                    <ng-container *ngIf="eachAction.type == _shared._config.ROW_ACTION.DELETE">
                                        <i (click)="deleteRecord(eachAction, data)" class="fa fa-times cursor"></i>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="h.key == 'table-row-function'">
                            <app-table-row-function [h]="h" [data]="data"></app-table-row-function>
                        </ng-container>
                        <ng-container *ngIf="h.key != 'row_action' && h.key != 'table-row-function'">
                            <div [innerHTML]="getValue(data,h)"></div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="h.type == 'link'">
                        <a [routerLink]="[h.link.url+data[h.key]]">{{h.link.label}}</a>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div *ngIf="pages && pages.length > 0" class="mt-3 seperator paginationnew">
    <div class="dataTables_info float-left">
        Showing {{offset+1}} to {{ isLess(offset, recordsFiltered, recordsTotal) ? (offset+recordsFiltered) :
        recordsTotal}}
        of {{recordsTotal}} entries 
    </div>
    <div class="dataTables_paginate paging_simple_numbers  float-right">
        <!-- <a class="paginate_button previous disabled">Previous</a> 
        <span *ngIf="pages" [innerHTML]=changePaginationHtml()> -->
        <span *ngIf="newAraryPagination">
            <ul class="pagination">
                <ng-container *ngFor="let p of newAraryPagination" >                    
                    <li class="page-item" *ngIf ="p == 'Previous'">
                        <a class="paginate_button cursor page-link" (click)="changePaginationHtmlPm(p)">
                            Previous
                        </a>
                    </li>
                    <li class="page-item" [ngClass]="((currentPage+1) == p ? 'active' : '') " *ngIf ="p != 'Next' && p != 'Previous'">
                        <a class="paginate_button cursor page-link" (click)="changePage(p)">
                            {{p}}
                        </a>
                    </li>
                    <li class="page-item" *ngIf ="p == 'Next'">
                        <a class="paginate_button cursor page-link" (click)="changePaginationHtmlP(p)">
                            Next
                        </a>
                    </li>
                </ng-container>
            </ul>
        </span>
    </div>
</div>