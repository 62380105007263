import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-text",
  templateUrl: "./text.component.html",
  styleUrls: ["./text.component.css"],
})
export class TextComponent implements OnInit {
  @Input() field;
  @Output() onValueChange = new EventEmitter();
  form: FormGroup;
  isDisplayValidation = false;

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit() {
    this.form = this.formBuilder.group({});
    this.form.addControl(this.field.name, new FormControl(this.field.value));

    if(this.field.validation && this.field.validation.required) {
      this.form.controls[this.field.name].setValidators(Validators.required);
    }

    this.form.controls[this.field.name].valueChanges.subscribe((val) => {
      this.field.value = val;
      this.onValueChange.emit({
        field: this.field,
        form: this.form,
        control: this.form.controls[this.field.name],
      });
    });
  }

  isRequired() {
    return (this.field.validation && this.field.validation.required);
  }

  setFormFieldValue(name, value) {
    this.field.value = value;
    this.form.controls[this.field.name].setValue(value);
  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }
  
  getFormFieldValue() {
    return {
      field: this.field,
      form: this.form,
      control: this.form.controls[this.field.name],
    };
  }

  isFieldValid() {
    this.isDisplayValidation = true;
    if(this.form.controls[this.field.name].errors) {
      return {
        [this.field.name]: this.form.controls[this.field.name].errors
      };
    }
    return null;
  }

  get _form () {
    return this.form.controls;
  }

  onSelect(event:any){
    console.log("event",event)
    console.log(this.form.controls)
  }

}
