<form [formGroup]="form">
    <div class="form-group row">
        <div [ngClass]="field.class.labelDivClass">
            <label *ngIf="field.isDisplayLabel">{{field.label}} 
                <span *ngIf="isRequired()" class="text-danger">*</span>
            </label>
        </div>
        <div [ngClass]="field.class.inputDivClass">    
        	<div class="input-group">
            	<input type="text" class="form-control"  (keydown)="_shared.allowNumber($event)" [value]="field.value" [formControlName]="field.name" [placeholder]="field.placeholder" />
            	<i class="form-group__bar"></i>
                <div class="input-group-append" *ngIf="field.postfix">
                    <span class="input-group-text" id="inputGroupPrepend3">
                        <ng-container *ngIf="field.postfix.class">
                            <i [class]="field.postfix.class"></i>
                        </ng-container>
                        <ng-container *ngIf="field.postfix.text">
                            {{field.postfix.text}}
                        </ng-container>
                    </span>
                </div>
            </div>	
        </div>
    </div>
    <ng-container *ngIf="field.isDisplayInlineError && isDisplayValidation && _form[field.name] && _form[field.name].errors">
        <ng-container *ngIf="_form[field.name].errors.required">
            <div class="row">
                <div [ngClass]="field.class.labelDivClass" *ngIf="field.isDisplayLabel"></div>
                <div [ngClass]="field.class.inputDivClass">
                    <span class="color-red">
                        This field is required!
                    </span>
                </div>
            </div>
        </ng-container>
    </ng-container>
</form>