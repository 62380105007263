import { Component, OnInit } from '@angular/core';
import  KneeDesign from "src/app/helpers/forms/knee-design";
import KneeTableDesign from 'src/app/helpers/tables/knee-tabledesign';

@Component({
  selector: 'app-knee-design',
  templateUrl: './knee-design.component.html',
  styleUrls: ['./knee-design.component.css']
})
export class KneeDesignComponent implements OnInit {
  _kneeForm = KneeDesign;
  _kneeTable = KneeTableDesign;
  constructor() { }

  ngOnInit(): void {
  }

}
