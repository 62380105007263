import { Component, Input, OnInit, QueryList, ViewChildren, EventEmitter, Output } from '@angular/core';
import { FormComponent } from 'src/app/common/form/form.component';
import { configuration } from 'src/app/configuration';
import FormKneePrimarySf36Score from 'src/app/helpers/forms/knee-primary-sf36-score-form';
import { ApiService } from 'src/app/service/api.service';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-knee-primary-sf36',
  templateUrl: './knee-primary-sf36.component.html',
  styleUrls: ['./knee-primary-sf36.component.css']
})
export class KneePrimarySf36Component implements OnInit {
  @Input() id;
  @Input() type;
  @Input() index;  
  @Output() changeScore = new EventEmitter();
  @Output() changeScoreFinal = new EventEmitter();
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;
  score = 0;
  _formKneePrimarySf36Score = JSON.parse(JSON.stringify(FormKneePrimarySf36Score));
  currentData = null;
  tableName = configuration.TABLE.KNEE_PRE_OP_SFTHREESIX;
  
  constructor(public _apiService: ApiService, public _shared: SharedService) { }

  ngOnInit(): void {
    if(this.type == "post") {
      this.tableName = configuration.TABLE.KNEE_POST_OP_SFTHREESIX
    }
    this.getData();
  }

  async getData() {
    let json = {
      where: {
        patient_id: this.id,
        index: this.index
      }
    };
    let res = await this._apiService.COMMON_API_executeQuery(this.tableName, json);
    if (res && res.data && res.data[0]) {
      this.currentData = res.data[0];
      let _currentData = JSON.parse(res.data[0].data);
      setTimeout(async () => {
        let formComponent = this.formComponent.toArray();
        for(let f of formComponent) {
          f.setAllFormFieldValue(_currentData);
        }
      });
    }
  }

  async submit() {
    let data = {};
    const formComponent: any = this.formComponent.toArray();
    if(formComponent) {
      for(let f of formComponent) {
        let formValues = f.getFormValue();
        data = formValues.form.value;
      }
    }
    let json = {
      patient_id: this.id,
      data: JSON.stringify(data),
      score: this.score,
      index: this.index
    }
    this._shared.isLoader = true;
    if(this.currentData) {
      let res = await this._apiService.COMMON_API_updateSingleRecord(this.tableName, json, this.currentData.id);
    } else {
      let res = await this._apiService.COMMON_API_insertSingleRecord(this.tableName, json);
    }
    this.changeScoreFinal.emit({
      type: "sf36",
      value: this.score
    });
    this._shared.isLoader = false;
  }

  onFormFieldOnChange(e) {
    this.score = 0;
    if(this.formComponent) {
      const formComponent: any = this.formComponent.toArray();
      if(formComponent) {
        for(let f of formComponent) {
          let form = f.getFormValue();
          let formValues = form.form.value;
          for(let f of form.formParam.fields) {
            if(f.type == "RADIO") {
              if(formValues[f.name]) {
                this.score = this.score + parseInt(formValues[f.name]);
              }
            }
          }
        }
      }
      this.changeScore.emit({
        type: "sf36",
        value: this.score
      });
    }
  }

}
