import { configuration } from 'src/app/configuration';

const FormKneePrimaryWomacScorePlain = {
  name: "plain",
  fields: [
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '1. Walking',
      name: 'walking',
      offsetLabel: "Pain",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '2. Stair Climbing',
      name: 'stair_climbing',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '3. Nocturnal',
      name: 'nocturnal',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '4. Rest',
      name: 'rest',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '5. Weight Bearing',
      name: 'weight_bearing',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },

  ],

};
export default FormKneePrimaryWomacScorePlain;

