import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormComponent } from '../common/form/form.component';
import { configuration } from '../configuration';
import FormPreOpScores from '../helpers/forms/pre-op-scores-form';
import TablePreOpScores from '../helpers/tables/pre-op-scores-table';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'app-pre-op-scores',
  templateUrl: './pre-op-scores.component.html',
  styleUrls: ['./pre-op-scores.component.css']
})
export class PreOpScoresComponent implements OnInit {

  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;
  _FormPreOpScores = FormPreOpScores;
  _TablePreOpScores = TablePreOpScores;

  constructor(public _apiService: ApiService, public shared: SharedService) {
    this.shared.currentPage = "report";
  }

  ngOnInit(): void {
  }

  async onSubmit(e) {
    console.log(e.form.value);
    const formComponent: any = this.formComponent.toArray();
    if (formComponent && formComponent[0]) {
      let errors = formComponent[0].runValidation();
      if (errors.length == 0) {
        console.log("formComponent", formComponent[0]);
        let formValues = formComponent[0].getFormValue();
        if (formValues.form.value) {
          let values = JSON.parse(JSON.stringify(formValues.form.value));
          let res = await this._apiService.POST_apiRequest(configuration.API_ENDPOINT.GET_PRE_OP_SCORES, e.form.value);
          if (formComponent && formComponent[0]) {
            formComponent[0].resetSubmitButton();
          }
          if (res && res.data) {
            this._TablePreOpScores.data = res.data;
          }
          console.log(res)
        }
      } else {
        formComponent[0].resetSubmitButton();
      }
    }
  }


}
