import { configuration } from "src/app/configuration";

const FormPreopScore = {
  fields: [
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Duration after surgery Months",
      name: "duration_surgery_month"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Duration after surgery Years",
      name: "duration_surgery_years"
    },

  ],
 
};
export default FormPreopScore;
