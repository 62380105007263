<form [formGroup]="form">
    <div class="form-group row">
        <div [ngClass]="field.class.labelDivClass">
            <label *ngIf="field.isDisplayLabel">
                {{field.label}}
                <span *ngIf="isRequired()" class="text-danger">*</span>
            </label>
        </div>
        <div [ngClass]="field.class.inputDivClass">
            <mat-form-field>
                <div class="input-group">
                    <input [formControlName]="field.name" [value]="field.value" matInput [matDatepicker]="picker"
                        (dateChange)="addEvent('change', $event)">
                    <i class="form-group__bar"></i>
                </div>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <ng-container
        *ngIf="field.isDisplayInlineError && isDisplayValidation && _form[field.name] && _form[field.name].errors">
        <ng-container *ngIf="_form[field.name].errors.required">
            <div class="row">
                <div [ngClass]="field.class.labelDivClass" *ngIf="field.isDisplayLabel"></div>
                <div [ngClass]="field.class.inputDivClass">
                    <span class="color-red">
                        This field is required!
                    </span>
                </div>
            </div>
        </ng-container>
    </ng-container>
</form>