import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SelectComponent } from '../select/select.component';
import { ApiService } from 'src/app/service/api.service';
import { configuration } from 'src/app/configuration';
import { state } from '@angular/animations';
import { TextareaComponent } from '../textarea/textarea.component';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {
  @Input() field;
  @Output() onValueChange = new EventEmitter();
  @ViewChildren(SelectComponent) fieldselectOneComponent: QueryList<SelectComponent>;
  @ViewChildren(TextareaComponent) fieldtextareaComponent: QueryList<TextareaComponent>;

  form: FormGroup;
  addressField = null;
  countryField = null;
  stateField = null;
  cityField = null;
  addressValue = {
    address: "",
    city_id: 0,
    state_id: 0,
    country_id: 0
  }

  constructor(public formBuilder: FormBuilder, public _apiService: ApiService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({});

    this.addressField = JSON.parse(JSON.stringify(this.field));
    this.countryField = JSON.parse(JSON.stringify(this.field));
    this.stateField = JSON.parse(JSON.stringify(this.field));
    this.cityField = JSON.parse(JSON.stringify(this.field));

    this.countryField.name = "country_id";
    this.stateField.name = "state_id";
    this.cityField.name = "city_id";


    this.addressField.isDisplayLabel = false;
    this.countryField.isDisplayLabel = false;
    this.stateField.isDisplayLabel = false;
    this.cityField.isDisplayLabel = false;

    this.countryField.selectLabel = "Select Country";
    this.stateField.selectLabel = "Select State";
    this.cityField.selectLabel = "Select City";

    this.addressField.class = {
      labelDivClass: "",
      inputDivClass: "col-md-12"
    }
    this.countryField.class = {
      labelDivClass: "",
      inputDivClass: "col-md-12"
    }
    this.stateField.class = {
      labelDivClass: "",
      inputDivClass: "col-md-12"
    }
    this.cityField.class = {
      labelDivClass: "",
      inputDivClass: "col-md-12"
    }

    this.countryField.options = {
      op_key: "country_id",
      op_value: "country_name"
    }

    this.stateField.options = {
      op_key: "state_id",
      op_value: "state_name"
    }

    this.cityField.options = {
      op_key: "city_id",
      op_value: "city_name"
    }
      

    this.form.addControl(
      this.field.name,
      new FormControl(this.field.value)
    );
  }

  async getState(e) {
    // const stateData = await this._apiService.COMMON_API_getSingleRecordByID(configuration.TABLE.MASTER_STATE, "country_id", e.field.value);
    // if(stateData && !stateData.is_error && stateData.data) {
    //   this.stateField.options.data = stateData.data;
    //   const fieldselectTwoComponent:any = this.fieldselectOneComponent.toArray();
    //   if(fieldselectTwoComponent && fieldselectTwoComponent[1]) {
    //     fieldselectTwoComponent[1].setFormFieldOptions("state_id", stateData.data, "data");
    //     this.onValueChangeInput(e);
    //   }
    // }
  }

  async getCity(e) {
    // const cityData = await this._apiService.COMMON_API_getSingleRecordByID(configuration.TABLE.MASTER_CITY, "state_id", e.field.value);
    // if(cityData && !cityData.is_error && cityData.data) {
    //   this.cityField.options.data = cityData.data;
    //   const fieldselectTwoComponent:any = this.fieldselectOneComponent.toArray();
    //   if(fieldselectTwoComponent && fieldselectTwoComponent[2]) {
    //     fieldselectTwoComponent[2].setFormFieldOptions("city_id", cityData.data, "data");
    //     this.onValueChangeInput(e);
    //   }
    // }
  }

  onValueChangeInput(e) {
    switch(e.field.name) {
      case "address":
        this.addressValue.address = e.field.value;
        break;
      case "country_id":
        this.addressValue.country_id = e.field.value;
        break;
      case "state_id":
        this.addressValue.state_id = e.field.value;
        break;
      case "city_id":
        this.addressValue.city_id = e.field.value;
        break;
    }
    this.field.value = this.addressValue;
    this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});
  }

  setFormFieldValue(name, value) {
 //   this.field.value = value;
 //   this.form.controls[this.field.name] = value;
    if(typeof value.address != "undefined") {
      this.form.controls["address"] = value.address;
      this.addressValue.address = value.address;
      const fieldtextareaComponent:any = this.fieldtextareaComponent.toArray();
      if(fieldtextareaComponent && fieldtextareaComponent[0]) {
        fieldtextareaComponent[0].setFormFieldValue("address", value.address);
      }
    }
    if(typeof value.country_id != "undefined") {
      this.form.controls["country_id"] = value.country_id;
      this.addressValue.country_id = value.country_id;
      const fieldselectTwoComponent:any = this.fieldselectOneComponent.toArray();
      if(fieldselectTwoComponent && fieldselectTwoComponent[0]) {
        fieldselectTwoComponent[0].setFormFieldValue("country_id", value.country_id);
      }
    }
    if(typeof value.state_id != "undefined") {
      this.form.controls["state_id"] = value.state_id;
      this.addressValue.state_id = value.state_id;
      const fieldselectTwoComponent:any = this.fieldselectOneComponent.toArray();
      if(fieldselectTwoComponent && fieldselectTwoComponent[1]) {
        fieldselectTwoComponent[1].setFormFieldValue("state_id", value.state_id);
      }
    }
    if(typeof value.city_id != "undefined") {
      this.form.controls["city_id"] = value.city_id;
      this.addressValue.city_id = value.city_id;
      const fieldselectTwoComponent:any = this.fieldselectOneComponent.toArray();
      if(fieldselectTwoComponent && fieldselectTwoComponent[2]) {
        fieldselectTwoComponent[2].setFormFieldValue("city_id", value.city_id);
      }
    }
  }

  // Set Select box options 
  setFormFieldOptions(name, value, attrname) {
    if(attrname == "dataCountry") {
      this.countryField.options.data = value;
      const fieldselectOneComponent:any = this.fieldselectOneComponent.toArray();
//      if(formComponent && formComponent[0]) {
    }

  }


  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }

}
