
const TableHospital = {
    
    header: [
        { name: "Hospital Id", key: "hospital_id"},
        { name: "Hospital Name", key: "hospital_name"},
        { name: "Registration Id", key: "registration_number"},
        { name: "Hospital Logo", key: "hospital_logo"},
        { name: "Number Of Branches", key: "number_of_branches"},
        { name: "Alias", key: "alias"},
        { name: "City", key: "city"},
        // { name: "Update", key: "update"},
        { name: "Update", key: "table-row-function", "component": "link", "row_type": "update_hospital"},
        // { name: "Branch", key: "table-row-function", "component": "link", "row_type": "edit_hospital"},
        { name: "Branch", key: "table-row-function", "component": "link", "row_type": "branch_list"},
        { name: "Delete", key: "table-row-function", "component": "link", "row_type": "delete_hospital"},

    ],
    data: [
    ],
};
export default TableHospital;











