import { Component, OnInit } from '@angular/core';
import HipDesign from 'src/app/helpers/forms/hip-design';
import HipDesignTable from '../../helpers/tables/hip-tabledesign';

@Component({
  selector: 'app-hip-design',
  templateUrl: './hip-design.component.html',
  styleUrls: ['./hip-design.component.css']
})
export class HipDesignComponent implements OnInit {
  _formHip = HipDesign
  _tableHip = HipDesignTable
  constructor() { }

  ngOnInit(): void {
  }

}
