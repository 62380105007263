import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.css']
})
export class NumberComponent implements OnInit {
  @Input() field;
  @Output() onValueChange = new EventEmitter();
  form: FormGroup;
  isDisplayValidation = false;

  constructor(public formBuilder: FormBuilder, public _shared: SharedService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({});
    this.form.addControl(
      this.field.name,
      new FormControl(this.field.value)
    );

    if(this.field.validation && this.field.validation.required) {
      this.form.controls[this.field.name].setValidators(Validators.required);
    }

    this.form.controls[this.field.name].valueChanges.subscribe(val => {
      this.field.value = val;
      this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});      
    });
  }

  isRequired() {
    return (this.field.validation && this.field.validation.required);
  }
  
  setFormFieldValue(name, value) {
    this.field.value = value;
    this.form.controls[this.field.name] = value;
  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }

  isFieldValid() {
    this.isDisplayValidation = true;
    if(this.form.controls[this.field.name].errors) {
      return {
        [this.field.name]: this.form.controls[this.field.name].errors
      };
    }
    return null;
  }

  get _form () {
    return this.form.controls;
  }
}
