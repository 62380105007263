<ng-container *ngIf="field.grid_type == 'inventory'">
    <app-grid-box-inventory></app-grid-box-inventory>
</ng-container>
<ng-container *ngIf="field.grid_type == 'medicine-bill'">
    <app-grid-box-medicine-bill></app-grid-box-medicine-bill>
</ng-container>
<ng-container *ngIf="field.grid_type == 'store-inventory'">
    <app-grid-box-store-inventory></app-grid-box-store-inventory>
</ng-container>
<ng-container *ngIf="field.grid_type == 'store-request'">
    <app-grid-box-store-request></app-grid-box-store-request>
</ng-container>