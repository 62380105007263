<form [formGroup]="form">
    <div class="form-group row">
        <label [ngClass]="field.class.labelDivClass" *ngIf="field.isDisplayLabel">{{field.label}}</label>
        <div [ngClass]="field.class.inputDivClass">
            <input [ngxTimepicker]="picker" [value]="value" class="form-control">
            <ngx-material-timepicker (timeSet)="timeSet($event)" #picker></ngx-material-timepicker>
            <!-- <div class="startTimeBox">
                <div style="width: 70px; float: left;">
                    <select (change)="changeValue()" class="form-control" [formControlName]="field.name+'_h'">
                        <option [selected]="!field.value" value="">H</option>
                        <ng-container *ngFor="let d of timeHour">
                            <option [selected]="field.valueH == d" [value]="d">{{d}}</option>
                        </ng-container>
                    </select>
                </div>
                <div style="width: 70px; float: left;">
                    <select (change)="changeValue()" class="form-control" [formControlName]="field.name+'_m'">
                        <option value="">M</option>
                        <ng-container *ngFor="let d of timeMin">
                            <option [selected]="field.valueM == d" [value]="d">{{d}}</option>
                        </ng-container>
                    </select>
                </div>
                <div style="width: 75px; float: left;">
                    <select (change)="changeValue()" class="form-control" [formControlName]="field.name+'_a'">
                        <option value="AM" [selected]="field.valueA == 'AM'">AM</option>
                        <option value="PM" [selected]="field.valueA == 'PM'">PM</option>
                    </select>
                </div>
            </div> -->
        </div>
    </div>
</form>