import { configuration } from "src/app/configuration";

const FormPrimarySummary = {
  fields: [
    {
      type: configuration.FORM.FIELD.DATEPICKER,
      label: "From Date",
      name: "from_date",
      validation: {
        required: true
      }
    },
    {
      type: configuration.FORM.FIELD.DATEPICKER,
      label: "To Date",
      name: "to_date",
      validation: {
        required: true
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Region",
      name: "region",
      options: {
        data: [
          { op_key: 0, op_value: "Knee Revision" },
          { op_key: 1, op_value: "Hip Revision" },
        ]
      },
      validation: {
        required: true
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Sides",
      name: "sides",
      options: {
        data: [
          { op_key: 1, op_value: "Left" },
          { op_key: 2, op_value: "Right" },
          { op_key: 3, op_value: "Bilateral" },
        ]
      },
      validation: {
        required: true
      }
    },

  ],
  isSubmitButton: true,
  isDisplayInlineError: true,
  submitButton: {
    type: configuration.FORM.FIELD.BUTTON,
    label: "Search",
    name: "submit",
    attr: {
      type: "submit",
      color: configuration.FORM.BUTTON.COLOR.PRIMARY,
    },
    buttons: []
  }
};
export default FormPrimarySummary;
