import { configuration } from "src/app/configuration";

const KneeCompany = {
  fields: [
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Company Name",
      name: "companyNmae"
    },
    
    
  ],
  isSubmitButton: true,
  isDisplayInlineError: true,
  submitButton: {
    type: configuration.FORM.FIELD.BUTTON,
    label: "Create Company",
    name: "createCompany",
    attr: {
      type: "submit",
      color: configuration.FORM.BUTTON.COLOR.PRIMARY,
    },
    buttons: []
  }
};
export default KneeCompany;
