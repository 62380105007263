import { Component, OnInit } from '@angular/core';
import OptionTable from 'src/app/helpers/tables/option-table';
import AddOption from 'src/app/helpers/forms/add-options';

@Component({
  selector: 'app-revised-knee-options',
  templateUrl: './revised-knee-options.component.html',
  styleUrls: ['./revised-knee-options.component.css']
})
export class RevisedKneeOptionsComponent implements OnInit {

  constructor() { }
  formArray = [
    { label: "Primary Diagnosis", form: OptionTable},
    { label: "Cause Of Revision", form: OptionTable },
    { label: "Prev Prosthetic Type", form: OptionTable },
    { label: "Co Morbidities", form: OptionTable },
    { label: "Surgical Plan", form: OptionTable },
    { label: "Procedure", form: OptionTable },
    { label: "Anaesthesia", form: OptionTable },
    { label: "Arthrotomy", form: OptionTable },
    { label: "Use of Spacer", form: OptionTable },
    { label: "Varus", form: OptionTable },
    { label: "Valgus", form: OptionTable },
    { label: "Flexion", form: OptionTable },

  ]
  _addOption = AddOption
  ngOnInit(): void {
  }

}
