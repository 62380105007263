import { configuration } from "src/app/configuration";

const FormIntraoperative = {
  fields: [
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Procedure",
      name: "procedure",
      is_primary: true,
      options: {
        API: {
          URL: "common/get-options?name=knee1 procedure",
        },
        op_key: "option_value",
        op_value: "option_name",
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Procedure",
      name: "revision_procedure",
      is_revision: true,
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Surgical plan",
      name: "surgical_plan",
      is_revision: true,
      options: {
        data: [
          { op_key: "One Stage", op_value: "One Stage" },
          { op_key: "Two Stage", op_value: "Two Stage" },
          { op_key: "Others", op_value: "Others" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Status of prev. femoral comp.',
      name: 'status_of_prev_femoral_comp',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'Well fixed',
            },
            {
              op_key: "2",
              op_value: 'Loose',
            },
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Status of prev. tibial comp.',
      name: 'status_of_prev_tibial_comp',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'Well fixed',
            },
            {
              op_key: "2",
              op_value: 'Loose',
            },
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Patellar status',
      name: 'patellar_status',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'No implant',
            },
            {
              op_key: "2",
              op_value: 'Well fixed',
            },
            {
              op_key: "3",
              op_value: 'Loose',
            },
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Status of retrieved PE',
      name: 'status_of_retrieved_pe',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'Normal',
            },
            {
              op_key: "2",
              op_value: 'Fragmented',
            },
            {
              op_key: "3",
              op_value: 'Pitting',
            },
            {
              op_key: "4",
              op_value: 'Delamination',
            },
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Navigation",
      name: "navigation",
      options: {
        data: configuration.YES_NO_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Drapes",
      name: "drapes",
      options: {
        data: configuration.USE
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Hoods",
      name: "hoods",
      options: {
        data: configuration.YES_NO_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Prophylactic antibiotic",
      name: "prophylactic_antibiotic"
    },

    {
      type: configuration.FORM.FIELD.RADIO,
      name: "tranexamic_acid",
      label: "Tranexamic acid",
      options: {
        data: configuration.YES_NO_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      name: "tranexamic_acid_options",
      label: "",
      options: {
        data: configuration.LOCAL_IV_OPTIONS
      },

    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Anaesthesia",
      name: "anaesthesia",
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Spinal',
          },
          {
            op_key: "2",
            op_value: 'Epidural',
          },
          {
            op_key: "3",
            op_value: 'GA',
          },
          {
            op_key: "4",
            op_value: 'Spinal + Epidural',
          },
          {
            op_key: "5",
            op_value: 'GA + Epidural',
          },
          {
            op_key: "6",
            op_value: 'Others',
          },
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "ASA Grade",
      optionSelectValue: "-5",
      name: "asa_grade",
      options: {
        data: [
          { op_key: "1", op_value: "Grade |" },
          { op_key: "2", op_value: "Grade ||" },
          { op_key: "3", op_value: "Grade |||" },
          { op_key: "4", op_value: "Grade |V" },
          { op_key: "5", op_value: "Grade V" },
          { op_key: "6", op_value: "Grade E" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Tourniquet",
      name: "tourniquet",
      options: {
        data: [
          { op_key: "1", op_value: "Not used" },
          { op_key: "2", op_value: "During cementing only" },
          { op_key: "3", op_value: "Released before closure" },
          { op_key: "4", op_value: "Released after closure" },
          { op_key: "5", op_value: "Intermittent" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Tourniquet Time",
      name: "tourniquet_time"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Tourniquet Pressure",
      name: "tourniquet_pressure"
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Arthrotomy",
      name: "arthrotomy",
      options: {
        data: [
          { op_key: "1", op_value: "Medial" },
          { op_key: "2", op_value: "Mid Vastus" },
          { op_key: "3", op_value: "Sub Vastus" },
          { op_key: "4", op_value: "Lateral" },
          { op_key: "5", op_value: "Quadsnip" },
          { op_key: "6", op_value: "V-Y Plasty" },
          { op_key: "7", op_value: "TTO" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Tibial defect",
      name: "tibial_defect",
      optionSelectValue: "-5",
      is_primary: true,
      options: {
        data: [
          { op_key: "1", op_value: "Antero-medial" },
          { op_key: "2", op_value: "Central" },
          { op_key: "3", op_value: "Postero-medial" },
          { op_key: "4", op_value: "Antero-lateral" },
          { op_key: "5", op_value: "Postero-lateral" },

        ]
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Tibial defect AORI Classification",
      name: "tibial_defect_aori",
      options: {
        data: [
          {
            image: "assets/images/grade1.jpg",
            op_key: "1",
            op_value: 'Grade 1',
          },
          {
            image: "assets/images/grade2.jpg",
            op_key: "2",
            op_value: 'Grade 2A',
          },
          {
            image: "assets/images/grade3.jpg",
            op_key: "3",
            op_value: 'Grade 2B',
          },
          {
            image: "assets/images/grade4.jpg",
            op_key: "4",
            op_value: 'Grade 3',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Femoral Defect",
      name: "femoral_defect",
      is_revision: true,
      options: {
        data: [
          {
            image: "assets/images/revKneeFemoralGrade1.jpg",
            op_key: "1",
            op_value: 'Grade 1',
          },
          {
            image: "assets/images/revKneeFemoralGrade2A.jpg",
            op_key: "2",
            op_value: 'Grade 2A',
          },
          {
            image: "assets/images/revKneeFemoralGrade2B.jpg",
            op_key: "3",
            op_value: 'Grade 2B',
          },
          {
            image: "assets/images/revKneeFemoralGrade3.jpg",
            op_key: "4",
            op_value: 'Grade 3',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Tibial comp. sizing',
      name: 'tibial_comp_sizing',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'Good',
            },
            {
              op_key: "2",
              op_value: 'Under sized',
            },
            {
              op_key: "3",
              op_value: 'Over hang',
            },
            {
              op_key: "4",
              op_value: 'Rotation',
            },
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Status of pat tendon',
      name: 'status_of_pat_tendon',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'Intact',
            },
            {
              op_key: "2",
              op_value: 'Compromised',
            },
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Use of Spacer (Infection)",
      name: "use_of_spacer",
      is_revision: true,
      options: {
        data: [
          { op_key: "Not Used", op_value: "Not Used" },
          { op_key: "Monoblock", op_value: "Monoblock" },
          { op_key: "Articulated mobile", op_value: "Articulated mobile" },
          { op_key: "Others", op_value: "Others" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Patellar retinaculum release",
      name: "patellar_retinaculum_release",
      is_revision: true,
      options: {
        data: configuration.YES_NO_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Medial collateral',
      name: 'medial_collateral',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'Normal',
            },
            {
              op_key: "2",
              op_value: 'Compromised',
            },
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Lateral collateral',
      name: 'lateral_collateral',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'Normal',
            },
            {
              op_key: "2",
              op_value: 'Compromised',
            },
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Patella - Outer bridge Classification",
      name: "patella_outer_bridge_classification",
      displayType: "table",
      is_primary: true,
      options: {
        header: [
          "Choose",
          "Outer Bridge Classification of Arthritis of Articular Cartilage"
        ],
        data: [
          {
            op_key: 1,
            op_value: 'Grade 0',
            op_value1: 'Normal cartilage',
          },
          {
            op_key: 2,
            op_value: 'Grade I',
            op_value1: 'cartilage with softening and swelling',
          },
          {
            op_key: 3,
            op_value: 'Grade II',
            op_value1: 'Partial-thickness defect with fissures on the surface that do not reach subchondral bone or exceed 1.5 cm in diameter',
          },
          {
            op_key: 4,
            op_value: 'Grade III',
            op_value1: 'Fissuring on the level of subchondral bone in an area with a diameter more than 1.5 cm',
          },
          {
            op_key: 5,
            op_value: 'Grade IV',
            op_value1: 'Exposed subchondral bone',
          },
        ],
      },
    },

    // {
    //   type: configuration.FORM.FIELD.TEXT,
    //   label: "Patell - Outer bridge classification",
    //   name: ""
    // },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Patellar thickness",
      name: "patellar_thickness",
      is_primary: true,
      postfix: {
        class: "",
        text: "mm"
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Lat. retinaculum",
      name: "lat_retinaculum",
      is_primary: true,
      options: {
        data: configuration.YES_NO_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "ACL",
      name: "acl",
      is_primary: true,
      options: {
        data: configuration.INTACT
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "PCL",
      name: "pcl",
      is_primary: true,
      options: {
        data: configuration.INTACT
      },

    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "MCL",
      name: "mcl",
      is_primary: true,
      options: {
        data: configuration.PATHOLOGICAL
      },

    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "LCL",
      name: "lcl",
      is_primary: true,
      options: {
        data: configuration.PATHOLOGICAL
      },

    },
    {
      type: configuration.FORM.FIELD.NOTE,
      label: "Soft tissue Release",
      name: "",
      class: {
        labelDivClass: "col-md-12 form-inside-label"
      }
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Varus",
      name: "varus",
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Posteromedial release',
          },
          {
            op_key: "2",
            op_value: 'Deep MCL release',
          },
          {
            op_key: "3",
            op_value: 'Pes release',
          },
          {
            op_key: "4",
            op_value: 'Superficial MCL pie crusting',
          },
          {
            op_key: "5",
            op_value: 'Superficial MCL release',
          },
          {
            op_key: "6",
            op_value: 'Popliteus release',
          },
          {
            op_key: "7",
            op_value: 'Medial epicondylar osteotomy',
          },
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Valgus",
      name: "valgus",
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Posterolateral release',
          },
          {
            op_key: "2",
            op_value: 'LCL pie crusting /release',
          },
          {
            op_key: "3",
            op_value: 'Popliteus release',
          },
          {
            op_key: "4",
            op_value: 'IT band pie crusting / release',
          },
          {
            op_key: "5",
            op_value: 'Lateral epicondylar osteotomy',
          },
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Flexion",
      name: "flexion",
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Posterior capsular release',
          },
          {
            op_key: "2",
            op_value: 'Hamstring release',
          },
          {
            op_key: "3",
            op_value: 'pie crusting',
          },

        ]
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Distal femoral cut",
      name: "vca_radio",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Standard',
          },
          {
            op_key: "2",
            op_value: 'Additional',
          },
        ]
      },

    },

    {
      type: configuration.FORM.FIELD.TEXT,
      label: "VCA (Valgus correction Angle)",
      name: "vca",
      is_primary: true
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Patella resurfacing",
      name: "patella_resurfacing",
      is_primary: true,
      options: {
        data: configuration.YES_NO_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Defect Reconstruction",
      name: "defect_reconstruction",
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Cement',
          },
          {
            op_key: "2",
            op_value: 'Cement + Screw',
          },
          {
            op_key: "3",
            op_value: 'Bone graft impaction',
          },
          {
            op_key: "4",
            op_value: 'Bone graft wedge',
          },
          {
            op_key: "5",
            op_value: 'Metal wedge',
          },
          {
            op_key: "6",
            op_value: 'Sleeve',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Femoral Stem",
      name: "femoral_stem",
      options: {
        data: configuration.YES_NO_STRING,
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "",
      name: "femoral_stem_options",
      options: {
        data: configuration.CEMENT_OPTIONS,
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Tibial Stem",
      name: "tibial_stem",
      options: {
        data: configuration.YES_NO_STRING
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "",
      name: "tibial_stem_options",
      options: {
        data: configuration.CEMENT_OPTIONS
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Cement used",
      name: "cement_used",
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Plain',
          },
          {
            op_key: "2",
            op_value: 'Antibiotic',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Antibiotic",
      name: "primary_antibiotic",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Simplex',
          },
          {
            op_key: "2",
            op_value: 'Palacos etc.',
          },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Antibiotic",
      name: "antibiotic",
      is_revision: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Simplex',
          },
          {
            op_key: "2",
            op_value: 'Palacos etc.',
          },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Viscosity",
      name: "viscosity",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Low',
          },
          {
            op_key: "2",
            op_value: 'Medium',
          },
          {
            op_key: "3",
            op_value: 'High',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Intraoperative cocktail",
      name: "intraoperative_cocktail",
      is_primary: true,
      options: {
        data: configuration.YES_NO_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Intraoperative complications",
      name: "intraoperative_complications",
      is_primary: true,
      options: {
        data: configuration.YES_NO_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Details",
      name: "details",
      is_primary: true,
    },
    {
      type: configuration.FORM.FIELD.NOTE,
      label: "Prosthesis used",
      name: "",
      class: {
        labelDivClass: "col-md-12 form-inside-label"
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Company",
      name: "company",
      options: {
        API: {
          URL: configuration.API_ENDPOINT.GET_COMPANY_OPTION,
        },
        op_key: "company_id",
        op_value: "company_name",
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Design",
      name: "design",
      options: {
        op_key: "design_id",
        op_value: "design_name",
      }
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Femoral Size",
      name: "femoral_size"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Tibial Size",
      name: "tibial_size"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Patellar Size",
      name: "patellar_size"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Insert Poly",
      name: "insert_poly"
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Component Stability",
      name: "component_stability",
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Stable in Flxn-Extn',
          },
          {
            op_key: "2",
            op_value: 'Stable in Flexion',
          },
          {
            op_key: "3",
            op_value: 'Stable in Extension',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Flexion Contracture",
      name: "flexion_contracture",
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'None',
          },
          {
            op_key: "2",
            op_value: '< 10°',
          },
          {
            op_key: "3",
            op_value: '10-20°',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Hyper extension",
      name: "hyper__extension",
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'None',
          },
          {
            op_key: "2",
            op_value: '< 10°',
          },
          {
            op_key: "3",
            op_value: '< 20°',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "ML Laxity",
      name: "ml_laxity",
      is_revision: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'None',
          },
          {
            op_key: "2",
            op_value: 'Minimal',
          },
          {
            op_key: "3",
            op_value: 'Moderate',
          },
          {
            op_key: "4",
            op_value: 'Marked',
          },
        ]
      },
    },

    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Medial Laxity",
      name: "medial_laxity",
      is_primary: true,
      options: {
        data: configuration.MODERATE_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Lateral Laxity",
      name: "lateral_laxity",
      is_primary: true,
      options: {
        data: configuration.MODERATE_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Patellar Tracking",
      name: "patellar_tracking",
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Optimal',
          },
          {
            op_key: "2",
            op_value: 'Lat. Tracking',
          },
          {
            op_key: "3",
            op_value: 'Lat. Sublux.',
          },
          {
            op_key: "4",
            op_value: 'Med. Sublux.',
          },
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Operative time",
      name: "operative_time"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Blood loss",
      name: "blood_loss"
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Drains",
      name: "drains",
      options: {
        data: configuration.YES_NO_STRING
      },

    },
    // {
    //   type: configuration.FORM.FIELD.NOTE,
    //   label: "Thrombo prophylaxis",
    //   name: "",
    // },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Mechanical",
      name: "mechanical",
      offsetLabel: "Thrombo prophylaxis",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'TED Stockings',
          },
          {
            op_key: "2",
            op_value: 'Calf pump',
          },
        ],
      },
    },

    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Pharmacological",
      name: "pharmacological",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'LMWH',
          },
          {
            op_key: "2",
            op_value: 'Oral',
          },
          {
            op_key: "3",
            op_value: 'Aspirin',
          },
          {
            op_key: "4",
            op_value: 'Warfarin',
          },

        ],
      },
    },
    // {
    //   type: configuration.FORM.FIELD.CHECKBOX,
    //   name: "additional_information",
    //   is_revision: true,
    //   options: {
    //     data: [
    //       {
    //         op_key: "1",
    //         op_value: 'Additional information',
    //       },
    //     ],
    //   }
    // },

    {
      type: configuration.FORM.FIELD.TEXTAREA,
      label: "Additional information",
      name: "additional_information"
    },
  ],

};
export default FormIntraoperative;
