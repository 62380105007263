<div class="container" *ngIf="patientData">
    <app-hip-primary-tab [id]="id"  [patientData]="patientData" [name]="'hip-postop'"></app-hip-primary-tab>
    <app-form [formParam]="_formHipPreopRadiographic"></app-form>
    <app-upload-image [id]="id" [name]="'hip-postop'" [label]="'radiological'" [type]="'hip-postop-radiographic'">
    </app-upload-image>
    <div class="row">
        <div class="col-md-12">
            <div class="bottom">
                <button type="button" class="btn btn-primary bottom-button" (click)="submitForm()">Continue</button>
            </div>
        </div>
    </div>
</div>