import { Component, OnInit } from '@angular/core';
import KneeCompany from 'src/app/helpers/forms/knee-company';
import KneeCompany1 from 'src/app/helpers/tables/knee-tablecompany';


@Component({
  selector: 'app-knee-company',
  templateUrl: './knee-company.component.html',
  styleUrls: ['./knee-company.component.css']
})
export class KneeCompanyComponent implements OnInit {
  _kneeCompany = KneeCompany;
  _kneeCompanyTable = KneeCompany1;
  constructor() { }

  ngOnInit(): void {
  }

}
