<div class="form-group row">
    <label [ngClass]="field.class.labelDivClass"  *ngIf="field.isDisplayLabel"></label>
    <div  [ngClass]="field.class.inputDivClass" class="offset-md-3">
        <button [attr.disabled]="isSubmit ? 'disabled' : null" [ngClass]="field.class && field.class.buttonClass ? field.class.buttonClass : ''" (click)="onClick($event,field)" [type]="field.attr.type" class="btn btn-{{field.attr.color}}">
            {{isSubmit ? "Loading..." : field.label}}
        </button>
        <ng-container *ngIf="field.buttons">
            <ng-container *ngFor="let f of field.buttons">
                <button class="ml-1" [ngClass]="f.class && f.class.buttonClass ? f.class.buttonClass : ''" (click)="onClick($event,f)" [type]="f.attr.type" class="btn btn-{{f.attr.color}}">{{f.label}}</button>
            </ng-container>
        </ng-container>
    </div>
</div>  