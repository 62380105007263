import { Component, OnInit, Input, ViewChildren, QueryList, Output, EventEmitter } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from "@angular/forms";
import { configuration } from 'src/app/configuration';
import { SharedService } from 'src/app/service/shared.service';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { ButtonComponent } from './button/button.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { EmailComponent } from './email/email.component';
import { FileComponent } from './file/file.component';
import { NoteComponent } from './note/note.component';
import { NumberComponent } from './number/number.component';
import { PasswordComponent } from './password/password.component';
import { RadioComponent } from './radio/radio.component';
import { SelectComponent } from './select/select.component';
import { TextComponent } from './text/text.component';
import { AddressComponent } from './address/address.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { DateandtimeComponent } from './dateandtime/dateandtime.component';
import { TimepickerdurationComponent } from './timepickerduration/timepickerduration.component';
import { MobileComponent } from './mobile/mobile.component';
import { IdproofComponent } from './idproof/idproof.component';
import { BirthdateComponent } from './birthdate/birthdate.component';
import { SpecialOpdChargeComponent } from "./special-opd-charge/special-opd-charge.component";
import { PaymentBoxWithAmountComponent } from "./payment-box-with-amount/payment-box-with-amount.component";
import { TextareaComponent } from "./textarea/textarea.component";
import { NameComponent } from "./name/name.component";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class FormComponent implements OnInit {
  @Input() formParam;
  @Output() onSubmit = new EventEmitter();
  @Output() onButton = new EventEmitter();  
  @Output() onFormFieldOnChange = new EventEmitter();
  @Output() onFormFieldOnClick = new EventEmitter();
  @Output() onFormEnterChange = new EventEmitter();
  @Output() onFormPostFixClick = new EventEmitter();
  
  form: FormGroup;
  fieldIndexByName = {};
  fieldComponentIndexByName = {};
  fieldTypeCount = {};
  @ViewChildren(AutocompleteComponent) fieldautocompleteComponent: QueryList<AutocompleteComponent>;
  @ViewChildren(ButtonComponent) fieldbuttonComponent: QueryList<ButtonComponent>;
  @ViewChildren(CheckboxComponent) fieldcheckboxComponent: QueryList<CheckboxComponent>;
  @ViewChildren(DatepickerComponent) fielddatepickerComponent: QueryList<DatepickerComponent>;
  @ViewChildren(DateandtimeComponent) fielddateandtimeComponent: QueryList<DateandtimeComponent>;  
  @ViewChildren(TimepickerComponent) fieldtimepickerComponent: QueryList<TimepickerComponent>;
  @ViewChildren(TimepickerdurationComponent) fieldtimepickerdurationComponent: QueryList<TimepickerdurationComponent>;  
  @ViewChildren(EmailComponent) fieldemailComponent: QueryList<EmailComponent>;
  @ViewChildren(FileComponent) fieldfileComponent: QueryList<FileComponent>;
  @ViewChildren(NoteComponent) fieldnoteComponent: QueryList<NoteComponent>;
  @ViewChildren(NumberComponent) fieldnumberComponent: QueryList<NumberComponent>;
  @ViewChildren(PasswordComponent) fieldpasswordComponent: QueryList<PasswordComponent>;
  @ViewChildren(RadioComponent) fieldradioComponent: QueryList<RadioComponent>;
  @ViewChildren(SelectComponent) fieldselectComponent: QueryList<SelectComponent>;
  @ViewChildren(TextComponent) fieldtextComponent: QueryList<TextComponent>;
  @ViewChildren(TextareaComponent) fieldtextareaComponent: QueryList<TextareaComponent>;  
  @ViewChildren(AddressComponent) fieldaddressComponent: QueryList<AddressComponent>;
  @ViewChildren(MobileComponent) fieldmobileComponent: QueryList<MobileComponent>;
  @ViewChildren(IdproofComponent) fieldidproofComponent: QueryList<IdproofComponent>;
  @ViewChildren(BirthdateComponent) fieldbirthdateComponent: QueryList<BirthdateComponent>;
  @ViewChildren(SpecialOpdChargeComponent) fieldsocComponent: QueryList<SpecialOpdChargeComponent>;
  @ViewChildren(PaymentBoxWithAmountComponent) fieldpbwaComponent: QueryList<PaymentBoxWithAmountComponent>;
  @ViewChildren(NameComponent) fieldnameComponent: QueryList<NameComponent>;
  
  @ViewChildren(ButtonComponent) submitButton: QueryList<ButtonComponent>;

  
  constructor(private formBuilder: FormBuilder, public _shared: SharedService) {}

  ngOnInit() {
    this.form = this.formBuilder.group({});
    
    this.formParam.name = 
    typeof this.formParam.name != "undefined" ? this.formParam.name : "form";
    this.formParam.class = 
    typeof this.formParam.class != "undefined" ? this.formParam.class : "";
    this.formParam.isSubmitButton =
      typeof this.formParam.isSubmitButton != "undefined" ? this.formParam.isSubmitButton : false;
    this.formParam.errorMessage =
      typeof this.formParam.errorMessage != "undefined" ? this.formParam.errorMessage : "";      
    this.formParam.buttons =
    typeof this.formParam.buttons != "undefined" ? this.formParam.buttons : [];      
    if(this.formParam.isSubmitButton) {
      this.formParam.submitButton.buttons =
      typeof this.formParam.submitButton.buttons != "undefined" ? this.formParam.submitButton.buttons : [];      
    }

    if(this.formParam.fieldClass) {
      this.formParam.fieldClass.labelDivClass =
      typeof this.formParam.fieldClass.labelDivClass != "undefined" ? this.formParam.fieldClass.labelDivClass : "col-md-3";
      this.formParam.fieldClass.inputDivClass =
      typeof this.formParam.fieldClass.inputDivClass != "undefined" ? this.formParam.fieldClass.inputDivClass : "col-md-9";
      this.formParam.fieldClass.inputDivClass1 =
      typeof this.formParam.fieldClass.inputDivClass1 != "undefined" ? this.formParam.fieldClass.inputDivClass1 : "col-md-12";
      this.formParam.fieldClass.inputDivClass2 =
      typeof this.formParam.fieldClass.inputDivClass2 != "undefined" ? this.formParam.fieldClass.inputDivClass2 : "col-md-12";
      this.formParam.fieldClass.mainDivClass =
      typeof this.formParam.fieldClass.mainDivClass != "undefined" ? this.formParam.fieldClass.mainDivClass : "col-md-12";
    }  else {
      this.formParam.fieldClass = {
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-9",
        inputDivClass1: "col-md-12",
        inputDivClass2: "col-md-12",
        mainDivClass: "col-md-12"
      }
    }

    if (this.formParam.fields) {
      let fieldIndex = 0;
      for (let field of this.formParam.fields) {
        this.fieldIndexByName[field.name] = fieldIndex;
        if(!this.fieldTypeCount[field.type]) {
          this.fieldTypeCount[field.type] = [];
        }
        field.isDisplayInlineError =
        typeof field.isDisplayInlineError != "undefined" ? field.isDisplayInlineError : (
          typeof this.formParam.isDisplayInlineError != "undefined" ? this.formParam.isDisplayInlineError : false
        );      
  
        this.fieldComponentIndexByName[field.name] = this.fieldTypeCount[field.type].length;        
        this.fieldTypeCount[field.type].push(this.fieldTypeCount[field.type].length);
        fieldIndex++;
        if (field.fieldGroup) {
          for (let eField of field.fieldGroup) {
            this.generateField(eField);
          }
        } else {
          this.generateField(field);
        }
      }
    }
  }

  setFieldComponentIndexByName(name, index) {
    this.fieldComponentIndexByName[name] = index;
  }
  
  generateField(field) {
    field.name = field.name
      ? field.name
      : (field.label ? field.label.toLowerCase().split(" ").join("_") : "");
    field.required = field.required || false;
    field.displayLabel =
      typeof field.displayLabel != "undefined" ? field.displayLabel : true;
    field.isTranslation =
      typeof field.isTranslation != "undefined" ? field.isTranslation : true;

    field.displayValue =
      typeof field.displayValue != "undefined" ? field.displayValue : field.value;
    field.isDisplay =
      typeof field.isDisplay != "undefined" ? field.isDisplay : true;
    field.selectLabel =
      typeof field.selectLabel != "undefined" ? field.selectLabel : "Please select";      
      
    field.isDisplayLabel =
      typeof field.isDisplayLabel != "undefined" ? field.isDisplayLabel : true;      
    field.id =
      typeof field.id != "undefined" ? field.id : field.name;
    field.isValueDisplayInDisable =
      typeof field.isValueDisplayInDisable != "undefined" ? field.isValueDisplayInDisable : true;      
      
    field.fieldClass = field.fieldClass || "col-sm-10";
    field.wrapperClass = field.wrapperClass || "col-md-12";
    field.value =       typeof field.value != "undefined" ? field.value : "";

    if(typeof field.class != "undefined") {
      field.class.labelDivClass = 
        typeof field.class.labelDivClass != "undefined" ? field.class.labelDivClass : "col-md-12";
      field.class.inputDivClass = 
        typeof field.class.inputDivClass != "undefined" ? field.class.inputDivClass : "col-md-12";
      field.class.inputDivClass1 = 
        typeof field.class.inputDivClass1 != "undefined" ? field.class.inputDivClass1 : "col-md-12";
      field.class.inputDivClass2 = 
        typeof field.class.inputDivClass2 != "undefined" ? field.class.inputDivClass2 : "col-md-12";
    } else {
      field.class = {
        labelDivClass: this.formParam.fieldClass.labelDivClass,
        inputDivClass: this.formParam.fieldClass.inputDivClass,
        inputDivClass1: this.formParam.fieldClass.inputDivClass1,
        inputDivClass2: this.formParam.fieldClass.inputDivClass2,        
      }
    }

    // console.log(field.class);

    field.keyup = field.keyup || null;

    field.placeholder =
      typeof field.placeholder != "undefined" ? field.placeholder : field.label;
    
    let validationArr = [];
    if (field.required) {
      validationArr.push(Validators.required);
    }

    this.form.addControl(
      field.name,
      new FormControl(field.value, validationArr)
    );
  }

  setFormErrorMessage(msg) {
    this.formParam.errorMessage = msg;
  }

  // Set Select box options 
  setFormFieldOptions(name, value, attrName = "data") {
    this.formParam.fields[this.fieldIndexByName[name]].options[attrName] = value;
    const field = this.formParam.fields[this.fieldIndexByName[name]];
    const component = this.getComponent(field, this.fieldComponentIndexByName[name]);
    if(component) {
      component.setFormFieldOptions(name, value, attrName);
    }  
  }

  // Set Data functions 
  setFormFieldValue(name, value) {
    this.form.controls[name].setValue(value);
    const field = this.formParam.fields[this.fieldIndexByName[name]];
    // console.log(field);
    const component = this.getComponent(field, this.fieldComponentIndexByName[name]);
    // console.log(component);
    if(component) {
      component.setFormFieldValue(name, value);
    }
  }

  // Set For Field Attr
  setFormFieldAttr(name, attr, value) {
    const field = this.formParam.fields[this.fieldIndexByName[name]];
    field[attr] = value;
    setTimeout(() => {
      const component = this.getComponent(field, this.fieldComponentIndexByName[name]);
      if(component && field.isDisplay) {
        component.setFormFieldAttr(name, attr, value);
      }
    });
  }

  setFormFieldAttrChild(name, attr, attr1, value) {
    const field = this.formParam.fields[this.fieldIndexByName[name]];
    field[attr][attr1] = value;
    setTimeout(() => {
      const component = this.getComponent(field, this.fieldComponentIndexByName[name]);
      if(component && field.isDisplay) {
        component.setFormFieldAttrChild(name, attr, attr1, value);
      }
    });
  }

  // Set Data for all fields 
  setAllFormFieldValue(data) {
    if(this.formParam.fields) {
      for(let eachField of this.formParam.fields) {
        let value = (typeof data[eachField.name] != "undefined" ? data[eachField.name] : "");
        if(eachField.type == configuration.FORM.FIELD.CHECKBOX) {
          if(typeof value == "string") {
            value = value ? value.split(",") : [];
          }
          // console.log("currentData", value);
          if(value && typeof value == "object") {
            value = value.map(s => (s+"").trim());
          } else {
            value = []
          }
        }
        // console.log("currentData", eachField);
        this.setFormFieldValue(eachField.name,  value);
        if(eachField.salutation) {
          this.setFormFieldAttr(eachField.name, "salutation_value", data.salutation);
        }
      }
    }
  }

  resetAllFormFieldValue() {
    if(this.formParam.fields) {
      for(let eachField of this.formParam.fields) {
        let value:any = eachField.defaultValue ? eachField.defaultValue : "";
        switch(eachField.type) {
            case configuration.FORM.FIELD.CHECKBOX:
              value = [];
              break;
            case configuration.FORM.FIELD.MOBILE:
              value = {
                mobile: "",
                code: ""
              };
              break;
            case configuration.FORM.FIELD.IDPROOF:
              value = {
                id_proof_id: "",
                id_proof_value: ""
              };
              break;
            case configuration.FORM.FIELD.ADDRESS:
              value = {
                address: "",
                city_id: "",
                state_id: "",
                country_id: ""
              };
              break;
            case configuration.FORM.FIELD.AUTOCOMPLETE:
              value = {
                id: 0,
                name: ""
              };
              break;
            case configuration.FORM.FIELD.SELECT_TEXT:
              value = {
                name: (value && typeof value.name != "undefined" ? value.name : ""),
                salutation: (value && typeof value.salutation != "undefined" ? value.salutation : "")
              };
              break;
        }
        this.setFormFieldValue(eachField.name, value);
      }
    }
    this.resetValidation();
  }

  getComponent(field, index) {
    let formComponent = null;
    // console.log(field);
    if(field) {
      switch(field.type) {
        case configuration.FORM.FIELD.AUTOCOMPLETE:
          formComponent = this.fieldautocompleteComponent.toArray();
          break;
        case configuration.FORM.FIELD.BUTTON:
          formComponent = this.fieldbuttonComponent.toArray();
          break;
        case configuration.FORM.FIELD.CHECKBOX:
          formComponent = this.fieldcheckboxComponent.toArray();
          break;
        case configuration.FORM.FIELD.DATEPICKER:
          formComponent = this.fielddatepickerComponent.toArray();
          break;
        case configuration.FORM.FIELD.TIMEPICKER:
          formComponent = this.fieldtimepickerComponent.toArray();
          break;
        case configuration.FORM.FIELD.EMAIL:
          formComponent = this.fieldemailComponent.toArray();
          break;
        case configuration.FORM.FIELD.FILE:
          formComponent = this.fieldfileComponent.toArray();
          break;
        case configuration.FORM.FIELD.NOTE:
          formComponent = this.fieldnoteComponent.toArray();
          break;
        case configuration.FORM.FIELD.NUMBER:
          formComponent = this.fieldnumberComponent.toArray();
          break;
        case configuration.FORM.FIELD.PASSWORD:
          formComponent = this.fieldpasswordComponent.toArray();
          break;
        case configuration.FORM.FIELD.RADIO:
          formComponent = this.fieldradioComponent.toArray();
          break;
        case configuration.FORM.FIELD.SELECT:
          formComponent = this.fieldselectComponent.toArray();
          break;
        case configuration.FORM.FIELD.TEXT:
          formComponent = this.fieldtextComponent.toArray();
          break;
        case configuration.FORM.FIELD.TEXTAREA:
          formComponent = this.fieldtextareaComponent.toArray();
          break;  
        case configuration.FORM.FIELD.DATEANDTIME:
          formComponent = this.fielddateandtimeComponent.toArray();
          break;        
        case configuration.FORM.FIELD.ADDRESS:
          formComponent = this.fieldaddressComponent.toArray();
          break;
        case configuration.FORM.FIELD.TIMEPICKER_DURATION:
          formComponent = this.fieldtimepickerdurationComponent.toArray();
          break;
        case configuration.FORM.FIELD.MOBILE:
          formComponent = this.fieldmobileComponent.toArray();
          break;
        case configuration.FORM.FIELD.IDPROOF:
          formComponent = this.fieldidproofComponent.toArray();
          break;
        case configuration.FORM.FIELD.BIRTHDATE:
          formComponent = this.fieldbirthdateComponent.toArray();
          break;
        case configuration.FORM.FIELD.SPECIAL_OPD_CHARGE:
          formComponent = this.fieldsocComponent.toArray();
          break;
        case configuration.FORM.FIELD.PAYMENT_BOX_WITH_AMOUNT:
          formComponent = this.fieldpbwaComponent.toArray();
          break;
        case configuration.FORM.FIELD.SELECT_TEXT:
          formComponent = this.fieldnameComponent.toArray();
          break;
      }
  
      if(formComponent && formComponent.length > 0 && formComponent[index]) {
        return formComponent[index];
      }
    }

    return null;
  }

  getFormValue(onlyValue = false) {
    if(onlyValue) {
      return this.form.value;
    } else {
      return { form: this.form, formParam: this.formParam };
    }

  }

  // Event Emitters
  onFormSubmit(event) {
    this.onSubmit.emit({ event, form: this.form, formParam: this.formParam });
  }

  onFormButton(event) {
    this.onButton.emit({ event, form: this.form, formParam: this.formParam });
  }

  onValueChange(e) {
    // console.log(this.form.controls[e.field.name]);
    this.form.controls[e.field.name].patchValue(e.field.value);
    // console.log("1");
    this.onFormFieldOnChange.emit(e);
    // console.log("2");

  }
  
  onFileChange(e) {
    if(!this.formParam.files) {
      this.formParam.files = {};
    }
    this.formParam.files[e.field.name] = e.field.file;
  }

  onClickEvent(e) {//
    this.onFormFieldOnClick.emit(e);
  } 

  setFocusField(name) {
    let index = this.fieldComponentIndexByName[name];
    let eachField = this.formParam.fields[index];
    const component = this.getComponent(eachField, index);
    if(component) {
      try {
        component.setFocusField();
        console.log("af");
      } catch(e) {
        console.log("no");
      }
    }
  }

  runValidation() {
    let errors = [];
    if(this.formParam.fields) {
      for(let eachField of this.formParam.fields) {
        const component = this.getComponent(eachField, this.fieldComponentIndexByName[eachField.name]);
        if(component) {
          try {
            const valid = component.isFieldValid();
            console.log(valid);
            if(valid) {
                errors.push(valid)
            }
          } catch(e) {
    
          }
        }
      }
    }
    return errors;    
  }

  resetValidation() {
    let errors = [];
    if(this.formParam.fields) {
      for(let eachField of this.formParam.fields) {
        const component = this.getComponent(eachField, this.fieldComponentIndexByName[eachField.name]);
        if(component) {
          try {
            const valid = component.resetFieldValid();
          } catch(e) {
    
          }
        }
      }
    }
    return errors;    
  }

  onEnterChange(e) {
    console.log(e);
    this.onFormEnterChange.emit(e);
  }

  resetSubmitButton() {
    let formComponent = this.submitButton.toArray();
    if(formComponent && formComponent[0]) {
      formComponent[0].resetSubmitButton();
    }
  }

  onClickPostFix(e) {
    console.log("e", e);
    this.onFormPostFixClick.emit(e);
  }
}
