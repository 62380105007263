import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";

import * as _moment from "moment";
import { SharedService } from "src/app/service/shared.service";
// tslint:disable-next-line:no-duplicate-imports
// import { default as _rollupMoment} from 'moment';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-birthdate",
  templateUrl: "./birthdate.component.html",
  styleUrls: ["./birthdate.component.css"],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class BirthdateComponent implements OnInit {
  @Input() field;
  @Output() onValueChange = new EventEmitter();
  form: FormGroup;
  ageField = null;
  tomorrow = new Date();

  constructor(public formBuilder: FormBuilder, public _shared: SharedService) {}

  ngOnInit() {
    this.form = this.formBuilder.group({});
    this.field.value_age = 0;
    this.field.value_age_month = 0;
    this.form.addControl(this.field.name, new FormControl(moment()));
    this.form.addControl(this.field.name + "_age_y", new FormControl(""));
    this.form.addControl(this.field.name + "_age_m", new FormControl(""));
    this.field.value = {
      date: null,
      age_year: 0,
      age_month: 0
    };
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    const dateDiff = this._shared.dateDiff(new Date(), new Date(event.value));
    // console.log(dateDiff);
    this.form.controls[this.field.name + "_age_y"].setValue(dateDiff.years);
    this.form.controls[this.field.name + "_age_m"].setValue(dateDiff.months);
    this.field.value = {
      date: new Date(event.value),
      age_year: dateDiff.years,
      age_month: dateDiff.months
    };
    this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});
  }

  changeAgeValue(e) {
    this.field.value.age_year =  e.target.value;
    this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});
  }

  changeAgeMonthValue(e) {
    this.field.value.age_month =  e.target.value;
    this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});
  }

  setFormFieldValue(name, value) {
    // console.log("datevalue" ,value);
    if(value.age_y) {
      this.form.controls[this.field.name + "_age_y"].setValue(value.age_y);
      this.field.value.age_year =  value.age_y;
    }
    if(value.age_m) {
      this.form.controls[this.field.name + "_age_m"].setValue(value.age_m);
      this.field.value.age_month =  value.age_m;
    }
    if(value.date) {
      this.form.controls[this.field.name].setValue(value.date);
      this.field.date_value = value.date;
      this.field.value.date =  value.date;
    }
    this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});

  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }
}
