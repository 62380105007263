
const KneeCompany1 = {
    header: [
        { name: "Company Name", key: "companyName" },
       
        { name: "Edit", key: "edit"},
     
        { name: "Delete", key: "delete"},

    ],
    data: [
    ],
};
export default KneeCompany1;











