import { configuration } from 'src/app/configuration';

const FormHipPrimaryHarrisScore = {
  name: "harris_section_score",
  fields: [
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Pain',
      name: 'pain',
      options: {
        data:
      [
        {
          op_key: 44,
          op_value: 'None, or ignores it',
        },
        {
          op_key: 40,
          op_value: 'Slight, occasional, no compromise in activity',
        },
        {
          op_key: 30,
          op_value: 'Mild pain, no effect on average activities, rarely moderate pain with unusual activity, may take aspirin',
        },
        {
          op_key: 20,
          op_value: 'Moderate pain, tolerable but makes concessions to pain. Some limitations of ordinary activity or work. May require occasional pain medication stronger than aspirin',
        },
        {
          op_key: 10,
          op_value: 'Marked pain, serious limitation of activities'
        },
        {
          op_key: 0,
          op_value: 'Totally disabled, crippled, pain in bed, bedridden'
        },
      ],
    },
  },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Distance walked',
      name: 'distance_walked',
      options: {
        data:
      [
        {
          op_key: 11,
          op_value: 'Unlimited',
        },
        {
          op_key: 8,
          op_value: 'Six blocks (30 minutes)',
        },
        {
          op_key: 5,
          op_value: 'Two or three blocks (10 - 15 minutes)',
        },
        {
          op_key: 2,
          op_value: 'Indoors only',
        },
        {
          op_key: 0,
          op_value: 'Bed and chair only'
        },
      ],
    },
  },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Activities - shoes, socks',
      name: 'activities_shoes_socks',
      options: {
        data:
      [
        {
          op_key: 4,
          op_value: 'With ease',
        },
        {
          op_key: 2,
          op_value: 'With ease',
        },
        {
          op_key: 0,
          op_value: 'With ease',
        },
      ],
    },
  },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Public transportation',
      name: 'public_transportation',
      options: {
        data:
      [
        {
          op_key: 1,
          op_value: 'Able to use transportation (Bus)',
        },
        {
          op_key: 0,
          op_value: 'Unable to use public transportation (Bus)',
        },
      ],
    },
  },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Support',
      name: 'support',
      options: {
        data:
      [
        {
          op_key: 11,
          op_value: 'None',
        },
        {
          op_key: 7,
          op_value: 'Cane / Walking stick for long walks',
        },
        {
          op_key: 5,
          op_value: 'Cane / Walking stick most of the time',
        },
        {
          op_key: 3,
          op_value: 'One crutch',
        },
        {
          op_key: 2,
          op_value: 'Two Canes / Walking sticks'
        },
        {
          op_key: 0,
          op_value: 'Two crutches or not able to walk'
        },
      ],
    },
  },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Limp',
      name: 'limp',
      options: {
        data:
      [
        {
          op_key: 11,
          op_value: 'None',
        },
        {
          op_key: 8,
          op_value: 'Slight',
        },
        {
          op_key: 5,
          op_value: 'Moderate',
        },
        {
          op_key: 0,
          op_value: 'Severe or unable to walk',
        },
      ],
    },
  },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Stairs',
      name: 'stairs',
      options: {
        data:
      [
        {
          op_key: 4,
          op_value: 'Normally without using a railing',
        },
        {
          op_key: 2,
          op_value: 'Normally using a railing',
        },
        {
          op_key: 1,
          op_value: 'In any manner',
        },
        {
          op_key: 0,
          op_value: 'Unable to do stairs',
        },
      ],
    },
  },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Sitting',
      name: 'sitting',
      options: {
        data:
      [
        {
          op_key: 5,
          op_value: 'Comfortably, ordinary chair for one hour',
        },
        {
          op_key: 3,
          op_value: 'On a high chair for 30 minutes',
        },
        {
          op_key: 0,
          op_value: 'Unable to sit comfortably on any chair',
        },
      ],
    },
  },
  ],

};
export default FormHipPrimaryHarrisScore; 
  
