import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import FormSurgeon from '../helpers/forms/surgeon';
import { FormComponent } from '../common/form/form.component';
import { ActivatedRoute } from '@angular/router';
import { configuration } from '../configuration';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'app-surgeon-create',
  templateUrl: './surgeon-create.component.html',
  styleUrls: ['./surgeon-create.component.css']
})
export class SurgeonCreateComponent implements OnInit {

  _formSurgeon = FormSurgeon
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;

  constructor(private router: ActivatedRoute, private apiService: ApiService, public shared: SharedService) { }

  ngOnInit(): void {
  }

  async onSubmit() {
    let formComponent = this.formComponent.toArray();
    if (formComponent && formComponent[0]) {
      let values = formComponent[0].getFormValue();
      values = JSON.parse(JSON.stringify(values.form.value));
      this.shared.isLoader = true;
      console.log(values)
      if (values.surgeon_is.length > 0) {
        values.is_surgeon = values.surgeon_is[0]
        delete values.surgeon_is
      } else {
        values.is_surgeon = 0
      }
      console.log(values)
      let res = await this.apiService.POST_apiRequest(configuration.API_ENDPOINT.CREATE_SURGEON, values);
      window.location.href = "/surgeon-list";
      this.shared.isLoader = false;
    }
  }

}
