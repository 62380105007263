import { configuration } from "src/app/configuration";

const FormHipPreopRadiographic = {
  fields: [
    {
      type: configuration.FORM.FIELD.DATEPICKER,
      label: "Date of input",
      name: "date_of_input"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Notes",
      name: "notes"
    },
  ]
};
export default FormHipPreopRadiographic;
