import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { configuration } from 'src/app/configuration';
import { SharedService } from 'src/app/service/shared.service';
import { SelectComponent } from '../select/select.component';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'app-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.css']
})
export class NameComponent implements OnInit {
  @Input() field;
  @Output() onValueChange = new EventEmitter();
  form: FormGroup;
  fieldValue = null;
  @ViewChildren(SelectComponent) fieldselectOneComponent: QueryList<SelectComponent>;
  @ViewChildren(TextComponent) fieldtextComponent: QueryList<TextComponent>;
  fieldText;
  fieldSelect;
  fieldTextClone;
  fieldSelectClone;
  specialOPDCharages = [];
  data = [];

  constructor(public formBuilder: FormBuilder, public _shared: SharedService) { }

  ngOnInit(): void {
    this.fieldText = JSON.parse(JSON.stringify(this.field));
    this.fieldSelect = JSON.parse(JSON.stringify(this.field));
    this.fieldSelect.class = {
      labelDivClass: "",
      inputDivClass: "col-md-12"
    }
    this.fieldText.class = {
      labelDivClass: "",
      inputDivClass: "col-md-12"
    }
    this.fieldText.type = configuration.FORM.FIELD.TEXT;
    this.fieldText.defaultValue = (this.field.defaultValue && typeof this.field.defaultValue.name != "undefined" ? this.field.defaultValue.name : "");
    this.fieldText.value = (this.field.value && typeof this.field.value.name != "undefined" ? this.field.value.name : "");
    this.fieldText.isDisplayLabel =  false;
    this.fieldSelect.type = configuration.FORM.FIELD.SELECT;
    this.fieldSelect.defaultValue = (this.field.defaultValue && typeof this.field.defaultValue.salutation != "undefined" ? this.field.defaultValue.salutation : ""),
    this.fieldSelect.value = (this.field.value && typeof this.field.value.salutation != "undefined" ? this.field.value.salutation : ""),
    this.fieldSelect.isDisplayLabel =  false;
    this.fieldSelect.name =  this.fieldSelect.name+"_salutation";
    this.field.value = {
      name: this.fieldText.value,
      salutation: this.fieldSelect.value
    }
    this.form = this.formBuilder.group({});
  }

  isRequired() {
    return (this.field.validation && this.field.validation.required);
  }

  setFormFieldValue(name, value) {
    if(typeof value.salutation != "undefined") {
      const fieldselectComponent:any = this.fieldselectOneComponent.toArray();
      if(fieldselectComponent && fieldselectComponent[0]) {
        fieldselectComponent[0].setFormFieldValue(this.fieldSelect.name, value.salutation);
      }
    }
    if(typeof value.name != "undefined") {
      const fieldtextComponent:any = this.fieldtextComponent.toArray();
      if(fieldtextComponent && fieldtextComponent[0]) {
        fieldtextComponent[0].setFormFieldValue(this.fieldText.name, value.name);
      }
    }
  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }
  
  getFormFieldValue() {
    return {
      field: this.field,
      form: this.form,
      control: this.form.controls[this.field.name],
    };
  }

  isFieldValid() {
    const fieldselectComponent:any = this.fieldselectOneComponent.toArray();
    if(fieldselectComponent && fieldselectComponent[0]) {
      fieldselectComponent[0].isFieldValid();
    }
    const fieldtextComponent:any = this.fieldtextComponent.toArray();
    if(fieldtextComponent && fieldtextComponent[0]) {
      fieldtextComponent[0].isFieldValid();
    }
  }

  onValueChangeField(e, key) {
    console.log(e.field.value,key);
    this.field.value[key] = e.field.value;
    // this.field.value = val;
    this.onValueChange.emit({
      field: this.field,
      form: this.form,
      control: this.form.controls[this.field.name],
    });
  }


}
