<form [formGroup]="form">
    <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{field.label}}</label>
        <div class="col-sm-9">
            <ng-container *ngIf="field.isMultiple">
                <input type="file" multiple #file (change)="onChangeMedia($event,file.files)"
                    style="visibility: hidden; width: 0px; height: 0px;" />
                <button *ngIf="!field.file.fileObjResult && !field.file.fileURL" (click)="file.click()" mat-fab
                    color="primary" aria-label="Example icon button with a delete icon">
                    <mat-icon>insert_drive_file</mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="!field.isMultiple">
                <input type="file" #file (change)="onChangeMedia($event,file.files)"
                    style="visibility: hidden; width: 0px; height: 0px;" />
                <button *ngIf="!field.file.fileObjResult && !field.file.fileURL" (click)="file.click()" mat-fab
                    color="primary" aria-label="Example icon button with a delete icon">
                    <mat-icon>insert_drive_file</mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="!field.isMultiple">
                <ng-container *ngIf="field.file.fileObjResult || field.file.fileURL">
                    <img *ngIf="field.file.fileObjResult && field.file.isPreview" class="preview-image" [src]="field.file.fileObjResult" />
                    <p *ngIf="field.file.fileObjResult && !field.file.isPreview">
                        {{field.file.fileObj.name}}
                    </p>
                    <img *ngIf="field.file.fileURL" class="preview-image" [src]="field.file.fileURL" />
                    <i class="fa fa-close closebutton cursor" (click)="removeFile()"></i>
                </ng-container>
            </ng-container>
        </div>
    </div>
</form>

<ng-container *ngIf="field.isMultiple && field.file.multipleFileURL.length > 0">
    <ng-container *ngFor="let fr of field.file.multipleFileURL; let i = index">
        <div class="eachImagePreview">
            <img class="preview-image" [src]="_shared._config.BUCKET_URL+_shared.userData.folder+fr.image_path" />
            <i class="fa fa-close closebutton cursor" (click)="removeFileMultipleURL(i)"></i>
        </div>
    </ng-container>
</ng-container>
<ng-container *ngIf="field.isMultiple && field.file.multipleFileResult.length > 0">
    <ng-container *ngFor="let fr of field.file.multipleFileResult; let i = index">
        <div class="eachImagePreview">
            <img class="preview-image" [src]="fr" />
            <i class="fa fa-close closebutton cursor" (click)="removeFileMultiple(i)"></i>
        </div>
    </ng-container>
</ng-container>