import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormComponent } from '../common/form/form.component';
import { configuration } from '../configuration';
import FormBranchLogin from '../helpers/forms/branch-login';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'app-branch-login',
  templateUrl: './branch-login.component.html',
  styleUrls: ['./branch-login.component.css']
})
export class BranchLoginComponent implements OnInit {
  _formBranchLogin = FormBranchLogin;
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;

  constructor(public shared: SharedService, public _apiService: ApiService) { }

  ngOnInit(): void {
  }

  async onSubmit(e) {
    console.log(e.form.value);
    this.shared.isLoader = true;

    let res = await this._apiService.POST_apiRequest(configuration.API_ENDPOINT.BRANCH_LOGIN, e.form.value);
    if (res.error) {
      this.shared.isLoader = false;
      this.shared.displayErrorMessage({
        msg: res.message
      })
      const formComponent: any = this.formComponent.toArray();
      if (formComponent && formComponent[0]) {
        formComponent[0].resetSubmitButton();
      }
    } else {
      configuration.setLocalStorageValue(configuration.LOCAL_STORAGE.BRANCH_TOKEN, res.data.token);
      configuration.setLocalStorageValue(configuration.LOCAL_STORAGE.BRANCH_USER_NAME, res.data.branchData.branch_user_name);
      configuration.setLocalStorageValue(configuration.LOCAL_STORAGE.BRANCH_ID, res.data.branchData.branch_id);
      configuration.setLocalStorageValue(configuration.LOCAL_STORAGE.BRANCH_EMAIL, res.data.branchData.branch_email_id);
      // configuration.setLocalStorageValue(configuration.LOCAL_STORAGE.HOSPITAL_NAME, res.data.userData.hospital_name);
      // configuration.setLocalStorageValue(configuration.LOCAL_STORAGE.USER_GROUP_ID, res.data.userData.user_group_id);
      window.location.href = "/dashboard";

    }
    console.log(res);
  }

}
