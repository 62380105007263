import { configuration } from "src/app/configuration";

const BranchForm = {
  fields: [
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Add Hospital Id",
      name: "add_hospital_id"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Admin Branch Name",
      name: "admin_branch_name"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Admin Branch Email Id",
      name: "admin_branch_email_id"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Admin Branch Phone Number",
      name: "admin_branch_phone_number"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Admin Branch Mobile Number",
      name: "admin_branch_mobile_number"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "User Name",
      name: "user_name"
    },
    {
      type: configuration.FORM.FIELD.PASSWORD,
      label: "Password",
      name: "password"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Display Name",
      name: "display_name"
    },
  ],
  isSubmitButton: true,
  isDisplayInlineError: true,
  // isDisabled: { 
  //   type: configuration.FORM.FIELD.TEXT,
  //   label: "Add Hospital Id",
  //   name: "addhospitalid"


  // },
  isDisabled: true,
  submitButton: {
    type: configuration.FORM.FIELD.BUTTON,
    label: "Create Branch",
    name: "branch",
    attr: {
      type: "submit",
      color: configuration.FORM.BUTTON.COLOR.PRIMARY,
    },
    buttons: []
  }
};
export default BranchForm;
