import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import TableHospitalBranchList from '../helpers/tables/hospital-branch-list-table';
import { TableComponent } from '../common/table/table.component';
import { ApiService } from '../service/api.service';
import { ActivatedRoute } from '@angular/router';
import BranchButton from '../helpers/forms/create-branch-button';

@Component({
  selector: 'app-hospital-branch-list',
  templateUrl: './hospital-branch-list.component.html',
  styleUrls: ['./hospital-branch-list.component.css']
})
export class HospitalBranchListComponent implements OnInit {
  _tableHospitalBranchList = TableHospitalBranchList;
  _branchButton=BranchButton
  @ViewChildren(TableComponent) tableComponent: QueryList<TableComponent>;
  id = "";

  constructor(private apiService: ApiService, private router: ActivatedRoute) {
    this.id = this.router.snapshot.params.id;
    console.log(this.id)
  }

  ngOnInit(): void {
    this.initData();
  }

  async initData() {
    // let json = {
    //   where: {
    //     id: this.id
    //   }
    // };
    // let res = await this._apiService.COMMON_API_executeQuery(configuration.TABLE.PATIENT, json);
    let res = await this.apiService.COMMON_API_executeQuery("siaa_admin_branch_details", {
      where: {
        add_hospital_id: this.id,
        is_active : 1
      }
    });
    console.log(res)
    if (res && res.data) {
      setTimeout(() => {
        const tableComponent: any = this.tableComponent.toArray();
        tableComponent[0].refreshData(res.data);
        tableComponent[0].refreshDataPagination(res);
      });
    }
    console.log(res);
  }

  onCreate(){
    window.location.href = '/hospital-branch/'+ this.id;
  }

}
