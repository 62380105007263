
const TableRevisionSummary = {
    header: [
        // { name: "Sl. No", key: "sl_no" },
        // { name: "Name", key: "first_name",function: "getPatientName" },
        // { name: "Age", key: "age" },
        // { name: "Gender", key: "gender" },
        // { name: "Email Id", key: "email_id" },
        // { name: "Phone", key: "phone" },
        // { name: "Date of Surgery", key: "date_of_surgery" },
        // { name: "Cause of Revision", key: "cause_of_revision" },
        // { name: "Time from Index surgery", key: "time_from_index_surgery" },
        // { name: "Procedure", key: "procedure" },
        // { name: "Prosthetic Used", key: "prosthetic_used" },
        // { name: "Company", key: "company" },
        // { name: "Design", key: "design" },
        { name: "Name", key: "name" },
        { name: "UHID", key: "uhid" },
        { name: "Age", key: "age" },
        { name: "gender", key: "gender" },
        { name: "height", key: "height" },
        { name: "weight", key: "weight" },
        { name: "BMI", key: "bmi" },
        { name: "City", key: "city" },
        { name: "country", key: "country" },
        { name: "Phone Number", key: "phone_number" },
        { name: "Operated knee", key: "operated_knee" },
        { name: "Surgery Date", key: "surgery_date" },
        { name: "Surgeon Name", key: "surgeon_name" },
        { name: "Etiology", key: "etiology" },
        { name: "Deformity", key: "deformity" },
        { name: "Other Joint Involvement", key: "other_joint_involvement" },
        { name: "Current Symptoms", key: "current_symptoms" },
        { name: "Onset of symptoms", key: "onset_of_symptoms" },
        { name: "Symptoms aggravated by", key: "symptoms_aggravated_by" },
        { name: "Co morbidities", key: "co_morbidities" },
        { name: "Previous Knee Surgery", key: "previous_knee_surgery" },
        { name: "Intraarticular Inj", key: "intraarticular_inj" },
        { name: "Flexion Deformity", key: "flexion_deformity" },
        { name: "Hyper extension", key: "hyper_extension" },
        { name: "Retro Patellar Tenderness", key: "retro_patellar_tenderness" },
        { name: "Patellar tracking", key: "patellar_tracking" },
        { name: "Quadriceps power", key: "quadriceps_power" },
        { name: "Knee Society score", key: "knee_society_score" },
        { name: "Womac score", key: "womac_score" },
        { name: "SF 36 Score", key: "sf_36_score" },
        { name: "Kujala Score", key: "kujala_score" },
        { name: "Implement In Situ", key: "implement_in_situ" },
        { name: "Deformity", key: "deformity" },
        { name: "Patella", key: "patella" },
        { name: "Stress Fracture", key: "stress_fracture" },
        { name: "VCA", key: "vca" },
        { name: "Availability", key: "availability" },
        { name: "Vitamin D Level", key: "vitamin_d_level" },
        { name: "Vitamin B12 Level", key: "vitamin_b12_level" },
        { name: "procedure", key: "procedure" },
        { name: "Navigation", key: "navigation" },
        { name: "Anaesthesia", key: "anaesthesia" },
        { name: "ASA GRADE", key: "asa_grade" },
        { name: "Tourniquet", key: "tourniquet" },
        { name: "Arthrotomy", key: "arthrotomy" },
        { name: "Tibial Defect", key: "tibial_defect" },
        { name: "Tibial Defect-AORI Classification", key: "tibial_defect_aori_classification" },
        { name: "Patella-outer bridge Classification", key: "patella_outer_bridge_classification" },
        { name: "Varus", key: "varus" },
        { name: "Valgus", key: "valgus" },
        { name: "Drapes", key: "drapes" },
        { name: "Flexion", key: "flexion" },
        { name: "patella resurfacing", key: "patella_resurfacing" },
        { name: "Defect Reconstruction", key: "defect_reconstruction" },
        { name: "cement used", key: "cement_used" },
        { name: "Company", key: "company" },
        { name: "Design", key: "design" },
        { name: "Femoral Size", key: "femoral_size" },
        { name: "Tibial Size", key: "tibial_size" },
        { name: "Insert Poly", key: "insert_poly" },
        { name: "Operative time", key: "operative_time" },
        { name: "Blood Loss", key: "blood_loss" },
        { name: "Thrombo prophylaxis mechanical", key: "thrombo_prophylaxis_mechanical" },
        { name: "Thrombo prophylaxis Pharmacological", key: "thrombo_prophylaxis_pharmacological" },
        { name: "Posterior Slope", key: "posterior_slope" },
        { name: "Hip-Knee-ankle axis", key: "hip_knee_ankle_axis" },
    ],
    data: [
    ],
};
export default TableRevisionSummary;