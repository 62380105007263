import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormComponent } from 'src/app/common/form/form.component';
import { configuration } from 'src/app/configuration';
import FormChangePassword from 'src/app/helpers/forms/change-password-form';
import { ApiService } from 'src/app/service/api.service';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  errorMessage = "";
  successMessage = "";
  _changePassword = FormChangePassword;
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;

  constructor(private apiService: ApiService, public shared: SharedService) { }

  ngOnInit(): void {
  }

  async submitForm(e) {
    this.errorMessage = "";
    this.successMessage = "";
    let json = e.form.value;
    console.log(e.form.value);
    const formComponent: any = this.formComponent.toArray();
    if (formComponent && formComponent[0]) {
      let errors = formComponent[0].runValidation();
      if(errors.length == 0) {
        if(json.confirm_password == json.new_password) {
          this.shared.isLoader = true;
          let res = await this.apiService.PUT_apiRequest(configuration.API_ENDPOINT.CHANGE_PASSWORD, json);
          if(res && res.data) {
            this.successMessage = res.message;            
            formComponent[0].resetAllFormFieldValue();
          } else {
            this.errorMessage = res && res.message ? res.message : "Please try again!";
          }
          this.shared.isLoader = false;
        } else {
          this.errorMessage = "New password and confirm password doesn't match.";
        }
//        if(e.form)
      }
      formComponent[0].resetSubmitButton();
    }
    console.log(e.form.value);
  }
}
