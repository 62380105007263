import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormComponent } from '../common/form/form.component';
import { configuration } from '../configuration';
import FormPrimarySummary from '../helpers/forms/primary-summary-form';
import TablePrimarySummary from '../helpers/tables/primary-summary-table';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'app-primary-summary',
  templateUrl: './primary-summary.component.html',
  styleUrls: ['./primary-summary.component.css']
})
export class PrimarySummaryComponent implements OnInit {

  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;
  _FormPrimarySummary = FormPrimarySummary;
  _TablePrimarySummary = TablePrimarySummary;
  constructor(public _apiService: ApiService, public shared: SharedService) {
    this.shared.currentPage = "report";
  }

  ngOnInit(): void {
  }

  async onSubmit(e) {
    console.log(e.form.value);
    const formComponent: any = this.formComponent.toArray();
    if (formComponent && formComponent[0]) {
      let errors = formComponent[0].runValidation();
      if (errors.length == 0) {
        console.log("formComponent", formComponent[0]);
        let formValues = formComponent[0].getFormValue();
        if (formValues.form.value) {
          let values = JSON.parse(JSON.stringify(formValues.form.value));
          let res = await this._apiService.POST_apiRequest(configuration.API_ENDPOINT.GET_PRIMARY_SUMMMARY, e.form.value);
          if (formComponent && formComponent[0]) {
            formComponent[0].resetSubmitButton();
          }
          if (res && res.data) {
            this._TablePrimarySummary.data = res.data;
          }
          console.log(res)
        }
      } else {
        formComponent[0].resetSubmitButton();
      }
    }
  }
}
