import { configuration } from 'src/app/configuration';

const FormKneePrimaryWomacScoreStiffness = {
  name: "stifness",
  fields: [
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '1. Morning Stiffness',
      name: 'morning_stiffness',
      offsetLabel: "Stiffness",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '2. Stiffness occurring later in the day',
      name: 'stiffness_occurring_later_in_the_day',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },

  ],

};
export default FormKneePrimaryWomacScoreStiffness;

