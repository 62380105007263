<form [formGroup]="form">
    <div class="form-group row">
        <div [ngClass]="field.class.labelDivClass">
            <label class="col-form-label">{{field.label}}</label>
        </div>
        <div [ngClass]="field.class.inputDivClass">
            <div class="row">
                <div class="col-md-6 date-picker-div">
                    <mat-form-field>
                        <input matInput [max]="tomorrow" [value]="field.date_value" [matDatepicker]="picker" (dateChange)="addEvent('change', $event)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-1 ln-35">
                    or
                </div>
                <div class="col-md-5 age-input-box">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group">
                                <input type="text" class="form-control" (keyup)="changeAgeValue($event)" [value]="field.value_age" [formControlName]="field.name+'_age_y'" [placeholder]="'Year'" /> 
                                <i class="form-group__bar"></i>  
                            </div>
                        </div> 
                        <div class="col-md-6">
                            <div class="input-group">                        
                                <input type="text" class="form-control" (keyup)="changeAgeMonthValue($event)" [value]="field.value_age_month" [formControlName]="field.name+'_age_m'" [placeholder]="'Month'" />
                                <i class="form-group__bar"></i>
                            </div>
                                
                        </div>
                    </div>                            
                </div>
            </div>
        </div>
    </div>
</form>