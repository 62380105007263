import { configuration } from "src/app/configuration";

const FormAdminLogin = {
  fields: [
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "User Name",
      name: "username"
    },
    {
        type: configuration.FORM.FIELD.PASSWORD,
        label: "Password",
        name: "password"
    },
  ],
  isSubmitButton: true,
  isDisplayInlineError: true,
  submitButton: {
    type: configuration.FORM.FIELD.BUTTON,
    label: "Sign In",
    name: "submit",
    attr: {
      type: "submit",
      color: configuration.FORM.BUTTON.COLOR.PRIMARY,
    },
    buttons: []
  }
};
export default FormAdminLogin;
