import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormComponent } from '../common/form/form.component';
import { configuration } from '../configuration';
import FormBranchChangePassword from '../helpers/forms/branch-change-password-form';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-branch-change-password',
  templateUrl: './branch-change-password.component.html',
  styleUrls: ['./branch-change-password.component.css']
})
export class BranchChangePasswordComponent implements OnInit {

  _formBranchChangePassword = FormBranchChangePassword
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;
  id = "";

  constructor(private router: ActivatedRoute, private apiService: ApiService, public shared: SharedService, private location: Location) {
    this.id = this.router.snapshot.params.id;
    console.log(this.id)
  }

  ngOnInit(): void {
  }

  async onSubmit() {
    let formComponent = this.formComponent.toArray();
    if (formComponent && formComponent[0]) {
      let values = formComponent[0].getFormValue();
      values = JSON.parse(JSON.stringify(values.form.value));
      this.shared.isLoader = true;
      console.log(values)
      values.admin_branch_id = this.id
      let res = await this.apiService.POST_apiRequest(configuration.API_ENDPOINT.CHANGE_BRANCH_PASSWORD, values);
      // window.location.href = "/surgeon-list";
      this.location.back();
      this.shared.isLoader = false;
    }
  }

}
