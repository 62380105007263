<div class="row">
    <div class="col-md-3">

    </div>
    <div class="col-md-9">
        <app-form (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimaryKujalaScore"></app-form>
    </div>

</div>

<div class="row">
    <div class="col-md-12 text-center mb-4 mt-3">
        <button class="btn btn-primary mr-1" (click)="submit()">Submit</button>
    </div>
</div>