import { Component, OnInit } from '@angular/core';
import TableSample from 'src/app/helpers/tables/sample-table';

@Component({
  selector: 'app-sample-table',
  templateUrl: './sample-table.component.html',
  styleUrls: ['./sample-table.component.css']
})
export class SampleTableComponent implements OnInit {
  _tableParam = TableSample;
  constructor() { }

  ngOnInit(): void {
  }

}
