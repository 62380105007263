import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TableComponent } from '../common/table/table.component';
import { configuration } from '../configuration';
import TableDashboard from '../helpers/tables/dashboard-table';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  _tableDashboard = TableDashboard;
  limit = 10;
  page = 0;
  where = {};
  hospital_id = 0;

  @ViewChildren(TableComponent) tableComponent: QueryList<TableComponent>;

  searchForm = new FormGroup({
    first_name: new FormControl(''),
    uhid: new FormControl('')
  });

  downloadForm = new FormGroup({
    select: new FormControl(''),
  });

  constructor(public apiService: ApiService, private router: Router, public shared: SharedService) {
    this.hospital_id = parseInt(configuration.getLocalStorageValue(configuration.LOCAL_STORAGE.HOSPITAL_ID));
    this.shared.currentPage = "dashboard";
  }

  ngOnInit(): void {
    this.where["add_hospital_id"] = this.hospital_id;
    this.getPatientData(this.page,this.where);
  }

  async getPatientData(page,where) {
    where.is_active = 1;
    // console.log("where",where)
    console.log(this.router.url);
    let json = {
      where: where,
      limit: this.limit,
      page: page
    };
    if (this.router.url == "/knee-patients") {
      this.shared.currentPage = "knee";
      json.where["patient_type"] = "0";
    }
    if (this.router.url == "/hip-patients") {
      this.shared.currentPage = "hip";
      json.where["patient_type"] = "1";
    }
    console.log(json)
    let res = await this.apiService.COMMON_API_executeQuery(configuration.TABLE.PATIENT, json);
    if (res && res.data) {
      setTimeout(() => {
        const tableComponent: any = this.tableComponent.toArray();
        tableComponent[0].refreshData(res.data);
        tableComponent[0].refreshDataPagination(res);
      });
    }
  }

  onChangeOfPage(e) {
    console.log(e);
    this.getPatientData(e-1, this.where);
  }

  async onSerach() {
    console.log("search");
    if(this.searchForm.value.first_name) {
      this.where["first_name"] = { operator: "like", value: this.searchForm.value.first_name };
    }
    if(this.searchForm.value.uhid) {
      this.where["uhid"] = this.searchForm.value.uhid;
    }
   
    this.getPatientData(0, this.where);
  }

  async onDownload() {
    console.log(this.downloadForm.value.select)
    // console.log("dowload")
    let res = await this.apiService.POST_apiRequest(configuration.API_ENDPOINT.DOWNLOAD_REVISION_DATA, {
      patient_type : this.downloadForm.value.select
    });
    if (res && !res.error && res.data.filename) {
      window.open(configuration.IMAGE_URL + res.data.filename);
    }
  }

}
