import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { Observable } from "rxjs";
import { switchMap, debounceTime, map, startWith, finalize } from "rxjs/operators";
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/service/api.service';
import { configuration } from 'src/app/configuration';

@Component({
  selector: "app-autocomplete",
  templateUrl: "./autocomplete.component.html",
  styleUrls: ["./autocomplete.component.css"],
})
export class AutocompleteComponent implements OnInit {
  @Input() field;
  @Output() onValueChange = new EventEmitter();
  @Output() onEnterChange = new EventEmitter();
  @Output() onClickPostFix = new EventEmitter();
  form: FormGroup;
  filteredOptions: Observable<Object[]>;
  @ViewChild('fieldInput') fieldInput: ElementRef;
  isDisplayValidation = false;

  
  constructor(public formBuilder: FormBuilder, public _apiService: ApiService) {

  }

  ngOnInit() {
    this.field.aValue = "";
    this.field.isClearValueAfterSelection = typeof this.field.isClearValueAfterSelection == "undefined" ? true : this.field.isClearValueAfterSelection;
    this.form = this.formBuilder.group({});
    this.form.addControl(
      this.field.name,
      new FormControl(this.field.value)
    );
    if(this.field.validation && this.field.validation.required) {
      this.form.controls[this.field.name].setValidators(Validators.required);
    }
    console.log("this.field.options.model", this.field.options.model);
    if(this.field.options.model) {
      this.form.controls[this.field.name].valueChanges.pipe(
        startWith(''),
        debounceTime(300),
        switchMap(value => this._apiService.COMMON_API_getAutocompleteData(this.field.options.model, value))
      ).subscribe(users => {
        this.field.options.data = users.data;
      });
    } else if(this.field.options.API) {
      this.form.controls[this.field.name].valueChanges.pipe(
        startWith(''),
        debounceTime(300),
        switchMap(value => this._apiService.GET_apiRequest(this.field.options.API + "" + value))
      ).subscribe(users => {
        if(users.data.pages) {
          this.field.options.data = users.data.data;
        } else {
          this.field.options.data = users.data;
        }

        // if(users.data.length == 0) {
        //   this.field.value = {id: 0, name: ""};
        //   this.onValueChange.emit({
        //     field: this.field,
        //     form: this.form
        //   });
        // }
      });
    } else {
      this.filteredOptions = this.form.controls[this.field.name].valueChanges.pipe(
        startWith(''),
        map(value => this._filterGroup(value))
      );
    }

  }

  valueMapper = (option) => {
    return (option && this.field.options ? option[this.field.options.op_value] : "");
  };

  optionSelected(option) {
    this.field.value = option.option.value;
    this.onValueChange.emit({
      e: option,
      field: this.field,
      form: this.form
    });
//    this.field.aValue = "";
    if(this.field.isClearValueAfterSelection) {
      this.field.aValue = "";
      this.form.controls[this.field.name].setValue("");
    }
//    return option[this.field.options.op_value];
  }

  private _filterGroup(value: any): Object[] {
    if (value) {
      if(typeof value == "object") {
        value = value[this.field.options.op_value];
      }
      value =   value.toLowerCase();

      return this.field.options.data
        .filter(option => option[this.field.options.op_value].toLowerCase().indexOf(value) === 0);
    }
    return this.field.options.data;

    // const filterValue = value.toLowerCase();
    // return this.field.options.data; //.filter(option => option[this.field.options.op_value].toLowerCase().indexOf(filterValue) === 0);
  }

  // Set Select box options 
  setFormFieldOptions(name, value, attrname) {
    this.field.options[attrname] = value;
  }

  setFormFieldValue(name, value) {
    this.field.aValue = value.name ? value.name : "";
    this.field.value = value;
    this.form.controls[this.field.name].setValue(value);
    this.onValueChange.emit({
      field: this.field,
      form: this.form
    });
  }

  onBlur(e) {

  }

  postFixClick() {
    console.log("kere");
    this.onClickPostFix.emit({
      e: {},
      field: this.field
    });
  }

  setFocusField() {
    this.fieldInput.nativeElement.focus();
  }

  onChange(e) {
    if(this.field.isAllowFree) {
      this.field.value = {id: 0, name: e.target.value};
      this.onValueChange.emit({
        field: this.field,
        form: this.form
      });
    }
  }

  onEnter(e) {
    if(this.field.options.data && this.field.options.data.length == 0) {
      this.onEnterChange.emit({ field: this.field, e });
    }
  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }

  setFormFieldAttrChild(name, attr, attr1, value) {
    this.field[attr][attr1] = value;
  }

  isRequired() {
    return (this.field.validation && this.field.validation.required);
  }

  isFieldValid() {
    this.isDisplayValidation = true;
    console.log("asd", this.form.controls[this.field.name].value);
    if(this.form.controls[this.field.name].errors) {
      return {
        [this.field.name]: this.form.controls[this.field.name].errors
      };
    }
    return null;
  }

  get _form () {
    return this.form.controls;
  }
}