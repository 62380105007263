<div>
    <ng-container *ngFor="let f of formArray">
        <div class="card mb-2">
            <div class="card-body">
                <i class="fa fa-plus icon"></i>
                <h4 class="card-title">{{f.label}}</h4>
                <app-table [tableParam]="f.form"></app-table>
                <app-form [formParam]="_addOption"></app-form>
                <h5>+Add New Options</h5>
                
            </div>
    
        </div>
    
    </ng-container>
    
    </div>

