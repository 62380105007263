<div class="container" *ngIf="patientData">
  <app-hip-primary-tab [id]="id" [patientData]="patientData" [name]="'hip-preop-clinical'"></app-hip-primary-tab>
  <app-form (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formHipPreopClinicalDetails"></app-form>
  <app-upload-image [id]="id" [name]="'hip-preop-clinical'" [label]="'clinical'" [type]="'hip-preop-clinical'">
  </app-upload-image>
  <h1 class="form-inside-label2">Click for Other scoring systems:</h1>
  <ng-container *ngFor="let score of scores; let i = index">
    <div class="row">
      <div class="col">
        <div class="row border-score" (click)="openModal('harris',i)">
          <div class="col-md-3">
            <img src="assets/images/client-02.png" class="media-object">
          </div>
          <div class="col-md-9">
            <h4 class="media-heading fs-18"> {{score["harris"]}}</h4>
            <p class="fs-18">Harris Hip Score</p>
          </div>

        </div>
      </div>
      <div class="col">

        <div class="row border-score" (click)="openModal('sf36',i)">
          <div class="col-md-3">
            <img src="assets/images/client-02.png" class="media-object">
          </div>
          <div class="col-md-9">
            <h4 class="media-heading fs-18">{{score["sf36"]}}</h4>
            <p class="fs-18">SF36 Score</p>
          </div>

        </div>
      </div>
      <div class="col">

        <div class="row border-score" (click)="openModal('satisfaction',i)">
          <div class="col-md-3">
            <img src="assets/images/client-02.png" class="media-object">
          </div>
          <div class="col-md-9">
            <h4 class="media-heading fs-18">{{score["satisfaction"]}}</h4>
            <p class="fs-18">Satisfaction Score</p>
          </div>
        </div>

      </div>
    </div>
  </ng-container>

  <div class="bottom">
    <button type="button" class="btn btn-primary bottom-button mr-1" (click)="addRow()">Add row</button>
  </div>
  <form class="mt-3">
    <div class="form-group row">
      <label for="inputPassword" class="col-sm-2 col-form-label">Vitamin D Level</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" name="vitamin_d_level" id="vitamin_d_level" placeholder="">
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPassword" class="col-sm-2 col-form-label">Vitamin B12 Level</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" name="vitamin_b12_level" id="vitamin_b12_level" placeholder="">
      </div>
    </div>
  </form>

  <div class="bottom mt-3 mb-3">
    <button type="button" class="btn btn-primary bottom-button" (click)="submitForm()">Continue</button>
  </div>
</div>

<ng-container *ngIf="modal == 'harris'">
  <div (click)="modal = ''" class="modal-backdrop fade show"></div>
  <div class="modal" tabindex="-1" role="dialog" style="display: block;">
    <div class="modal-dialog modal-dialog-big" role="document">
      <div class="modal-content">
        <div class="modal-header grey-div">
          <div class="row c100vw">
            <div class="col-md-4">
              Name: {{patientData.first_name}} {{patientData.middle_name}} {{patientData.last_name}} <br />
              Age: <br />
              Sex:
            </div>
            <div class="col-md-4 text-center">
              <h1 class="bold">
                Harris Hip Score
              </h1>
            </div>
            <div class="col-md-4 text-right">
              <div class="score_div">
                {{currentScore}}
              </div>
              <span class="score-box-close-icon" data-dismiss="modal" (click)="modal = ''">
                <i class="fa fa-remove"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <app-hip-primary-harris-score (changeScoreFinal)="changeScoreFinal($event)" (changeScore)="changeScore($event)" [index]="currentIndex" [type]="'pre'" [id]="id">
          </app-hip-primary-harris-score>
        </div>
        <div class="modal-footer">
          <button (click)="modal = ''" type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="modal == 'sf36'">
  <div (click)="modal = ''" class="modal-backdrop fade show"></div>
  <div class="modal" tabindex="-1" role="dialog" style="display: block;">
    <div class="modal-dialog modal-dialog-big" role="document">
      <div class="modal-content">
        <div class="modal-header grey-div">
          <div class="row c100vw">
            <div class="col-md-4">
              Name: {{patientData.first_name}} {{patientData.middle_name}} {{patientData.last_name}} <br />
              Age: <br />
              Sex:
            </div>
            <div class="col-md-4 text-center">
              <h1 class="bold">
                SF36 Score
              </h1>
            </div>
            <div class="col-md-4 text-right">
              <div class="score_div">
                {{currentScore}}
              </div>
              <span class="score-box-close-icon" data-dismiss="modal" (click)="modal = ''">
                <i class="fa fa-remove"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <app-hip-primary-sf36-score (changeScoreFinal)="changeScoreFinal($event)" (changeScore)="changeScore($event)" [index]="currentIndex" [type]="'pre'" [id]="id">
          </app-hip-primary-sf36-score>
        </div>
        <div class="modal-footer">
          <button (click)="modal = ''" type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="modal == 'satisfaction'">
  <div (click)="modal = ''" class="modal-backdrop fade show"></div>
  <div class="modal" tabindex="-1" role="dialog" style="display: block;">
    <div class="modal-dialog modal-dialog-big" role="document">
      <div class="modal-content">
        <div class="modal-header grey-div">
          <div class="row c100vw">
            <div class="col-md-4">
              Name: {{patientData.first_name}} {{patientData.middle_name}} {{patientData.last_name}} <br />
              Age: <br />
              Sex:
            </div>
            <div class="col-md-4 text-center">
              <h1 class="bold">
                Satisfaction Score
              </h1>
            </div>
            <div class="col-md-4 text-right">
              <div class="score_div">
                {{currentScore}}
              </div>
              <span class="score-box-close-icon" data-dismiss="modal" (click)="modal = ''">
                <i class="fa fa-remove"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <app-hip-primary-satisfaction-score (changeScoreFinal)="changeScoreFinal($event)" (changeScore)="changeScore($event)" [index]="currentIndex" [type]="'pre'" [id]="id">
          </app-hip-primary-satisfaction-score>
        </div>
        <div class="modal-footer">
          <button (click)="modal = ''" type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>