<form [formGroup]="form">
    <div class="form-group row">
        <div [ngClass]="field.class.labelDivClass">
        	<label  *ngIf="field.isDisplayLabel">{{field.label}}</label>
        </div>	
        <div [ngClass]="field.class.inputDivClass"> 
        	<div class="input-group">
            	<textarea type="text" rows="4" class="form-control" [attr.disabled]="field.isDisabled ? true : null" [value]="field.value" [formControlName]="field.name" [placeholder]="field.placeholder"></textarea>
            	<i class="form-group__bar"></i>
            </div>	
        </div>
    </div>
</form>