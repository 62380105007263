import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.css']
})
export class ChipsComponent implements OnInit {

  @Input() data;
  @Output() removeChip = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  setData(data) {
    this.data = data;
  }

  removeCurrentChip(i) {
    this.data.splice(i,1);
    this.removeChip.emit({ index: i})
  }
}
