import { configuration } from "src/app/configuration";

const FormSample = {
  fields: [
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Patient Name",
      name: "patient_name"
    },
    {
        type: configuration.FORM.FIELD.TEXT,
        label: "Patient Mobile",
        name: "patient_mobile"
    },
  ],
  isSubmitButton: true,
  isDisplayInlineError: true,
  submitButton: {
    type: configuration.FORM.FIELD.BUTTON,
    label: "Save",
    name: "submit",
    attr: {
      type: "submit",
      color: configuration.FORM.BUTTON.COLOR.PRIMARY,
    },
    buttons: []
  }
};
export default FormSample;
