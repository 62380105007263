import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import { configuration } from "../configuration";
import { ToastrService } from 'ngx-toastr';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
// import { default as _rollupMoment} from 'moment';

const moment = _moment;

@Injectable({
  providedIn: "root",
})
export class SharedService {
  _config = configuration;
  currentPage = "dashboard";
  isUserLoggedIn = false;
  userData = {
    name: "",
    department: "",
    environment_id: 0,
    folder: "",
    hospital_name: "",
    user_group_id: 2
  }
  userTokenData = null;
  menuData = null;
  subMenuData = null;
  moduleData = null;
  subModuleData = null;
  reportData = null;
  _keyboardEvent = new Subject<String>();
  _printPopupEvent = new Subject<Object>();
  _deletePopupEvent = new Subject<Object>();
  _emailPopupEvent = new Subject<Object>();
  _smsPopupEvent = new Subject<Object>();
  _deleteSuccessEvent = new Subject<Object>();
  _ipdDataChangeEvent = new Subject<Object>();
  _editStoreItem = new Subject<Object>();
  _anyAction = new Subject<Object>();
  validateToken = new Subject<number>();
  canChangeDoctor = true;
  isLoader = false;
  constructor(private _toastrService: ToastrService) {
    const token = configuration.getLocalStorageValue(configuration.LOCAL_STORAGE.USER_TOKEN, null);
    if (token) {
      this.userData.name = configuration.getLocalStorageValue(configuration.LOCAL_STORAGE.USER_NAME, "");
      this.userData.department = configuration.getLocalStorageValue(configuration.LOCAL_STORAGE.USER_DEPARTMENT, "");
      this.userData.folder = configuration.getLocalStorageValue(configuration.LOCAL_STORAGE.USER_FOLDER, "");
      this.userData.environment_id = configuration.getLocalStorageValue(configuration.LOCAL_STORAGE.USER_ENV, "");      
      this.userData.hospital_name = configuration.getLocalStorageValue(configuration.LOCAL_STORAGE.HOSPITAL_NAME, "");      
      this.userData.user_group_id = configuration.getLocalStorageValue(configuration.LOCAL_STORAGE.USER_GROUP_ID, "");      
      this.isUserLoggedIn = true;
    }
  }

  dateDiff(date1, date2) {
    const diff = Math.floor(date1.getTime() - date2.getTime());
    const day = 1000 * 60 * 60 * 24;

    const days = Math.floor(diff / day);
    const years = Math.floor(days / 365);
    const months = Math.floor((days - (years * 365)) / 30);

    return {
      days, months, years
    }
  }

  compareDate(date1, date2) {
    // console.log(date2);
    const diff = Math.floor(date1.getTime() - date2.getTime());
    const day = 1000 * 60 * 60 * 24;
    const days = Math.floor(diff / day);
    return (days == 0 ? true : false);
  }

  getDayDiff(date1, date2) {
    console.log(typeof date1);
    date1 = new Date(date1);
    date2 = new Date(date2);
    let obj = this.dateDiff(date1, date2);
    return obj.days;
  }

  getYMDDate(date, format = "YYYY-MM-DD") {
    let m = moment();
    let newDate = moment(date);
    m.set(newDate.toObject());
    return m.format(format)
  }

  getHMFromDate(date) {
    let m = moment();
    let newDate = moment(date);
    m.set(newDate.toObject());
    return m.format("hh:mm A")
  }

  getHoursMinFromTime(time) {
    let h = time.split(":");
    let m = h[1].split(" ");
    let hr = this.integerValue(h[0]);
    let amPM = time.split(" ");
    if(amPM[1] == "PM") {
      hr = hr + 12;
    }
    return {
      hour: hr,
      min: this.integerValue(m[0])
    }
  }

  getTimeSlotCount(time1, time2, duration) {
    let hm1 = this.getHoursMinFromTime(time1);
    let hm2 = this.getHoursMinFromTime(time2);
    let endTime = hm2.hour*60 + hm2.min;
    let startTime = hm1.hour*60 + hm1.min;
    return ((endTime-startTime)/duration);
  }

  getEndTime(time1, duration) {
    let d = new Date();    
    let h = this.getHoursMinFromTime(time1);
    d.setHours(h.hour);
    d.setMinutes(h.min+duration);
    return this.getHMFromDate(d);
  }

  integerValue(amt) {
    if (amt) {
      let valueInt = parseInt(amt);
      let valueFloat = parseFloat(amt);
      return (valueInt == valueFloat ? valueInt : valueFloat);
    } else {
      return 0;
    }

  }

  integerValueOnly(amt) {
    if (amt) {
      let valueInt = parseInt(amt);
      return valueInt;
    } else {
      return 0;
    }

  }

  getPackUnitByValue(value) {
    for(let t of configuration.STORE_UNIT) {
      if(t.op_value == value) {
        return t.op_key;
      }
    }
    return -1;
  }

  discountValue(amt, discount) {
    if (discount.indexOf("%") != -1) {
      discount = this.integerValue(discount);
      amt = amt - ((amt * discount) / 100);
    } else {
      discount = this.integerValue(discount);
      amt = amt - discount;
    }
    return amt;
  }

  displayAmountValue(amt) {
    return "<i class='fa fa-rupee'></i> " + this.integerValue(amt);
  }

  isValid(ext) {
    let index = 0;
    for (let extArr of configuration.fileExtensions) {
      if (extArr.indexOf(ext) >= 0) {
        return {
          type: index,
          isValid: true,
        }
      }
      index++;
    }
    return {
      type: index,
      isValid: false
    };
  }

  displaySuccessMessage(data: any = {}) {
    data.msg = data.msg ? data.msg : "Your record is saved!";
    data.header = data.header ? data.header : "Success";
    this._toastrService.success(data.msg, data.header);
  }

  displayErrorMessage(data: any = {}) {
    data.msg = data.msg ? data.msg : "Your record is not saved!";
    data.header = data.header ? data.header : "Error";
    this._toastrService.error(data.msg, data.header);
  }

  returnSexOfPatient(obj, seeperator = " / ") {
    if (obj && obj.patient_id_obj && obj.patient_id_obj.sex) {
      return seeperator + (obj.patient_id_obj.sex == 1 ? "Male" : "Female");
    }
    return "";
  }

  returnSexValue(sex) {
    return (sex == 1 ? "Male" : "Female");
  }


  openPrint(h, data) {
    this._printPopupEvent.next({
      h,
      data
    });
  }

  async onFormFieldOnChangeRegistrationForm(e, _formComponent, _apiService, index = 0) {
    console.log("e", e);
    if (e && e.field && e.field.name == "consultation_department_id") {
      if (e.field.value) {
        const resCase = await _apiService.GET_apiRequest(configuration.API_ENDPOINT.GET_STAFF_BY_SUBDEPT + e.field.value);
        if (resCase && !resCase.error && resCase.data) {
          let userData = [];
          for (let u of resCase.data) {
            if (u.UserToUserDetails) {
              userData.push({
                doctor_id: u.user_id,
                doctor_name: u.UserToUserDetails.name
              })
            }
          }
          setTimeout(async () => {
            const formComponent: any = _formComponent.toArray();
            formComponent[index].setFormFieldOptions("consultation_doctor_id", userData);
            if (this.canChangeDoctor) {
              if (userData.length == 1) {
                formComponent[index].setFormFieldValue("consultation_doctor_id", userData[0].doctor_id);
              } else {
                formComponent[index].setFormFieldValue("consultation_doctor_id", "");
              }
            }
            this.canChangeDoctor = true;
          });
        }
      }
    } else if (e && e.field && e.field.name == "discount_type") {
      setTimeout(async () => {
        let isDisplay = false;
        if (e.field.value) {
          isDisplay = true;
        }
        const formComponent: any = _formComponent.toArray();
        formComponent[index].setFormFieldAttr("discount", "isDisplay", isDisplay);
        formComponent[index].setFormFieldAttr("discount_remark", "isDisplay", isDisplay);
      });
    } else if (e && e.field && e.field.name == "category") {
      setTimeout(async () => {
        let isDisplay = false;
        if (e.field.value) {
          isDisplay = true;
        }
        const formComponent: any = _formComponent.toArray();
        formComponent[index].setFormFieldAttr("category_value", "isDisplay", isDisplay);
      });
    } else if (e && e.field && (e.field.name == "special_opd_charge" || e.field.name == "discount")) {
      const formComponent: any = _formComponent.toArray();
      if (formComponent && formComponent[index]) {
        let fValue = formComponent[index].getFormValue(true);
        console.log("fValue", fValue);
        let totalValue = fValue.case_paper_fee ? this.integerValue(fValue.case_paper_fee) : 0;
        let _value = fValue.special_opd_charge;

        if (_value && _value.length > 0) {
          for (let v of _value) {
            totalValue = totalValue + this.integerValue(v.value ? v.value : 0);
          }
        }
        let _disValue = fValue.discount ? fValue.discount : 0;
        if (_disValue) {
          totalValue = this.discountValue(totalValue, _disValue);
        }

        formComponent[index].setFormFieldValue("payment_mode", {
          tvalue: totalValue
        });
        console.log("totalValue", totalValue);
      }

    }
  }

  resetSubmitButton(_formComponent) {
    const formComponent: any = _formComponent.toArray();
    if (formComponent && formComponent[0]) {
      formComponent[0].resetSubmitButton();
    }
  }

  getUnitValue(type) {
    let unit = {
      "1": "unit",
      "2": "gm",
      "3": "mg",
      "4": "ml"
    }
    return (unit[type] ? unit[type] : "");
  }

  getReceipt(type) {
    switch (type) {
      case 0:
        return "Case Paper fee";
      case 1:
        return "Medicine";
      case 5:
      case 11:
        return "Surgery";
      case 9:
        return "Pathology Lab";
      case 4:
        return "IPD";
      case 10:
        return "X-ray";
      case 23:
        return "ECG";
      case 16:
        return "Treatment";
      case 13:
        return "Ped. Vaccination";
      case 19:
      case 14:
        return "ANC Vaccination";
      case 17:
        return "Special OPD Charge";
      case 3:
        return "Panchkarma";
      case 20:
        return "Netra";
      case 21:
        return "Prasuti";
      case 12:
        return "Suvarnaprashan";
      case 24:
        return "Internal Medicine";
      case 26:
        return "Physio Therapy";
      case 27:
        return "PFT";
      case 32:
        return "PAP";
      default:
        return type;
    }
  }

  getMedicinNameWithPack(medicine, pack) {
    pack.medicine_pack_size = (pack.medicine_pack_size ? this.integerValue(pack.medicine_pack_size) + (pack.medicine_pack_unit_text ? " " + pack.medicine_pack_unit_text : "") : null);
    return medicine.medicine_name + (pack.medicine_pack_size && pack.medicine_pack_size != -1 ? " - " + pack.medicine_pack_size : "");
  }

  allowNumber(e) {
    console.log(e, isNaN(e.key));
    if(e.which != 8 && isNaN(e.key)){
      e.preventDefault(); //stop character from entering input
      return false;
    }
  }

  modifyRequest(values, arrayColumns) {
    for (let n of arrayColumns) {
      if (values[n]) {
        values[n] = JSON.stringify(values[n]);
      }
    }
    return values;
  }

  parseRequest(values, arrayColumns) {
    for (let n of arrayColumns) {
      if (values[n]) {
        values[n] = JSON.parse(values[n]);
      }
    }
    return values;
  }

  showHideRevisionFields(formComponent, patientData) {
    console.log("formComponent", formComponent.formParam);
    if(formComponent.formParam && formComponent.formParam.fields) {
      for(let f of formComponent.formParam.fields) {
        if(f.is_revision && patientData.patient_sub_type == 0) {
          console.log(f.name, f.type);
          formComponent.setFormFieldAttr(f.name, "isDisplay", false);
        }
        if(f.is_primary && patientData.patient_sub_type == 1) {
          console.log(f.name, f.type);
          formComponent.setFormFieldAttr(f.name, "isDisplay", false);
        }
      }
    }
  }

}
