<ng-container *ngIf="canLoad">
    <div class="text-center">
        <h3><u>Data on satisfaction</u></h3>
    </div>
    <div class="row">
        <p>1) How well did the surgery relieve the pain in your affected joint?</p>
        <app-form (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySatisfactionDate6MonthScoreArr[0]"></app-form>
        <app-form (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySatisfactionDate12MonthScoreArr[0]"></app-form>
    </div>
    <div class="row">
        <p>2) How well did the surgery increase your ability to perform regular activities?</p>
        <app-form  (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySatisfactionDate6MonthScoreArr[1]"></app-form>
        <app-form  (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySatisfactionDate12MonthScoreArr[1]"></app-form>
    </div>
    <div class="row">
        <p>3) How well did the surgery allow you to perform heavy work or sport activities?</p>
        <app-form  (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySatisfactionDate6MonthScoreArr[2]"></app-form>
        <app-form  (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySatisfactionDate12MonthScoreArr[2]"></app-form>
    </div>
    <div class="row">
        <p>4) How well did the surgery meet your expectations?</p>
        <app-form  (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySatisfactionDate6MonthScoreArr[3]"></app-form>
        <app-form  (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySatisfactionDate12MonthScoreArr[3]"></app-form>
    </div>
    <div class="row">
        <p>5) Rate your overall hospital experience using the response scale;</p>
        <app-form  (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySatisfactionDate6MonthScoreArr[4]"></app-form>
        <app-form  (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySatisfactionDate12MonthScoreArr[4]"></app-form>
    </div>
    
    <div class="text-center">
        <h3><u>Patient's attitude towards further surgery</u></h3>
    </div>
    <div class="row">
        <p>(1) Would you have this operation again if it were required on another joint? ( if applicable )</p>
        <app-form  (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySatisfactionPatient6MonthScoreArr[0]"></app-form>
        <app-form  (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySatisfactionPatient12MonthScoreArr[0]"></app-form>
    </div>
    <div class="row">
        <p>(2) Would you recommend this operation to someone else?</p>
        <app-form  (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySatisfactionPatient6MonthScoreArr[1]"></app-form>
        <app-form  (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySatisfactionPatient12MonthScoreArr[1]"></app-form>
    </div>

    <div class="row">
        <div class="col-md-12 text-center mb-4 mt-3">
            <button class="btn btn-primary mr-1" (click)="submit()">Submit</button>
        </div>
    </div>
</ng-container>
