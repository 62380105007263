
const KneeTableDesign = {
    header: [
        { name: "Company Name", key: "companyName" },
        { name: "Design Name", key: "designName" },
       
        { name: "Edit", key: "edit"},
     
        { name: "Delete", key: "delete"},

    ],
    data: [
    ],
};
export default KneeTableDesign;











