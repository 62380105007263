<!-- <form class="example-form" [formGroup]="form">
    <mat-form-field>
        <input type="text" [placeholder]="field.placeholder" aria-label="Number" matInput [formControlName]="field.name"
            [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <ng-container *ngIf="field.options && field.options.data">
                <mat-option *ngFor="let option of field.options.data | async" [value]="option[field.options.op_key]">
                    {{option[field.options.op_key]}}
                </mat-option>
            </ng-container>
        </mat-autocomplete>
    </mat-form-field>
</form> -->
<form [formGroup]="form">
    <div class="form-group row autocomplete-textbox">
        <label [ngClass]="field.class.labelDivClass" *ngIf="field.isDisplayLabel">
            {{field.label}}
            <span *ngIf="isRequired()" class="text-danger">*</span>
        </label>
        <div [ngClass]="field.class.inputDivClass">
            <mat-form-field floatLabel='never' class="autocomplete_field">
                <div class="input-group">
                    <input #fieldInput (blur)="onBlur($event)" (change)="onChange($event)"
                        (keyup.enter)="onEnter($event)" type="text" [placeholder]="field.placeholder"
                        aria-label="Number" matInput [formControlName]="field.name" [matAutocomplete]="auto"
                        [value]="field.aValue" class="form-control">
                    <div class="input-group-append"  (click)="postFixClick()" *ngIf="field.postfix">
                        <span class="input-group-text" [ngClass]="field.postfix.parent_class ? field.postfix.parent_class : ''">
                            <ng-container *ngIf="field.postfix.class">
                                <i [class]="field.postfix.class"></i>
                            </ng-container>
                        </span>
                    </div>
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event)" [displayWith]="valueMapper">
                        <ng-container *ngIf="!field.options.model && field.options && field.options.data">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option[field.options.op_value]}}
                            </mat-option>
                        </ng-container>
                        <ng-container
                            *ngIf="(field.options.model || field.options.API) && field.options && field.options.data">
                            <mat-option *ngFor="let option of field.options.data" [value]="option">
                                {{option[field.options.op_value]}}
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </div>
            </mat-form-field>
        </div>
    </div>
    <ng-container *ngIf="field.isDisplayInlineError && isDisplayValidation && _form[field.name] && _form[field.name].errors">
        <ng-container *ngIf="_form[field.name].errors.required">
            <div class="row">
                <div [ngClass]="field.class.labelDivClass" *ngIf="field.isDisplayLabel"></div>
                <div [ngClass]="field.class.inputDivClass">
                    <span class="color-red">
                        This field is required!
                    </span>
                </div>
            </div>
        </ng-container>
    </ng-container>
</form>