<div class="row header-siaa">
    <div class="col-md-3 col-xs-12 col-sm-12 logo text-center">
        <img src="/assets/images/siaa_logo.jpg">
    </div>
    <div class="col-md-6 col-xs-12 col-sm-12 title text-center">
        <h1 class="text-center">{{shared.userData.hospital_name}}</h1>
    </div>
    <div class="col-md-3 col-xs-12 col-sm-12 info ">
        <h5>Date : {{todayDate | date:'dd/MM/yyyy'}}</h5>
        <h5>User : {{shared.userData.name}}</h5>
        <h5>Institute : {{shared.userData.hospital_name}}</h5>
    </div>
</div>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <ul class="navbar-nav navbar-left">
                        <li *ngIf="shared.userData.user_group_id != 1"  class="nav-item" [ngClass]="shared.currentPage == 'dashboard' ? 'active' : ''">
                            <a class="nav-link" routerLink="/dashboard"><i class="fa fa-columns mr-2"></i>DASHBOARD
                                <span class="sr-only">(current)</span></a>
                        </li>
                        <li *ngIf="shared.userData.user_group_id == 1" class="nav-item" [ngClass]="shared.currentPage == 'hospital-list' ? 'active' : ''">
                            <a class="nav-link" routerLink="/hospital-management"><i class="fa fa-columns mr-2"></i>HOSPITAL MANAGEMENT
                                <span class="sr-only">(current)</span></a>
                                
                        </li>
                        <li *ngIf="shared.userData.user_group_id == 1" class="nav-item dropdown" [ngClass]="shared.currentPage == 'knee' ? 'active' : ''">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-comment  mr-2"></i> Implants
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" routerLink="/knee-company">Knee Implants Company</a>
                                <a class="dropdown-item" routerLink="/knee-design">Knee Implants Design</a>
                                <a class="dropdown-item" routerLink="/hip-company">Hip Implants Company</a>
                                <a class="dropdown-item" routerLink="/hip-design">Hip Implants Design</a>
                            </div>
                        </li>
                        <li *ngIf="shared.userData.user_group_id == 1" class="nav-item dropdown" [ngClass]="shared.currentPage == 'knee' ? 'active' : ''">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-comment  mr-2"></i> Additional Options
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" routerLink="/additional-options/knee-primary-options">Primary Knee Options</a>
                                <a class="dropdown-item" routerLink="/additional-options/revised-knee-options">Revised Knee Options</a>
                                <a class="dropdown-item" routerLink="/additional-options/primary-hip-options">Primary Hip Options</a>
                                <a class="dropdown-item" routerLink="/additional-options/revised-hip-options">Revised Hip Options</a>
                            </div>
                        </li>
                        <li *ngIf="shared.userData.user_group_id != 1" class="nav-item dropdown" [ngClass]="shared.currentPage == 'knee' ? 'active' : ''">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-comment  mr-2"></i> KNEE
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownSub" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add new patient</a>
                                <div class="submenu dropdown-menu" aria-labelledby="navbarDropdownSub">
                                    <a class="dropdown-item" routerLink="/knee-primary">Knee-Primary</a>
                                    <a class="dropdown-item" routerLink="/knee-revision">Knee-Revision</a>
                                </div>
                                <a class="dropdown-item" routerLink="/knee-patients">View Knee Patients</a>
                            </div>
                        </li>
                        <li *ngIf="shared.userData.user_group_id != 1" class="nav-item dropdown" [ngClass]="shared.currentPage == 'hip' ? 'active' : ''">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-comment  mr-2"></i>HIP
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownSub" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add new patient</a>
                                <div class="submenu dropdown-menu" aria-labelledby="navbarDropdownSub">
                                    <a class="dropdown-item" routerLink="/hip-primary">Hip-Primary</a>
                                    <a class="dropdown-item" routerLink="/hip-revision">Hip-Revision</a>
                                </div>
                                <a class="dropdown-item" routerLink="/hip-patients">View Hip Patients</a>
                            </div>
                        </li>
                        <li class="nav-item dropdown" [ngClass]="shared.currentPage == 'report' ? 'active' : ''">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-digital-tachograph  mr-2"></i>REPORTS
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" routerLink="/summary">Summary</a>
                                <!-- <a class="dropdown-item" routerLink="/revision-summary">Summary</a>
                                <a class="dropdown-item" routerLink="/primary-summary">Summary</a> -->
                                <a class="dropdown-item" routerLink="/pre-op-scores">Pre op Scores</a>
                            </div>
                        </li>
                        <li class="nav-item dropdown" [ngClass]="shared.currentPage == 'custom_search' ? 'active' : ''">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-search mr-2"></i>CUSTOM SEARCH
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" routerLink="/primary-search">Primary Search</a>
                                <a class="dropdown-item" routerLink="/revision-search">Revision Search</a>
                            </div>
                        </li>
                        <li class="nav-item dropdown ml-auto">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <!-- <img src="/assets/images/logo-pro.png" class="logo" alt=""> -->
                                South Indian Arthroplasty Academy - {{shared.userData.name}}
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">

                                <a class="dropdown-item" href="change-password">Change Password</a>
                                <a class="dropdown-item" href="javascript:void(0)" (click)="logOut()">Log Out</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</nav>