
const TablePreOpScores = {
    header: [
        { name: "Sl. No", key: "sl_no" },
        { name: "Surgery Date", key: "surgery_date" },
        { name: "Name", key: "first_name" ,function: "getPatientName"},
        { name: "Age", key: "age" },
        { name: "Gender", key: "gender" },
        { name: "Height", key: "height" },
        { name: "Weight", key: "weight" },
        { name: "Range(ROM)", key: "range" },
        { name: "Knee soci", key: "knee_soci" },
        { name: "Womac", key: "womac" },
        { name: "SF36", key: "sf36" },
        { name: "Kujala", key: "kujala" },
        { name: "Oxford", key: "oxford" },
    ],
    data: [
    ],
};
export default TablePreOpScores;