<ng-container *ngIf="url">
    <h1 class="common-h1">
        {{labels[url].name}}
    </h1>
    <app-form #formComponent [formParam]="_formParam"></app-form>
    <div class="row" *ngIf="url == '/hip-primary' || url == '/hip-revision'">
        <div class="col-md-12 text-center mb-4 mt-3">
            <button class="btn btn-primary mr-1" (click)="submitForm('1')">Left Hip</button>
            <button class="btn btn-primary mr-1" (click)="submitForm('2')">Right Hip</button>
            <button class="btn btn-primary mr-1" (click)="submitForm('3')">Bi-Lateral</button>
        </div>
    </div>
    <div class="row" *ngIf="url == '/knee-primary' || url == '/knee-revision'">
        <div class="col-md-12 text-center mb-4 mt-3">
            <button class="btn btn-primary mr-1" (click)="submitForm('1')">Left Knee</button>
            <button class="btn btn-primary mr-1" (click)="submitForm('2')">Right Knee</button>
            <button class="btn btn-primary mr-1" (click)="submitForm('3')">Bi-Lateral</button>
        </div>
    </div>
</ng-container>