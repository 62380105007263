import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormComponent } from '../common/form/form.component';
import { TableComponent } from '../common/table/table.component';
import { configuration } from '../configuration';
import TableUploadImage from '../helpers/tables/upload-image-table';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.css']
})
export class UploadImageComponent implements OnInit {
  _tableUploadImage = TableUploadImage;
  @Input() id;
  @Input() name;
  @Input() type;
  @Input() label;
  steps = {
    "hip-preop-clinical": "1",
    "hip-radiological-evaluation": "2",
    "hip-postop": "4",
    "knee-preop-clinical": "1",
    "knee-radiological": "2",
    "knee-postop": "4",
  }

  @ViewChildren(TableComponent) tableComponent: QueryList<FormComponent>;
  isSliderOpen = false;
  data = [];
  currentIndex = 0;

  constructor(public _shared: SharedService, public _apiService: ApiService) { }

  ngOnInit(): void {
    this.getImagesData();
  }

  async getImagesData() {
    console.log("test")
    this.data = [];
    let res = await this._apiService.POST_apiRequest(configuration.API_ENDPOINT.GET_PATIENT_DOCUMENT + this.id, {
      page: this.type
    });
    console.log("res",res)
    res = this._apiService.getData(res);
    console.log(res);
    for (let r of res) {
      this.data.push({
        id: r.file_id,
        file_name: r.file_name,
        size: "",
        status: "Uploaded",
        progress: false,
        file: null,
        thumbnail: null,
        tags: r.tags,
        url: configuration.IMAGE_URL + r.file_path,
      });
    }

    this.getExternalImages();
  }

  async getExternalImages() {
    let json1 = {
      where: {
        id: this.id
      }
    };
    let res1 = await this._apiService.COMMON_API_executeQuery(configuration.TABLE.PATIENT, json1);
    if (res1 && res1.data && res1.data[0]) {
      let data = await this._apiService.getImages(res1.data[0].old_patient_id, this.steps[this.name])
      console.log("image", data);
      if(data && data.files) {
        for (let r of data.files) {
          this.data.push({
            id: 0,
            file_name: r.name,
            size: "",
            status: "Uploaded",
            progress: false,
            file: null,
            thumbnail: null,
            tags: "",
            url: r.url,
          });
        }
      }

    }

  }

  changeFile(event) {
    console.log("files",event.target.files);
    let file = event.target.files[0];
    console.log(file);
    let ext = file.name.split(".").pop();
    if (configuration.fileExtensions[0].indexOf(ext) >= 0) {
      setTimeout(async () => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e: any) => {
          this.data.push({
            id: null,
            file_name: file.name,
            size: file.size,
            status: "pending",
            progress: false,
            file: file,
            thumbnail: e.target.result,
            tags: "",
            url: null
          });
        };
      });
    } else {
      this._shared.displayErrorMessage({
        msg: "Invalid image file"
      })
    }

  }


  displayFileSize(val) {
    let size = Math.round(val / 1000);
    if (size < 1000) {
      return size + " kb";
    } else {
      return Math.round(size / 1000) + " mb";
    }
  }

  async startUpload() {
    for (let d of this.data) {
      d.progress = true;
      if(d.file) {
        await this.uploadFile(d);
      } else {
        await this.uploadFileData(d);
      }
    }
    this.getImagesData();
  }

  async uploadFile(d) {
    let formData = new FormData();
    formData.append(d.file.name, d.file);
    formData.append("patient_id", this.id);
    formData.append("page", this.type);
    formData.append("tags", d.tags);
    console.log("formData",formData)
    this._apiService.isUploadImage = true;
    await this._apiService.POST_apiRequest(configuration.API_ENDPOINT.PATIENT_DOCUMENT, formData);
    d.status = "Uploaded";
    d.progress = false;
  }

  async uploadFileData(d) {
    let json = {
      tags: d.tags
    }
    await this._apiService.COMMON_API_updateSingleRecord(configuration.TABLE.PATIENT_DOCUMENT, json, d.id);
    d.status = "Uploaded";
    d.progress = false;
  }

  async deleteImage(i) {
    this._shared.isLoader = true;
    if(this.data[i].id) {
      let json = {
        is_active: 0
      }
      await this._apiService.COMMON_API_updateSingleRecord(configuration.TABLE.PATIENT_DOCUMENT, json, this.data[i].id);
    }
    this.data.splice(i, 1);
    this.getImagesData();
    this._shared.isLoader = false;
  }

  openSlider(index) {
    this.isSliderOpen = true;
    this.currentIndex = index;
  }

  goBack() {
    this.currentIndex--;
  }

  goNext() {
    this.currentIndex++;
  }
}
