<form [formGroup]="form">
    <div class="form-group row">
    	<div [ngClass]="field.class.labelDivClass">
        	<label>{{field.label}}</label>
        </div>	
        <div [ngClass]="field.class.inputDivClass">
        	<div class="input-group">
            	<input type="text" [ngClass]="field.isControlBig ? 'form-control-lg' : ''" class="form-control" [value]="field.value" [formControlName]="field.name" [placeholder]="field.placeholder" />
            	<i class="form-group__bar"></i>
            </div>	
        </div>
    </div>
</form>