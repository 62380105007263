<div class="container">
    <div class="row">
        <div class="col-md-3">
        </div>
        <div class="col-md-6">
            <h1 class="text-center title">South Indian Arthroplasty<br> Academy</h1>
            <p class="text-center sub-title">Sign in to get in touch</p>
            <app-form #formComponent [formParam]="_formAdminlogin" (onSubmit)="onSubmit($event)"></app-form>
            <!-- <p class="text-center">Dont have account yet!</p>
            <div class="text-center">
                <button type="button" class="btn btn-primary">Primary</button>
            </div> -->
        </div>
    </div>
  
</div>