<div class="row">
    <div class="col-md-12 mt-2">
        <h1 class="form-inside-label2">Upload {{label}} images</h1>
        <div>
            <input type="file" (change)="changeFile($event)" class="wh-zero" #fileMedia />
            <button type="button" class="btn btn-primary mr-1" (click)="fileMedia.click()">
                <i class="fa fa-plus"></i> Add files</button>
            <button type="button" class="btn btn-primary mr-1" (click)="startUpload()">
                <i class="fa fa-upload"></i> Start upload
            </button>
            <button type="button" class="btn btn-primary mr-1" disabled>
                <i class="fa fa-stop"> </i> Cancel upload
            </button>
            <!-- <button type="button" class="btn btn-primary mr-1">Delete</button> -->
        </div>
        <hr>
        <h1 class="mid-font">Queue progress:</h1>
        <hr>
        <table class="table">
            <thead>
                <tr>
                    <th>Thumbnail</th>
                    <th>File Name</th>
                    <!-- <th>Size</th> -->
                    <th class="text-center">Progress</th>
                    <th>Status</th>
                    <th>Tags</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let d of data; let i = index">
                    <tr>
                        <td>
                            <img (click)="openSlider(i)" *ngIf="d.thumbnail" class='thumb-image' [src]="d.thumbnail" />
                            <img (click)="openSlider(i)" *ngIf="d.url" class='thumb-image' [src]="d.url" />
                        </td>
                        <td>
                            {{d.file_name}}
                        </td>
                        <!-- <td>
                            {{displayFileSize(d.size)}}
                        </td> -->
                        <td class="text-center">
                            <ng-container *ngIf="d.progress">
                                <i class="fa fa-spin fa-spinner"></i>
                            </ng-container>
                        </td>
                        <td>{{d.status}}</td>
                        <td class="mt-1">
                            <input type="text" class="form-control" [(ngModel)]="d.tags" />
                        </td>
                        <td>
                            <button class="btn btn-danger" (click)="deleteImage(i)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>

                </ng-container>
            </tbody>
        </table>
    </div>

</div>

<ng-container *ngIf="isSliderOpen && data && data.length > 0">
    <div class="custom-carousel-main-div">
        <div class="custom-carousel-slider-div">
            <div class="custom-carousel-inner">
                <ng-container *ngFor="let d of data; let i = index">
                    <div class="custom-carousel-item" [ngClass]="currentIndex == i ? 'active' : '' ">
                        <img *ngIf="d.thumbnail" class="d-block w-100" [src]="d.thumbnail" />
                        <img *ngIf="d.url" class="d-block w-100" [src]="d.url" />
                    </div>
                </ng-container>
            </div>
            <i class="fa fa-close custom-carousel-close-icon cursor" (click)="isSliderOpen = false"></i>
            <a class="carousel-control-prev" *ngIf="currentIndex != 0" href="javascript:void(0)" (click)="goBack()" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" *ngIf="currentIndex != (data.length-1)" href="javascript:void(0)" (click)="goNext()" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</ng-container>