<div class="row">
    <table class="table">
        <thead>
            <tr>
                <th>Medicine</th>
                <th>Packing Size</th>
                <th>Rate</th>
                <th>Discount</th>
                <th>GST</th>
                <th>Qty</th>
                <th>Amount</th>
                <th>Nos.</th>
                <th>Exp.Date</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td></td>
                <td>
                    <select class="form-control">
                        <option>1</option>
                        <option>2</option>
                    </select>
                </td>
                <td><input type="text" class="form-control"></td>
                <td><input type="text" class="form-control"></td>
                <td>
                    <select class="form-control">
                        <option>1</option>
                        <option>2</option>
                    </select>
                </td>
                <td><input type="text" class="form-control"><input type="text" class="form-control"></td>
                <td></td>
                <td><input type="text" class="form-control"></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>