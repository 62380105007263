<div class="container" *ngIf="patientData">
  <app-hip-primary-tab [id]="id" [patientData]="patientData" [name]="'hip-radiological-evaluation'">
  </app-hip-primary-tab>
  <app-form [formParam]="_formHipRadiologicalEvaluations"></app-form>
  <app-upload-image [id]="id" [name]="'hip-radiological-evaluation'" [label]="'radiological'"
    [type]="'hip-radiological-evaluation'"></app-upload-image>
  <div class="bottom">
    <button type="button" class="btn btn-primary bottom-button" (click)="submitForm()">Continue</button>
  </div>

</div>