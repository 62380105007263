<div class="container">
    <app-knee-primary-tab *ngIf="patientData" [patientData]="patientData" [id]="id" [name]="'knee-radiological'"></app-knee-primary-tab>
    <app-form [formParam]="_formRadiologicalEvaluations"></app-form>
    <app-upload-image [id]="id" [name]="'knee-radiological'" [label]="'radiological'" [type]="'knee-radiological'">
    </app-upload-image>
    <form>
        <div class="form-group row">
            <div class="col-sm-3">
                <label for="inputPassword1" class="col-form-label">Vitamin D Level</label>
            </div>
            <div class="col-sm-3">
                <input type="text" [(ngModel)]="vitamin_d_level" name="vitamin_d_level" class="form-control"
                    id="inputPassword1" placeholder="">

            </div>
            <div class="col-sm-4">
                <label class="col-form-label">(Range)</label>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-3">
                <label for="inputPassword2" class="col-form-label">Vitamin B2 Level</label>
            </div>
            <div class="col-sm-3">
                <input type="text" [(ngModel)]="vitamin_b_level" name="vitamin_b_level" class="form-control"
                    id="inputPassword2" placeholder="">

            </div>
            <div class="col-sm-4">
                <label class="col-form-label">(Range)</label>
            </div>
        </div>
    </form>
    <div class="bottom mb-3">
        <button type="button" class="btn btn-primary bottom-button" (click)="submitForm()">Continue</button>
    </div>
</div>