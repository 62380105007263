import { Component, OnInit,QueryList, ViewChildren } from '@angular/core';
import TableSurgeon from '../helpers/tables/surgeon-table';
import { TableComponent } from '../common/table/table.component';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';
import SurgeonButton from '../helpers/forms/create-surgeon-button';

@Component({
  selector: 'app-surgeon-list',
  templateUrl: './surgeon-list.component.html',
  styleUrls: ['./surgeon-list.component.css']
})
export class SurgeonListComponent implements OnInit {
  // Tablesurgeon
  _tableSurgeon = TableSurgeon
  _surgeonButton = SurgeonButton
  // surgeonButton
  @ViewChildren(TableComponent) tableComponent: QueryList<TableComponent>;

  constructor(private apiService: ApiService, public shared: SharedService) { }

  ngOnInit(): void {
    this.initData()
  }

  async initData() {
    // let res = await this.apiService.COMMON_API_getAllRecords("user");
    let json = {
      where: {
        is_active:1
      }
    };
    let res = await this.apiService.COMMON_API_executeQuery("user", json);
    if (res && res.data) {
      setTimeout(() => {
        const tableComponent: any = this.tableComponent.toArray();
        tableComponent[0].refreshData(res.data);
        tableComponent[0].refreshDataPagination(res);
      });
    }
    console.log(res);
  }

  onCreate() {
    window.location.href = "/surgeon-create";
  }

}
