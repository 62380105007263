import { configuration } from "src/app/configuration";

const HipDesign = {
  fields: [
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Company Name",
      name: "companyName"
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Type",
      name: "type",
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Acetabular Cups',
          },
          {
            op_key: "2",
            op_value: ' Thr Stems',
          },
          
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Model Name",
      name: "modelName"
    },
    
    
  ],
  isSubmitButton: true,
  isDisplayInlineError: true,
  submitButton: {
    type: configuration.FORM.FIELD.BUTTON,
    label: "Create Model",
    name: "createModel",
    attr: {
      type: "submit",
      color: configuration.FORM.BUTTON.COLOR.PRIMARY,
    },
    buttons: []
  }
  
};
export default HipDesign;
