import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { configuration } from 'src/app/configuration';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-timepickerduration',
  templateUrl: './timepickerduration.component.html',
  styleUrls: ['./timepickerduration.component.css']
})
export class TimepickerdurationComponent implements OnInit {

  @Input() field;
  @Output() onValueChange = new EventEmitter();
  form: FormGroup;
  amPM = ["am", "pm"];
  timeHour = [
    "12", "01","02","03","04","05","06","07","08","09","10","11"
  ]
  timeMin = [
    "00","15","30","45"
    // "00", "01","02","03","04","05","06","07","08","09","10",
    // "11","12","13","14","15","16","17","18","19","20",
    // "21","22","23","24","25","26","27","28","29","30",
    // "31","32","33","34","35","36","37","38","39","40",
    // "41","42","43","44","45","46","47","48","49","50",
    // "51","52","53","54","55","56","57","58","59"
  ]
  startTime = "";
  endTime = "";
  constructor(public formBuilder: FormBuilder, public _apiService: ApiService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({});
    this.form.addControl(
      this.field.name+'_startTime',
      new FormControl(this.field.value)
    );
    this.form.addControl(
      this.field.name+'_endTime',
      new FormControl(this.field.value)
    );
    this.form.addControl(
      this.field.name,
      new FormControl(this.field.value)
    );
    // this.form.controls[this.field.name+'_endTime'].valueChanges.subscribe(val => {
    //   this.endTime = val;
    //   this.field.value = {
    //     startTime: this.startTime,
    //     endTime: this.endTime,
    //   };
    //   this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});
    // });

  }

  changeEndTime(e) {  
    console.log("changeEndTime");
    this.startTime = e.value;
    let arr = this.startTime.split("_");
    let h = parseInt(arr[0]);
    let changeAMPM = false;
    if(arr[1] == "00") {
      arr[1] = "30";
    } else if(arr[1] == "15") {
      arr[1] = "45";
    } else if(arr[1] == "30") {
      arr[1] = "00";
      h = h + 1;
      changeAMPM = true;
    } else if(arr[1] == "45") {
      arr[1] = "15";
      h = h + 1;
      changeAMPM = true;
    }
    if(h == 12 && changeAMPM) {
      arr[2] = (arr[2] == "am" ? "pm" : "am"); 
    }
    if(h > 12) {
      h = 1;
    }
    arr[0] = (h < 10 ? "0"+h: h+"");
    this.endTime = arr[0]+"_"+arr[1]+"_"+arr[2];
    this.form.controls[this.field.name+'_endTime'].setValue(this.endTime);
    this.field.value = {
      startTime: this.startTime,
      endTime: this.endTime,
    };
    this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});
    console.log(e, this.endTime);
  }

  setFormFieldValue(name, value) {
    console.log(value, "value");
    this.startTime = value.startTime;
    this.endTime = value.endTime;    
    this.form.controls[this.field.name+'_startTime'].setValue(value.startTime);
    this.form.controls[this.field.name+'_endTime'].setValue(value.endTime);
    this.field.value = {
      startTime: value.startTime,
      endTime: value.endTime,
    };
    console.log(this.field.value);
  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }
}
