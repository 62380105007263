import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormComponent } from '../common/form/form.component';
import { configuration } from '../configuration';
import FormPreopClinicalDetails from '../helpers/forms/knee-preop-clinical-details-form';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';
import * as _moment from 'moment';
const moment = _moment;

@Component({
  selector: 'app-knee-preop-clinical',
  templateUrl: './knee-preop-clinical.component.html',
  styleUrls: ['./knee-preop-clinical.component.css']
})
export class KneePreopClinicalComponent implements OnInit {
  _formPreopClinicalDetails = FormPreopClinicalDetails;
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;
  id = "";
  currentData = null;
  patientData = null;
  modal = "";
  arrayColumns = ["co_morbidities", "current_symptoms", "deformity", "other_joint_involvement", "previous_knee_surgeries", "symptoms_aggravated_by"];

  scoreJson = {
    "knee": 0,
    "womac": 0,
    "sf36": 0,
    "kujala": 0,
    "oxford": 0,
  }
  side = 0;
  scores = [JSON.parse(JSON.stringify(this.scoreJson))];
  currentIndex = 0;
  currentScore = 0;
  isBothSide = false;

  scoreTables = [
    { label: "knee", table: configuration.TABLE.KNEE_PRE_OP_SOCIETY_SCORE },
    { label: "womac", table: configuration.TABLE.KNEE_PRE_OP_WOMAC_SCORE },
    { label: "sf36", table: configuration.TABLE.KNEE_PRE_OP_SFTHREESIX },
    { label: "kujala", table: configuration.TABLE.KNEE_PRE_OP_KUJALA_SCORE },
    { label: "oxford", table: configuration.TABLE.KNEE_PRE_OP_OXFORD_SCORE },
  ]

  constructor(private router: ActivatedRoute, private apiService: ApiService, public shared: SharedService) {
    this.id = this.router.snapshot.params.id;
    // this.side = this.router.snapshot.params.side;
    this.shared.currentPage = "knee";
  }

  ngOnInit(): void {
    this.getPatientData();
  }

  async getPatientData() {
    console.log(this.router.url);
    let json1 = {
      where: {
        id: this.id
      }
    };
    let res1 = await this.apiService.COMMON_API_executeQuery(configuration.TABLE.PATIENT, json1);
    // console.log("res1",res1)
    if (res1 && res1.data && res1.data[0]) {
      this.patientData = res1.data[0];
      this.side = this.patientData.surgery_side;
      if(this.patientData.surgery_side == 3) {
        if(this.side == 1 || this.side == 2) {
          this.isBothSide = false;
        } else {
          this.isBothSide = true;
        }

      }
    }

    let json = {
      where: {
        patient_id: this.id
      }
    };
    let res = await this.apiService.COMMON_API_executeQuery(configuration.TABLE.KNEE_PRE_OP, json);
    // console.log("res",res)
    if (res && res.data && res.data.length > 0) {
      // this.currentData = res.data[res.data.length-1];
      this.currentData = {};
      for(let r of res.data) {
        if(this.patientData.surgery_side == r.side || (this.patientData.surgery_side == 3 && this.side == r.side)) {
          for(let tdKey of Object.keys(r)) {
            if(this.arrayColumns.indexOf(tdKey) >= 0) {
              try {
                  let arrC = JSON.parse(r[tdKey]);
                  if(arrC.length != 0) {
                    this.currentData[tdKey] = arrC;
                  }
              } catch (e) {
                console.log("error", e);
              }
            } else {
              if(r[tdKey]) {
                this.currentData[tdKey] = r[tdKey];
              }
            }
          }
        }

      }
      res.data[res.data.length-1];
      console.log("currentData", this.currentData);

      setTimeout(async () => {
        let formComponent = this.formComponent.toArray();
        if (formComponent && formComponent[0]) {
          // for (let n of this.arrayColumns) {
          //   console.log("n", n);
          //   try {
          //     if (this.currentData[n]) {
          //       this.currentData[n] = JSON.parse(this.currentData[n]);
          //     }
          //   } catch (e) {
          //     console.log("error", e);
          //   }

          // }
          console.log("currentData", this.currentData);
          formComponent[0].setAllFormFieldValue(this.currentData);
          if (configuration.OPERATED_KNEE_VALUE[this.patientData.surgery_side]) {
            formComponent[0].setFormFieldValue("date_of_surgery", this.patientData.date);
            formComponent[0].setFormFieldValue("operated_knee", configuration.OPERATED_KNEE_VALUE[this.patientData.surgery_side]);
          }
          this.showHideRevisionFields(formComponent[0]);
        }
      });
    } else {
      setTimeout(async () => {
        let formComponent = this.formComponent.toArray();
        if (formComponent && formComponent[0]) {
          if (configuration.OPERATED_KNEE_VALUE[this.patientData.surgery_side]) {
            formComponent[0].setFormFieldValue("date_of_surgery", this.patientData.date);
            formComponent[0].setFormFieldValue("operated_knee", configuration.OPERATED_KNEE_VALUE[this.patientData.surgery_side]);
          }
          this.showHideRevisionFields(formComponent[0]);
        }
      });
    }

    this.getScores();
  }

  showHideRevisionFields(formComponent) {
    console.log("formComponent", formComponent.formParam);
    if(formComponent.formParam && formComponent.formParam.fields) {
      for(let f of formComponent.formParam.fields) {
        if(f.is_revision && this.patientData.patient_sub_type == 0) {
          console.log(f.name, f.type);
          formComponent.setFormFieldAttr(f.name, "isDisplay", false);
        }
        if(f.is_primary && this.patientData.patient_sub_type == 1) {
          console.log(f.name, f.type);
          formComponent.setFormFieldAttr(f.name, "isDisplay", false);
        }
        if(f.is_bilateral && this.patientData.surgery_side != 3) {
          console.log(f.name, f.type);
          formComponent.setFormFieldAttr(f.name, "isDisplay", false);
        }        
      }
    }
  }

  getScores() {
    for(let t of this.scoreTables) {
      this.getScoresByTable(t);
    }
  }

  async getScoresByTable(t) {
    let json = {
      where: {
        patient_id: this.id
      }
    };
    let res = await this.apiService.COMMON_API_executeQuery(t.table, json);
    // console.log("res lable",res)
    if (res && res.data && res.data[0]) {
      for(let r of res.data) {
        let index = r.index;
        if(!this.scores[index]) {
          this.scores[index] = JSON.parse(JSON.stringify(this.scoreJson));
        }
        this.scores[index][t.label] = r.score;
        console.log("score", t, r);
      }
    }
  }

  submitForm() {
    console.log("submit score")
    setTimeout(async () => {
      let formComponent = this.formComponent.toArray();
      if (formComponent && formComponent[0]) {
        let values = formComponent[0].getFormValue();
        values = JSON.parse(JSON.stringify(values.form.value));
        values.patient_id = this.id;
        values.side = this.side;
        for (let n of this.arrayColumns) {
          if (values[n]) {
            values[n] = JSON.stringify(values[n]);
          }
        }
        for (let key of Object.keys(values)) {
          if (values[key] == null) {
            delete values[key]
          }
        }
        console.log("values",values)
        this.shared.isLoader = true;
        if (this.currentData) {
          await this.apiService.COMMON_API_updateSingleRecord(configuration.TABLE.KNEE_PRE_OP, values, this.currentData.id);
        } else {
          await this.apiService.COMMON_API_insertSingleRecord(configuration.TABLE.KNEE_PRE_OP, values);
        }
        console.log(values.date_of_surgery)
        let date = moment(values.date_of_surgery).format("YYYY-MM-DD")
        // console.log("date",date)
        let values2 = {
          date: date
        }
        console.log("values2",values2)
        await this.apiService.COMMON_API_updateSingleRecord(configuration.TABLE.PATIENT, values2, this.id);
        this.shared.isLoader = false;
        window.location.href = '/knee-radiological/'+this.id;
      }
    });
  }

  changeScore(e) {
    this.currentScore = e.value;
  }

  changeScoreFinal(e) {
    this.scores[this.currentIndex][e.type] = e.value; 
  }

  openModal(key, i) {
    console.log("working")
    this.currentIndex = i; 
    this.modal = key;
    this.currentScore = this.scores[i][key] ? this.scores[i][key] : 0;
  }

  addRow() {
    this.scores.push(JSON.parse(JSON.stringify(this.scoreJson)));
  }

  changeSide(e) {
    console.log(e.target.value);
    window.location.href = '/knee-preop-clinical/'+this.id+'/'+e.target.value;
  }
}
