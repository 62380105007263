import { Component, OnInit, QueryList, ViewChildren  } from '@angular/core';
import FormHipPreopClinicalDetails from '../helpers/forms/hip-preop-clinical-details-form';
import { ActivatedRoute } from '@angular/router';
import { FormComponent } from '../common/form/form.component';
import { configuration } from '../configuration';
import FormPreopClinicalDetails from '../helpers/forms/knee-preop-clinical-details-form';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'app-hip-preop-clinical',
  templateUrl: './hip-preop-clinical.component.html',
  styleUrls: ['./hip-preop-clinical.component.css']
})
export class HipPreopClinicalComponent implements OnInit {
  _formHipPreopClinicalDetails = FormHipPreopClinicalDetails;
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;
  id = "";
  currentData = null;
  patientData = null;
  modal = "";
  arrayColumns = ["current_symptoms", "other_involvement", "co_morbidities", "previous_surgeries", "orif", "deformity", "limb_shortening", "limb_shortening_info1"];

  scoreJson = {
    "harris": 0,
    "sf36": 0,
    "satisfaction": 0,
  }

  scores = [JSON.parse(JSON.stringify(this.scoreJson))];
  currentIndex = 0;
  currentScore = 0;

  scoreTables = [
    { label: "harris", table: configuration.TABLE.HIP_HARRIS_SCORE },
    { label: "sf36", table: configuration.TABLE.HIP_PRE_OP_SFTHREESIX },
    { label: "satisfaction", table: configuration.TABLE.HIP_PRE_OP_SATISFACTION_SCORE },
  ]

  constructor(private router: ActivatedRoute, private apiService: ApiService, public shared: SharedService) {
    this.id = this.router.snapshot.params.id;
    this.shared.currentPage = "hip";
  }

  ngOnInit(): void {
    this.getPatientData();
  }

  async getPatientData() {
    console.log(this.router.url);
    let json1 = {
      where: {
        id: this.id
      }
    };
    let res1 = await this.apiService.COMMON_API_executeQuery(configuration.TABLE.PATIENT, json1);
    if (res1 && res1.data && res1.data[0]) {
      this.patientData = res1.data[0];
    }

    let json = {
      where: {
        patient_id: this.id
      }
    };
    let res = await this.apiService.COMMON_API_executeQuery(configuration.TABLE.HIP_PRE_OP, json);
    if (res && res.data && res.data[0]) {
      this.currentData = res.data[0];
      setTimeout(async () => {
        let formComponent = this.formComponent.toArray();
        if (formComponent && formComponent[0]) {
          for (let n of this.arrayColumns) {
            if (this.currentData[n]) {
              this.currentData[n] = JSON.parse(this.currentData[n]);
            }
          }
          console.log("currentData", this.currentData);
          formComponent[0].setAllFormFieldValue(this.currentData);
          if(configuration.OPERATED_KNEE_VALUE[this.patientData.surgery_side]) {
            formComponent[0].setFormFieldValue("operated_hip", configuration.OPERATED_KNEE_VALUE[this.patientData.surgery_side]);
          }
          this.showHideRevisionFields(formComponent[0]);
        }
      });
    } else {
      setTimeout(async () => {
        let formComponent = this.formComponent.toArray();
        if (formComponent && formComponent[0]) {
          if(configuration.OPERATED_KNEE_VALUE[this.patientData.surgery_side]) {
            formComponent[0].setFormFieldValue("operated_hip", configuration.OPERATED_KNEE_VALUE[this.patientData.surgery_side]);
          }
        }
        this.showHideRevisionFields(formComponent[0]);
      }); 
    }
    this.getScores();
  }

  showHideRevisionFields(formComponent) {
    console.log("formComponent", formComponent.formParam);
    if(formComponent.formParam && formComponent.formParam.fields) {
      for(let f of formComponent.formParam.fields) {
        if(f.is_revision && this.patientData.patient_sub_type == 0) {
          console.log(f.name, f.type);
          formComponent.setFormFieldAttr(f.name, "isDisplay", false);
        }
        if(f.is_primary && this.patientData.patient_sub_type == 1) {
          console.log(f.name, f.type);
          formComponent.setFormFieldAttr(f.name, "isDisplay", false);
        }
      }
    }
  }

  async onFormFieldOnChange(e) {
    console.log("Changing form", e.field.name);
    if(e.field.name == "primary_diagnosis") {
      let op_name = "";
      if(e.field.options && e.field.options.data) {
        for(let op of e.field.options.data) {
          if(op.option_value == e.field.value) {
            op_name = op.option_name;
            break;
          }
        }
      }
      if(op_name != "") {
        const crudData = await this.apiService.GET_apiRequest(configuration.API_ENDPOINT.GET_COMMON_OPTIONS+"hip2 "+op_name);
        if(crudData && !crudData.is_error && crudData.data) {
          setTimeout(async () => {
            let formComponent = this.formComponent.toArray();
            if (formComponent && formComponent[0]) {
                formComponent[0].setFormFieldOptions("primary_diagnosis_options", crudData.data);
            }
          }); 
        }
      }

    }
  }

  getScores() {
    for(let t of this.scoreTables) {
      this.getScoresByTable(t);
    }
  }

  async getScoresByTable(t) {
    let json = {
      where: {
        patient_id: this.id
      }
    };
    let res = await this.apiService.COMMON_API_executeQuery(t.table, json);
    if (res && res.data && res.data[0]) {
      for(let r of res.data) {
        let index = r.index;
        if(!this.scores[index]) {
          this.scores[index] = JSON.parse(JSON.stringify(this.scoreJson));
        }
        this.scores[index][t.label] = r.score;
        console.log("score", t, r);
      }

    }
  }

  submitForm() {
    setTimeout(async () => {
      let formComponent = this.formComponent.toArray();
      if (formComponent && formComponent[0]) {
        let values = formComponent[0].getFormValue();
        values = JSON.parse(JSON.stringify(values.form.value));
        values.patient_id = this.id;
        for (let n of this.arrayColumns) {
          if (values[n]) {
            values[n] = JSON.stringify(values[n]);
          }
        }
        for (let key of Object.keys(values)) {
          if(values[key] == null) {
            delete values[key]
          }
        }
        this.shared.isLoader = true;
        if (this.currentData) {
          await this.apiService.COMMON_API_updateSingleRecord(configuration.TABLE.HIP_PRE_OP, values, this.currentData.id);
        } else {
          await this.apiService.COMMON_API_insertSingleRecord(configuration.TABLE.HIP_PRE_OP, values);
        }
        let values2 = {
          date: values.date_surgery
        }
        await this.apiService.COMMON_API_updateSingleRecord(configuration.TABLE.PATIENT, values2, this.id);
        this.shared.isLoader = false;
        window.location.href = '/hip-radiological-evaluation/'+this.id;
      }
    });
  }

  changeScore(e) {
    this.currentScore = e.value;
  }

  changeScoreFinal(e) {
    this.scores[this.currentIndex][e.type] = e.value; 
  }

  openModal(key, i) {
    this.currentIndex = i; 
    this.modal = key;
    this.currentScore = this.scores[i][key] ? this.scores[i][key] : 0;
  }

  addRow() {
    this.scores.push(JSON.parse(JSON.stringify(this.scoreJson)));
  }
}
