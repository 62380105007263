<form [formGroup]="form">
    <div class="form-group row">
        <label *ngIf="field.class" [ngClass]="field.class.offsetDivClass">
            {{field.offsetLabel}}
        </label>
        <div [ngClass]="field.class.labelDivClass">
            <label *ngIf="field.isDisplayLabel">{{field.label}}</label>
        </div>
        <div [ngClass]="field.class.inputDivClass">
            <ng-container *ngIf="field.options && field.options.data">
                <ng-container *ngFor="let d of field.options.data">
                    <div class="form-check" [ngClass]="{'form-check-inline' : field.attr.isInline}">
                        <input [checked]="value && value.indexOf(d[field.options.op_key]) >= 0 ? 'checked' : null"
                            class="form-check-input" (click)="onCheckboxClick($event,field.options,d)"
                            [name]="field.name" [formControlName]="field.name" [id]="field.name+'_'+d[field.options.op_key]" type="checkbox"
                            [value]="d[field.options.op_key]">
                        <label class="form-check-label cursor" [for]="field.name+'_'+d[field.options.op_key]"
                            [innerHTML]="d[field.options.op_value]">
                        </label>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</form>