import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormComponent } from '../common/form/form.component';
import { configuration } from '../configuration';
import BranchUpdateForm from '../helpers/forms/branch-update';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-branch-update-form',
  templateUrl: './branch-update-form.component.html',
  styleUrls: ['./branch-update-form.component.css']
})
export class BranchUpdateFormComponent implements OnInit {
  _branchUpdateForm = BranchUpdateForm
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;
  currentData = null;
  id = "";

  constructor(private router: ActivatedRoute, private apiService: ApiService, public shared: SharedService,private location: Location) {
    this.id = this.router.snapshot.params.id;
    console.log(this.id)
  }


  ngOnInit(): void {
    this.getData()
  }

  async getData() {
    if (this.id) {
      let res = await this.apiService.COMMON_API_getSingleRecord(configuration.TABLE.ADMIN_BRANCH, this.id);
      console.log(res)
      if (res && res.data && res.data) {
        this.currentData = res.data;
        setTimeout(async () => {
          let formComponent = this.formComponent.toArray();
          if (formComponent && formComponent[0]) {
            formComponent[0].setAllFormFieldValue(this.currentData);
          }
        });
      }
    }
  }

  async onSubmit() {
    let formComponent = this.formComponent.toArray();
    if (formComponent && formComponent[0]) {
      let values = formComponent[0].getFormValue();
      values = JSON.parse(JSON.stringify(values.form.value));
      // console.log(values)
      this.shared.isLoader = true;
      await this.apiService.COMMON_API_updateSingleRecord(configuration.TABLE.ADMIN_BRANCH, values, this.id);
      this.shared.isLoader = false;
      // window.location.href = "/hospital-management";
      this.location.back();
    }
  }

}
