import { Component, OnInit } from '@angular/core';
import HipCompany from 'src/app/helpers/forms/hip-company';
import HipTable from 'src/app/helpers/tables/hip-tablecompany';

@Component({
  selector: 'app-hip-company',
  templateUrl: './hip-company.component.html',
  styleUrls: ['./hip-company.component.css']
})
export class HipCompanyComponent implements OnInit {
  _hipCompany = HipCompany;
  _hipCompanyTable = HipTable;
  constructor() { }

  ngOnInit(): void {
  }

}
