
const TableHospitalBranchList = {
    header: [
        { name: "Admin Branch Name", key: "admin_branch_name", },
        { name: "Admin Branch Email", key: "admin_branch_email_id", },
        { name: "Admin Branch Phone Number", key: "admin_branch_phone_number", },
        { name: "Admin Branch Mobile Number", key: "admin_branch_mobile_number", },
        { name: "User Name", key: "user_name", },
        { name: "Display Name", key: "display_name", },
        // { name: "Update", key: "table-row-function", "component": "link", "row_type": "update_hospital"},
        { name: "Edit",key: "table-row-function", "component": "link", "row_type": "edit_branch" },
        { name: "Delete", key: "table-row-function", "component": "link", "row_type": "delete_branch"  },
        { name: "Change Password",key: "table-row-function", "component": "link", "row_type": "change_password" },
        // { name: "Radiology", key: "radiology", function: "getSubFormLink", class: "text-center" },
        // { name: "Intraop", key: "intraop", function: "getSubFormLink", class: "text-center" },
        // { name: "Postop", key: "postop", function: "getSubFormLink", class: "text-center" },
        // { name: "Postop scores", key: "postopscore", function: "getSubFormLink", class: "text-center" },
        // { name: "Status", key: "status" },
    ],
    data: [
    ],
};
export default TableHospitalBranchList;











