<form [formGroup]="form">
    <div class="form-group row">
        <label [ngClass]="field.class.labelDivClass" *ngIf="field.isDisplayLabel">{{field.label}}</label>
        <div [ngClass]="field.class.inputDivClass">
            <div class="row">
                <div class="col-md-4">
                    <app-text #fieldtextpComponent (onValueChange)="onValueChangeP($event)" [field]="field1"></app-text>
                </div>
                <div class="col-md-4">
                    <app-text #fieldtextmComponent (onValueChange)="onValueChangeM($event)" [field]="field2"></app-text>
                </div>
                <div class="col-md-4">
                    <app-text #fieldtextfComponent [field]="field3"></app-text>
                </div>
            </div>
        </div>
    </div>
</form>

