import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonAppModule } from 'src/app/common-app.module';

@NgModule({
  imports: [
    CommonModule,
    CommonAppModule
  ],
  declarations: []
})
export class FormModule { }
