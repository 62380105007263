<form [formGroup]="form">
    <div class="row">
    	<div [ngClass]="field.class.labelDivClass">
        	<label>{{field.label}}</label>
        </div>	
        <div [ngClass]="field.class.inputDivClass1">
            <app-select  (onValueChange)="onValueChangeInput($event)"  #fieldselectOneComponent [field]="field"></app-select>
        </div>
        <div [ngClass]="field.class.inputDivClass2">
            <app-text  (onValueChange)="onValueChangeInput($event)"  #fieldtextComponent [field]="fieldText"></app-text>
        </div>
    </div>
</form>