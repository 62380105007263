import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hip-primary',
  templateUrl: './hip-primary.component.html',
  styleUrls: ['./hip-primary.component.css']
})
export class HipPrimaryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
