import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HeaderComponent } from './header/header.component';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormModule } from './common/form/form.module';
import { ApiService } from './service/api.service';
import { SharedService } from './service/shared.service';
import { ToastrModule } from 'ngx-toastr';
import { KneePrimaryComponent } from './knee-primary/knee-primary.component';
import { KneeRevisionComponent } from './knee-revision/knee-revision.component';
import { ViewKneePatientsComponent } from './view-knee-patients/view-knee-patients.component';
import { HipPrimaryComponent } from './hip-primary/hip-primary.component';
import { HipRevisionComponent } from './hip-revision/hip-revision.component';
import { ViewHipPatientsComponent } from './view-hip-patients/view-hip-patients.component';
import { RevisionSummaryComponent } from './revision-summary/revision-summary.component';
import { PrimarySummaryComponent } from './primary-summary/primary-summary.component';
import { PreOpScoresComponent } from './pre-op-scores/pre-op-scores.component';
import { TableRowFunctionService } from './common/table-row-function.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { SampleTableComponent } from './sample/sample-table/sample-table.component';
import { SampleFormComponent } from './sample/sample-form/sample-form.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonAppModule } from './common-app.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UploadImageComponent } from './upload-image/upload-image.component';

import { KneeIntraoperativeComponent } from './knee-intraoperative/knee-intraoperative.component';
import { KneeRadiologicalEvaluationsComponent } from './knee-radiological-evaluations/knee-radiological-evaluations.component';
import { KneePreopClinicalComponent } from './knee-preop-clinical/knee-preop-clinical.component';
import { KneePostopRadiographicComponent } from './knee-postop-radiographic/knee-postop-radiographic.component';
import { KneePostopScoreComponent } from './knee-postop-score/knee-postop-score.component';
import { HipPreopClinicalComponent } from './hip-preop-clinical/hip-preop-clinical.component';
import { HipRadiologicalEvaluationsComponent } from './hip-radiological-evaluations/hip-radiological-evaluations.component';
import { HipIntraoperativeComponent } from './hip-intraoperative/hip-intraoperative.component';
import { HipPostopRadiographicComponent } from './hip-postop-radiographic/hip-postop-radiographic.component';
import { HipPostopScoreComponent } from './hip-postop-score/hip-postop-score.component';
import { KneePrimaryOxfordScoreComponent } from './knee-primary/knee-primary-oxford-score/knee-primary-oxford-score.component';
import { KneePrimaryKujalaScoreComponent } from './knee-primary/knee-primary-kujala-score/knee-primary-kujala-score.component';
import { KneePrimarySf36Component } from './knee-primary/knee-primary-sf36/knee-primary-sf36.component';
import { KneePrimaryWomacScoreComponent } from './knee-primary/knee-primary-womac-score/knee-primary-womac-score.component';
import { KneePrimarySocietyScoreComponent } from './knee-primary/knee-primary-society-score/knee-primary-society-score.component';
import { KneePrimaryDateSatisfactionScoreComponent } from './knee-primary/knee-primary-date-satisfaction-score/knee-primary-date-satisfaction-score.component';
import { KneePrimaryPatientSatisfactionScoreComponent } from './knee-primary/knee-primary-patient-satisfaction-score/knee-primary-patient-satisfaction-score.component';
import { HipPrimaryHarrisScoreComponent } from './hip-primary/hip-primary-harris-score/hip-primary-harris-score.component';
import { HipPrimarySf36ScoreComponent } from './hip-primary/hip-primary-sf36-score/hip-primary-sf36-score.component';
import { KneeDemographyComponent } from './knee-demography/knee-demography.component';
import { HipDemographyComponent } from './hip-demography/hip-demography.component';
import { KneePrimaryTabComponent } from './knee-primary-tab/knee-primary-tab.component';
import { HipPrimaryTabComponent } from './hip-primary-tab/hip-primary-tab.component';
import { HipPrimarySatisfactionScoreComponent } from './hip-primary/hip-primary-satisfaction-score/hip-primary-satisfaction-score.component';
import { PrimarySearchComponent } from './primary-search/primary-search.component';
import { RevisionSearchComponent } from './revision-search/revision-search.component';
import { SummaryComponent } from './summary/summary.component';
import { HospitalListComponent } from './admin/hospital-list/hospital-list.component';
import { HospitalFormfillComponent } from './newform/hospital-formfill/hospital-formfill.component';
import { KneeCompanyComponent } from './implants/knee-company/knee-company.component';
import { KneeDesignComponent } from './secondImplants/knee-design/knee-design.component';
import { HipCompanyComponent } from './thirdImplants/hip-company/hip-company.component';
import { HipDesignComponent } from './fourthImplants/hip-design/hip-design.component';
import { HospitalBranchComponent } from './branch/hospital-branch/hospital-branch.component';
import { PrimaryKneeOptionsComponent } from './additional-options/primary-knee-options/primary-knee-options.component';
import { RevisedKneeOptionsComponent } from './additional-options/revised-knee-options/revised-knee-options.component';
import { PrimaryHipOptionsComponent } from './additional-options/primary-hip-options/primary-hip-options.component';
import { RevisedHipOptionsComponent } from './additional-options/revised-hip-options/revised-hip-options.component';
import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { SurgeonCreateComponent } from './surgeon-create/surgeon-create.component';
import { SurgeonListComponent } from './surgeon-list/surgeon-list.component';
import { HospitalBranchListComponent } from './hospital-branch-list/hospital-branch-list.component';
import { BranchChangePasswordComponent } from './branch-change-password/branch-change-password.component';
import { BranchUpdateFormComponent } from './branch-update-form/branch-update-form.component';
import { DeleteHospitalComponent } from './delete-hospital/delete-hospital.component';
import { DeleteBranchComponent } from './delete-branch/delete-branch.component';
import { DeleteSugeonComponent } from './delete-sugeon/delete-sugeon.component';
import { UpdateSugeonComponent } from './update-sugeon/update-sugeon.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { BranchLoginComponent } from './branch-login/branch-login.component';
import { SurgeonChangePasswordComponent } from './surgeon-change-password/surgeon-change-password.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    KneePrimaryComponent,
    KneeRevisionComponent,
    ViewKneePatientsComponent,
    HipPrimaryComponent,
    HipRevisionComponent,
    ViewHipPatientsComponent,
    RevisionSummaryComponent,
    PrimarySummaryComponent,
    PreOpScoresComponent,
    SampleTableComponent,
    SampleFormComponent,
    DashboardComponent,
    UploadImageComponent,
  
    KneeIntraoperativeComponent,
    KneeRadiologicalEvaluationsComponent,
    KneePreopClinicalComponent,
    KneePostopRadiographicComponent,
    KneePostopScoreComponent,
    HipPreopClinicalComponent,
    HipRadiologicalEvaluationsComponent,
    HipIntraoperativeComponent,
    HipPostopRadiographicComponent,
    HipPostopScoreComponent,
    KneePrimaryOxfordScoreComponent,
    KneePrimaryKujalaScoreComponent,
    KneePrimarySf36Component,
    KneePrimaryWomacScoreComponent,
    KneePrimarySocietyScoreComponent,
    KneePrimaryDateSatisfactionScoreComponent,
    KneePrimaryPatientSatisfactionScoreComponent,
    HipPrimaryHarrisScoreComponent,
    HipPrimarySf36ScoreComponent,
   
    
    HipPrimarySatisfactionScoreComponent,
               KneeDemographyComponent,
               HipDemographyComponent,
               KneePrimaryTabComponent,
               HipPrimaryTabComponent,
               PrimarySearchComponent,
               RevisionSearchComponent,
               SummaryComponent,
               HospitalListComponent,
               HospitalFormfillComponent,
               KneeCompanyComponent,
              //  KneedesignComponent,
               KneeDesignComponent,
               HipCompanyComponent,
               HipDesignComponent,
               HospitalBranchComponent,
               PrimaryKneeOptionsComponent,
               RevisedKneeOptionsComponent,
               PrimaryHipOptionsComponent,
               RevisedHipOptionsComponent,
               ChangePasswordComponent,
               SurgeonCreateComponent,
               SurgeonListComponent,
               HospitalBranchListComponent,
               BranchChangePasswordComponent,
               BranchUpdateFormComponent,
               DeleteHospitalComponent,
               DeleteBranchComponent,
               DeleteSugeonComponent,
               UpdateSugeonComponent,
               AdminLoginComponent,
               BranchLoginComponent,
               SurgeonChangePasswordComponent,
             
 
    // FormComponent, TableComponent, TableRowFunctionComponent, 
    // TextComponent, ButtonComponent, 
    // CheckboxComponent, 
    // DatepickerComponent, 
    // EmailComponent, FileComponent, NoteComponent, NumberComponent, PasswordComponent, RadioComponent, 
    // SelectComponent, AutocompleteComponent, BirthdateComponent, AddressComponent, NameComponent, MobileComponent, IdproofComponent, TextareaComponent, TimepickerComponent, TimepickerdurationComponent, NoofpersonComponent, DateandtimeComponent, ChipsComponent, TableRowFunctionDirective, SpecialOpdChargeComponent, PaymentBoxWithAmountComponent, GridBoxComponent, GridBoxInventoryComponent, GridBoxStoreInventoryComponent, GridBoxMedicineBillComponent, GridBoxStoreRequestComponent    
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    FormModule,
    CommonAppModule,
    BrowserModule,
    DataTablesModule,
    RouterModule, 
    CommonModule, MaterialModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
  ],
  providers: [SharedService, ApiService, HttpClient, DatePipe, TableRowFunctionService],
  bootstrap: [AppComponent]
})
export class AppModule { }
