import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/service/shared.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { configuration } from 'src/app/configuration';
import { TableRowFunctionDirective } from '../table-row-function.directive';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnDestroy, OnInit {
  dtOptions: DataTables.Settings = {};
  @Input() tableParam;
  @Output() openEditForm = new EventEmitter();
  @Output() openDeleteForm = new EventEmitter();
  @Output() openCustomFunction = new EventEmitter();
  @Output() onChangeOfPage = new EventEmitter();
  dtTrigger = new Subject();
  currentSelectedRow = -1
  currentSelectedColumn = -1
  // @ViewChild(TableRowFunctionDirective, {static: false}) tableRowFunction: TableRowFunctionDirective;
  //  @ViewChildren(TableRowFunctionDirective) tableRowFunction:  QueryList<TableRowFunctionDirective>;
  srNo = 0;
  selectedRows = [];
  pages = [];
  recordsFiltered = 0;
  recordsTotal = 0;
  offset = 0;
  currentPage = 1;
  currentType = "custom";
  pagination = 0;
  newAraryPagination = [];

  constructor(public _shared: SharedService, public cd: ChangeDetectorRef, public router: Router, public datePipe: DatePipe) { }

  ngOnInit() {
    console.log("this.tableParam", this.tableParam);
    this.tableParam.header = typeof this.tableParam.header != "undefined" ? this.tableParam.header : [];
    this.tableParam.isHeader = typeof this.tableParam.isHeader != "undefined" ? this.tableParam.isHeader : true;
    this.tableParam.data = typeof this.tableParam.data != "undefined" ? this.tableParam.data : [];
  }

  selectRowColumn(i, j) {
    this.currentSelectedRow = i;
    this.currentSelectedColumn = j;
  }

  editRecord(action, record) {
    this.openEditForm.emit({ action, record, primaryKey: this.tableParam.primaryKey });
  }

  deleteRecord(action, record) {
    this.openDeleteForm.emit({ action, record, primaryKey: this.tableParam.primaryKey });
  }


  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  refreshDataPagination(data) {
    this.pages = data.pages;
    this.recordsFiltered = data.recordsFiltered;
    this.recordsTotal = data.recordsTotal;
    this.offset = data.offset;
    this.currentPage = data.currentPage;
    this.changePaginationHtml();
  }

  refreshDataTable(data) {
    this.tableParam.data = data;
    this.cd.detectChanges();
    this.dtTrigger.next();
  }

  refreshData(data) {
    this.tableParam.data = data;
    this._shared.isLoader = false;
  }

  addDataInTable(data) {
    this.tableParam.data.push(data);
  }

  getValue(data, h) {
    if (h.parent) {
      let parentArr = h.parent.split(",");
      if (parentArr.length > 1) {
        let cData = data;
        for (let parent of parentArr) {
          cData = cData[parent];
        }
        if (cData) {
          return cData[h.key];
        } else {
          return "";
        }
      } else {
        if (data[h.parent]) {
          return data[h.parent][h.key];
        } else {
          return "";
        }
      }

    } else {
      if (h.function) {
        return this[h.function](h, data);
      } else {
        return data[h.key];
      }

    }

  }

  incomeOrExpense = (h, data) => {
    let value = this.integerValue(data[h.key]);
    if (data["type"] == -1) {
      return "<span class='color-red'>-" + value + "</span>";
    }
    return "<span class='color-green'>" + value + "</span>";
  }

  systemToUserDate = (h, data) => {
    return this.datePipe.transform(data[h.key], configuration.DEFAULT.DATE_FORMAT);
  }

  patient_case_view_link = (h, data) => {
    return "<a routerLink='/patient/case/" + data[h.key] + "'>View</a>";
  }

  displayHeight = (h, data) => {
    return (data[h.key] ? data[h.key] + " cm" : "");
  }

  displayWeight = (h, data) => {
    return (data[h.key] ? data[h.key] + " kg" : "");
  }

  integerValue = (amt) => {
    return this._shared.integerValue(amt);
  }

  getPatientName = (h, data) => {
    return data["first_name"] + " " + data["middle_name"] + " " + data["last_name"];
  }

  getPatientType = (h, data) => {
    return (data["patient_sub_type"] == 0 ? "Primary" : "Revision") + " " + (data["patient_type"] == 0 ? "Knee" : "Hip");
  }

  getSurgerySide = (h, data) => {
    return configuration.OPERATED_KNEE_VALUE[data["surgery_side"]];
  }
  
  getSubFormLink = (h, data) => {
    if(data.patient_type == 0) {
      switch (h.key) {
        case "demography":
          return "<div class='text-center'><a href='/knee-demography/"+data.id+"'><i class='fa fa-edit'></i></a></div>";
        case "preop":
          return "<div class='text-center'><a href='/knee-preop-clinical/"+data.id+"'><i class='fa fa-edit'></i></a></div>";
        case "radiology":
          return "<div class='text-center'><a href='/knee-radiological/"+data.id+"'><i class='fa fa-edit'></i></a></div>";
        case "intraop":
          return "<div class='text-center'><a href='/knee-intraoperative/"+data.id+"'><i class='fa fa-edit'></i></a></div>";
        case "postop":
          return "<div class='text-center'><a href='/knee-postop/"+data.id+"'><i class='fa fa-edit'></i></a></div>";
        case "postopscore":
          return "<div class='text-center'><a href='/knee-postop-score/"+data.id+"'><i class='fa fa-edit'></i></a></div>";
      }
    } else if(data.patient_type == 1) {
      switch (h.key) {
        case "demography":
          return "<div class='text-center'><a href='/hip-demography/"+data.id+"'><i class='fa fa-edit'></i></a></div>";
        case "preop":
          return "<div class='text-center'><a href='/hip-preop-clinical/"+data.id+"'><i class='fa fa-edit'></i></a></div>";
        case "radiology":
          return "<div class='text-center'><a href='/hip-radiological-evaluation/"+data.id+"'><i class='fa fa-edit'></i></a></div>";
        case "intraop":
          return "<div class='text-center'><a href='/hip-intraoperative/"+data.id+"'><i class='fa fa-edit'></i></a></div>";
        case "postop":
          return "<div class='text-center'><a href='/hip-postop/"+data.id+"'><i class='fa fa-edit'></i></a></div>";
        case "postopscore":
          return "<div class='text-center'><a href='/hip-postop-score/"+data.id+"'><i class='fa fa-edit'></i></a></div>";
      }
    }

  }

  ecgPftValue = (h, data) => {
    switch (data["ecg_pft"]) {
      case "3":
        return "Yes";
      case "2":
        return (h.key == "pft" ? "Yes" : "No");
      case "1":
        return (h.key == "ecg" ? "Yes" : "No");
      default:
        return "No";
    }
  }

  patientNameWithSalutation(h, data) {
    if (configuration.SALUTATION_BY_ID[data["name_prefix"]]) {
      return configuration.SALUTATION_BY_ID[data["name_prefix"]] + " " + data["name"]
    }
    return data["name"];
  }

  displayImageFromBuffer(h, data) {
    return "<img class='thumb-image' src='"+data[h.key]+"' />";
  }

  textinput(h, data) {
    return "<input type='text' />";
  }

  displayFileSize(h, data) {
    let size = Math.round(data[h.key]/1000);
    if(size < 1000) {
      return size + " kb";
    } else {
      return Math.round(size/1000) + " mb";
    }
  }

  loadComponent() {
    // setTimeout(() => {
    //   console.log(this.tableRowFunction);
    // })

    // this.currentAdIndex = (this.currentAdIndex + 1) % this.ads.length;
    // const adItem = this.ads[this.currentAdIndex];

    // const componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem.component);

    // const viewContainerRef = this.adHost.viewContainerRef;
    // viewContainerRef.clear();

    // const componentRef = viewContainerRef.createComponent<AdComponent>(componentFactory);
    // componentRef.instance.data = adItem.data;
  }
  changePage(p) {
    this.onChangeOfPage.emit(p);
  }

  isLess(offset, recordsFiltered, recordsTotal) {
    return ((offset+recordsFiltered) < recordsTotal);
  }

  changePaginationHtmlP () {
    this.pagination = this.pagination +10;
    console.log(this.pagination);
    this.changePaginationHtml();
  }

  changePaginationHtmlPm () {
    this.pagination = this.pagination -10;
    console.log(this.pagination);
    this.changePaginationHtml();
  }

  async changePaginationHtml () {
    this.newAraryPagination = [];
    let html = "<ul class='pagination'>";
    console.log(this.pagination);
    let start = this.pagination + 1;
    let end = this.pagination + 10;
    console.log(start);
    console.log(end);
    if(this.pages){
      if(this.pagination != 0) {
        this.newAraryPagination.push("Previous");
        //html = html + "<li class='page-item'><a class='paginate_button cursor page-link' (click)='changePaginationHtmlP()'>Previous</a></li>";
      } 
      
      for (let page of this.pages) {
        if(start <= page && page <= end) {
          //if(this.currentPage == (page-1)){
            this.newAraryPagination.push(page);
            //html = html + "<li class='page-item active'><a class='paginate_button cursor page-link' (click)='changePage(p)'>"+page+"</a></li>";
          //} else {
            //html = html + "<li class='page-item'><a class='paginate_button cursor page-link' (click)='changePage(p)'>"+page+"</a></li>";
          //}
        }
      }
      
      if((this.pagination+10) < this.pages.length) {
        this.newAraryPagination.push("Next");
        //html = html + "<li class='page-item'><a class='paginate_button cursor page-link' (click)='changePaginationHtmlP()'>Next</a></li>";
      }
    }
    html = html + "</ul>";
    return html;
  }
}
