<div class="row">
    <div style="display: inline-flex; justify-content: space-between;">
        <div class="float-left">
            <form class="form-inline one-line" [formGroup]="downloadForm">
                <div class="input-group">
                    <select class="form-control" style="width: 180px;" formControlName="select">
                        <option selected value="">Select option..</option>
                        <option value="0">Knee Revision</option>
                        <option value="1">Hip Revision</option>
                    </select>
                    <span class="input-group-btn">
                        <button style="margin-left: 12px;" class="btn btn-primary" (click)="onDownload()" type="submit" tabindex="-1">Download
                            Patients</button>
                    </span>
                </div>
            </form>
        </div>
        <div class="float-end">
            <form class="row g-3 float-right-new" [formGroup]="searchForm">
                <div class="col input-group">
                    <span class="input-group-text">Name:</span>
                    <div class="input-width">
                        <input type="text" class="form-control mr-1" formControlName="first_name" id="inputName"
                            placeholder="Name..">
                    </div>
                    <span class="input-group-text">Uhid:</span>
                    <div class="input-width">
                        <input type="uhid" class="form-control mr-1" formControlName="uhid" id="inputUhid"
                            placeholder="UHID..">
                    </div>
                    <button type="submit" class="btn btn-primary " (click)="onSerach()">
                        <i class="fa fa-search" aria-hidden="true"></i></button>
                </div>

            </form>
        </div>
    </div>
    <div class="col-md-12 mt-2">
        <app-table #tableComponent [tableParam]="_tableDashboard" (onChangeOfPage)="onChangeOfPage($event)">
        </app-table>
    </div>
</div>