import { Component, OnInit, Input, ViewChildren, QueryList, EventEmitter, Output } from '@angular/core';
import { configuration } from 'src/app/configuration';
import { ApiService } from 'src/app/service/api.service';
import { SharedService } from 'src/app/service/shared.service';
import { FormComponent } from '../form/form.component';
import { TableComponent } from '../table/table.component';

@Component({
  selector: 'app-crud',
  templateUrl: './crud.component.html',
  styleUrls: ['./crud.component.css']
})
export class CrudComponent implements OnInit {

  @Input() _crudConfig;
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;
  @ViewChildren(TableComponent) tableComponent: QueryList<TableComponent>;
  @Output() onCrudFormFieldOnChange = new EventEmitter();
  @Output() onCrudDownload = new EventEmitter();
  @Output() onCrudPDF = new EventEmitter();
  isAddNewFormDisplay = false;
  currentEditID = 0;
  currentDeleteData = null;
  isDeletePopupOpen = false;
  isRefresh = false;
  isCrudRefreshed = true;
  _formParam = null;

  constructor(public _apiService: ApiService, public _shared: SharedService) { }

  ngOnInit() {
    console.log(this._crudConfig);
    this.initData();
  }

  refreshCRUDData(config) {
    this._crudConfig = config;
    this.isCrudRefreshed = false;
    setTimeout( () => {
      this.isAddNewFormDisplay = false;
      this.isCrudRefreshed = true;
      this.initData();
    });
    console.log(this._crudConfig);

  }
  
  updateData(data) {
    setTimeout(() => {
      const tableComponent: any = this.tableComponent.toArray();
      tableComponent[0].refreshData(data);
    });
  }

  async initData() {
    if(this._crudConfig.tableConfig.API) {
      if(this._crudConfig.tableConfig.API.type == configuration.API_TYPE.COMMON) {
        const crudData = await this._apiService.COMMON_API_getAllRecords(this._crudConfig.tableConfig.API.getModel ? this._crudConfig.tableConfig.API.getModel : this._crudConfig.tableConfig.API.model);
        if(crudData && !crudData.is_error && crudData.data) {
//          this._crudConfig.tableConfig.tableParam.data = crudData.data;
          setTimeout( () => {
            const tableComponent:any = this.tableComponent.toArray();
            if(this.isRefresh) {
              tableComponent[0].refreshData(crudData.data);
            } else {
              tableComponent[0].refreshDataTable(crudData.data);
            }
            this.isRefresh = false;
          }); 14.4
        }
      } else if(this._crudConfig.tableConfig.API.type == configuration.API_TYPE.CUSTOM) {
        let crudData = null;
        if(!this._crudConfig.tableConfig.API.VIEW.METHOD) {
          crudData = await this._apiService.GET_apiRequest(this._crudConfig.tableConfig.API.VIEW.URL);
        } else if(this._crudConfig.tableConfig.API.VIEW.METHOD == configuration.REQUEST.POST) {
          crudData = await this._apiService.POST_apiRequest(this._crudConfig.tableConfig.API.VIEW.URL, this._crudConfig.tableConfig.API.VIEW.DATA);
        }

        if(crudData && !crudData.is_error && crudData.data) {
//          this._crudConfig.tableConfig.tableParam.data = crudData.data;
          setTimeout( () => {
            const tableComponent:any = this.tableComponent.toArray();
            if(this.isRefresh) {
              tableComponent[0].refreshData(crudData.data);
            } else {
              tableComponent[0].refreshDataTable(crudData.data);
            }
            this.isRefresh = false;
          }); 
        }
      }
    }
  }

  async loadData() {

  }

  openDeleteForm(deleteData) {
    this.isDeletePopupOpen = true;
    const action = deleteData.action;
    if(action.type == this._shared._config.ROW_ACTION.DELETE && action.API) {
      if(action.API.type == configuration.API_TYPE.COMMON || action.API.type == configuration.API_TYPE.CUSTOM) {
        this.currentEditID = deleteData.record[deleteData.primaryKey];
        this.currentDeleteData = deleteData;
        this.isRefresh = true;
      }
    }
  }
  
  closeDeleteBox() {
    this.isDeletePopupOpen = false;
  }

  async runDeleteAction() {
    const action = this.currentDeleteData.action;    
    this.closeDeleteBox();
    if(action.API.type == configuration.API_TYPE.COMMON) {
      await this._apiService.COMMON_API_deleteSingleRecord(action.API.model, this.currentEditID);
    } else {
      await this._apiService.DELETE_apiRequest(action.API.URL + this.currentEditID);
    }
    this.initData();
  }

  async openEditForm(editData) {
    this._formParam = JSON.parse(JSON.stringify(this._crudConfig.formParam));
    const action = editData.action;
    console.log(action);
    if(action.type == this._shared._config.ROW_ACTION.EDIT && action.API) {
      let record = null;
      this.currentEditID = editData.record[editData.primaryKey];
      if(action.API.type == configuration.API_TYPE.COMMON) {
        record = await this._apiService.COMMON_API_getSingleRecord(action.API.model, editData.record[editData.primaryKey]);
      } else if(action.API.type == configuration.API_TYPE.CUSTOM) {
        record = await this._apiService.GET_apiRequest(action.API.URL + this.currentEditID);
      }
        if(record && !record.is_error && record.data) {
          this.isAddNewFormDisplay = true;
          setTimeout( () => {
            const formComponent:any = this.formComponent.toArray();
            console.log("formComponent", formComponent[0]);
            formComponent[0].setAllFormFieldValue(record.data);
          }); 

        }

    }
  }

  addNewRecord() {
    this._formParam = JSON.parse(JSON.stringify(this._crudConfig.formParam));    
    this.isAddNewFormDisplay = !this.isAddNewFormDisplay;
    this.currentEditID = 0;
    if(this.isAddNewFormDisplay) {
      setTimeout( () => {
        const formComponent:any = this.formComponent.toArray();
        formComponent[0].resetAllFormFieldValue();
      });
    }
  }

  async onSubmit(e) {
    let data = JSON.parse(JSON.stringify(e.form.value));
    console.log("data", data);
    if(data.checkbox_field) {
      data.checkbox_field = data.checkbox_field.join(", ");
    }
    if(this._crudConfig.tableConfig.API) {
      if(this._crudConfig.tableConfig.API.type == configuration.API_TYPE.COMMON) {
        if(!this.currentEditID) {
          const crudData = await this._apiService.COMMON_API_insertSingleRecord(this._crudConfig.tableConfig.API.model, data);
          console.log("crudData", crudData);
          if(!crudData || crudData.error) {
            const formComponent:any = this.formComponent.toArray();
            if(formComponent && formComponent[0]) {
              formComponent[0].setFormErrorMessage(configuration.getErrorMessage(crudData));
            }      
          } else {
            this.isAddNewFormDisplay = false;            
            this.initData();
          }
        } else {
          const crudData = await this._apiService.COMMON_API_updateSingleRecord(this._crudConfig.tableConfig.API.model, data, this.currentEditID);
          if(crudData && crudData.error) {
            const formComponent:any = this.formComponent.toArray();
            if(formComponent && formComponent[0]) {
              formComponent[0].setFormErrorMessage(configuration.getErrorMessage(crudData));
            }      
          }
          this.isAddNewFormDisplay = false;
          this.initData();
        }
      } else if(this._crudConfig.tableConfig.API.type == configuration.API_TYPE.CUSTOM) {
        if(!this.currentEditID) {

          if(this._crudConfig.tableConfig.API.ADD.DATA) {
            data = Object.assign(data, this._crudConfig.tableConfig.API.ADD.DATA);
          }
          const crudData = await this._apiService.POST_apiRequest(this._crudConfig.tableConfig.API.ADD.URL, data);
          console.log("crudData", crudData);
          if(!crudData || crudData.error) {
            const formComponent:any = this.formComponent.toArray();
            if(formComponent && formComponent[0]) {
              formComponent[0].setFormErrorMessage(configuration.getErrorMessage(crudData));
            }      
          } else {
            this.isAddNewFormDisplay = false;            
            this.initData();
          }
        } else {
          const crudData = await this._apiService.PUT_apiRequest(this._crudConfig.tableConfig.API.UPDATE.URL+this.currentEditID, data);
          if(crudData && crudData.error) {
            const formComponent:any = this.formComponent.toArray();
            if(formComponent && formComponent[0]) {
              formComponent[0].setFormErrorMessage(configuration.getErrorMessage(crudData));
            }      
          }
          this.isAddNewFormDisplay = false;
          this.initData();
        }
      }
    }
    console.log(data);
  }
  
  onFormFieldOnChange(e) {
    this.onCrudFormFieldOnChange.emit(e);
  }
  crudSetFormFieldOptions(name, value, attrName = "data") {
    setTimeout(() => {
      const formComponent: any = this.formComponent.toArray();
//      formComponent[0].setFormFieldOptions(name, value, attrName);
    });
  }


  _onCrudDownload() {
    this.onCrudDownload.emit(true);
  }

  _onCrudPDF() {
    this.onCrudPDF.emit(true);
  }
}
