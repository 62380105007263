import { configuration } from 'src/app/configuration';

const FormKneePrimaryWomacScorePhysicalFunction = {
  name: "physical_function",
  fields: [
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '1. Descending Stairs',
      name: 'descending_stairs',
      offsetLabel: "Physical Function",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '2. Ascending Stairs',
      name: 'ascending_stairs',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '3. Rising from sitting',
      name: 'rising_from_sitting',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '4. Standing',
      name: 'standing',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '5. Bending to floor',
      name: 'bending_to_floor',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '6. Walking on flat surface',
      name: 'walking_on_flat_surface',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '7. Getting in / Out of car',
      name: 'getting_in_out_of_car',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '8. Going Shopping',
      name: 'going_shopping',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '9. Putting on Socks',
      name: 'putting_on_socks',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '10. Lying in Bed',
      name: 'lying_in_bed',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '11. Taking off Socks',
      name: 'taking_off_socks',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '12. Rising from Bed',
      name: 'rising_from_bed',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '13. Getting in/Out of Bath',
      name: 'getting_in_out_of_bath',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '14. Sitting',
      name: 'sitting',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '15. Getting on/Off Toilet',
      name: 'getting_on_off_toilet',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '16. Heavy Domestic Duties',
      name: 'heavy_domestic_duties',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '17. Light Domestic Duties',
      name: 'light_domestic_duties',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VALUE
      },
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: 'Total Score:',
      name: 'total_score',
    },
  ],
  // isSubmitButton: true,
  // isDisplayInlineError: true,
  // submitButton: {
  //   type: configuration.FORM.FIELD.BUTTON,
  //   label: 'Submit',
  //   name: 'submit',
  //   attr: {
  //     type: 'submit',
  //     color: configuration.FORM.BUTTON.COLOR.PRIMARY,
  //   },
  //   buttons: [],
  // },
};
export default FormKneePrimaryWomacScorePhysicalFunction;

