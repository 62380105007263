<div class="loading" *ngIf="shared.isLoader">Loading&#8230;</div>

<app-header *ngIf="shared.isUserLoggedIn"></app-header>
<div class="container">
    <div class="row">
        <div class="col-md-12 mt-3">
            <router-outlet></router-outlet>
            
        </div>
    </div>
</div>