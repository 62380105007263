import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import TableHospital from 'src/app/helpers/tables/hospital-table';
import Button from 'src/app/helpers/forms/hospital-listbutton';
import { ApiService } from '../../service/api.service';
import { TableComponent } from '../../common/table/table.component';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-hospital-list',
  templateUrl: './hospital-list.component.html',
  styleUrls: ['./hospital-list.component.css']
})
export class HospitalListComponent implements OnInit {
  _tableHospital = TableHospital
  _button = Button
  @ViewChildren(TableComponent) tableComponent: QueryList<TableComponent>;


  constructor(private apiService: ApiService, public shared: SharedService) {
    this.shared.currentPage = "hospital-list";
  }

  ngOnInit(): void {
    this.initData()
  }

  async initData() {
    let json = {
      where: {
        is_active:1
      }
    };
    let res = await this.apiService.COMMON_API_executeQuery("hospital", json);
    // let res = await this.apiService.COMMON_API_getAllRecords("hospital");
    if (res && res.data) {
      setTimeout(() => {
        const tableComponent: any = this.tableComponent.toArray();
        tableComponent[0].refreshData(res.data);
        tableComponent[0].refreshDataPagination(res);
      });
    }
    console.log(res);
  }

  onCreate() {
    window.location.href = "/hospital-formfill";
  }
}
