import { configuration } from "src/app/configuration";

const FormRadiologicalEvaluations = {
  fields: [
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Implant in situ",
      name: "implant_in_situ",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'None',
          },
          {
            op_key: "2",
            op_value: 'Nail',
          },
          {
            op_key: "3",
            op_value: 'Plate',
          },
          {
            op_key: "4",
            op_value: 'Screw',
          },
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Femoral component",
      name: "femoral_component",
      is_revision: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Well fixed',
          },
          {
            op_key: "2",
            op_value: 'Loose',
          },
          {
            op_key: "3",
            op_value: 'Migrated',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Tibial component",
      name: "tibial_component",
      is_revision: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Well fixed',
          },
          {
            op_key: "2",
            op_value: 'Loose',
          },
          {
            op_key: "3",
            op_value: 'Subsidence',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Femoral component rotation",
      name: "femoral_component_rotation",
      is_revision: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Neutral',
          },
          {
            op_key: "2",
            op_value: 'Internally rotated',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Tibial component rotation",
      name: "tibial_component_rotation",
      is_revision: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Neutral',
          },
          {
            op_key: "2",
            op_value: 'Internally rotated',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      name: "availability_of_ct_mri",
      is_revision: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'CT/ MRI Available',
          },
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Deformity",
      name: "deformity",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Absent',
          },

        ],
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Valgus",
      name: "valgus",
      is_primary: true,
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
          { op_key: 1, op_value: '0-10 °', },
          { op_key: 2, op_value: '10-20 °', },
          { op_key: 3, op_value: '20-30 °', },
          { op_key: 4, op_value: '>30 °', },
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Varus",
      name: "varus",
      is_primary: true,
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
          { op_key: 1, op_value: '0-10 °', },
          { op_key: 2, op_value: '10-20 °', },
          { op_key: 3, op_value: 'Extraarticular deformity', },
          { op_key: 4, op_value: '>30 °', },
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Patella",
      name: "patella",
      options: {
        data: [
          { op_key: "1", op_value: "Lateral subluxation" },
          { op_key: "2", op_value: "Patella Baja" },
          { op_key: "3", op_value: "Patella Alta" },
          { op_key: "4", op_value: "Fracture Patella" },
          { op_key: "5", op_value: "Others" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Stress fracture",
      name: "stress_fracture",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'None',
          },
          {
            op_key: "2",
            op_value: 'Tibia',
          },
          {
            op_key: "3",
            op_value: 'Femur',
          },
          {
            op_key: "4",
            op_value: 'Patella',
          },
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "VCA (Valgus correction Angle)",
      name: "vca",
      is_primary: true,
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "CT",
      name: "availability_of_ct",
      offsetLabel: "Availability of",
      is_primary: true,
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.YES_NO_NUMBER
      },
      value: 0
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "MRI",
      name: "availability_of_mri",
      is_primary: true,
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.YES_NO_NUMBER
      },
      value: 0
    },

  ],

};
export default FormRadiologicalEvaluations;
