import { configuration } from 'src/app/configuration';

const FormKneePrimarySf36Score = {
  fields: [
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '1. In general, would you say your health is:',
      name: 'in_general_would_you_say_your_health_is',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: [
          {
            op_key: "100",
            op_value: 'Excellent (100)',
            div_val : 5
          },
          {
            op_key: "75",
            op_value: 'Very good (75)',
            div_val : 5
          },
          {
            op_key: "50",
            op_value: 'Good (50)',
            div_val : 5
          },
          {
            op_key: "25",
            op_value: 'Fair (25)',
            div_val : 5
          },
          {
            op_key: "0",
            op_value: 'Poor (0)',
            div_val : 5
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '2. Compared to one year ago, how would your rate your health in general now?',
      name: 'compared_to_one_year_ago_how_would_your_rate_your_health_in_general_now',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: [
          {
            op_key: "100",
            op_value: 'Much better now than one year ago (100)',
            div_val : 1
          },
          {
            op_key: "75",
            op_value: 'Somewhat better now than one year ago (75)',
            div_val : 1
          },
          {
            op_key: "50",
            op_value: 'About the same (50)',
            div_val : 1
          },
          {
            op_key: "25",
            op_value: 'Somewhat worse now than one year ago (25)',
            div_val : 1
          },
          {
            op_key: "0",
            op_value: 'Much worse now than one year ago (0)',
            div_val : 1
          },
        ],
      },
    },

    {
      type: configuration.FORM.FIELD.NOTE,
      label: "The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?",
      name: "",
      class: {
        labelDivClass: "col-md-6 form-inside-label"
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "3. Vigorous activities, such as running, lifting heavy objects, participating in strenuous sports",
      name: "vigorous_activities_such_as_running_lifting_heavy_objects_participating_in_strenuous_sports",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.LIMITED
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "4. Moderate activities, such as moving a table, pushing a vacuum cleaner, bowling, or playing golf",
      name: "moderate_activities_such_as_moving_a_table_pushing_a_vacuum_cleaner_bowling_or_playing_golf",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.LIMITED
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "5.Lifting or carrying groceries",
      name: "lifting_or_carrying_groceries",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.LIMITED
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "6. Climbing several flights of stairs",
      name: "climbing_several_flights_of_stairs",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.LIMITED
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "7. Climbing one flight of stairs",
      name: "climbing_one_flight_of_stairs",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.LIMITED
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "8. Bending, kneeling, or stooping",
      name: "bending_kneeling_or_stooping",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.LIMITED
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "9. Walking more than a mile",
      name: "walking_more_than_a_mile",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.LIMITED
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "10. Walking several blocks",
      name: "walking_several_blocks",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.LIMITED
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "11. Walking one block",
      name: "walking_one_block",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.LIMITED
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "12. Bathing or dressing yourself",
      name: "bathing_or_dressing_yourself",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.LIMITED
      },

    },
    {
      type: configuration.FORM.FIELD.NOTE,
      label: "During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of your physical health?",
      name: "",
      class: {
        labelDivClass: "col-md-6 form-inside-label"
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "13. Cut down the amount of time you spent on work or other activities",
      name: "cut_down_the_amount_of_time_you_spent_on_work_or_other_activities2",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.YES_0_NO_100
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "14. Accomplished less than you would like",
      name: "accomplished_less_than_you_would_like2",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.YES_0_NO_100
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "15. Were limited in the kind of work or other activities",
      name: "were_limited_in_the_kind_of_work_or_other_activities",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.YES_0_NO_100
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "16. Had difficulty performing the work or other activities (for example, it took extra effort)",
      name: "had_difficulty_performing_the_work_or_other_activities",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.YES_0_NO_100
      },

    },
    {
      type: configuration.FORM.FIELD.NOTE,
      label: "During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of any emotional problems (such as feeling depressed or anxious)?",
      name: "",
      class: {
        labelDivClass: "col-md-6 form-inside-label"
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "17. Cut down the amount of time you spent on work or other activities",
      name: "cut_down_the_amount_of_time_you_spent_on_work_or_other_activities",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.YES_0_NO_100_1
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "18. Accomplished less than you would like",
      name: "accomplished_less_than_you_would_like",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.YES_0_NO_100_1
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "19. Didn't do work or other activities as carefully as usual",
      name: "didnt_do_work_or_other_activities_as_arefully_as_usual",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.YES_0_NO_100_1
      },

    },

    {
      type: configuration.FORM.FIELD.RADIO,
      label: '20. During the past 4 weeks, to what extent has your physical health or emotional problems interfered with your normal social activities with family, friends, neighbors, or groups?',
      name: 'during_the_past_4_weeks_t45',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.RATING
      },

    },

    {
      type: configuration.FORM.FIELD.RADIO,
      label: "21. How much bodily pain have you had during the past 4 weeks?",
      name: "how_much_bodily_pain_have_you_had_during_the_past_4_weeks",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: [
          {
            op_key: "100",
            op_value: 'None (100)',
            div_val : 2
          },
          {
            op_key: "80",
            op_value: 'Very mild (80)',
            div_val : 2
          },
          {
            op_key: "60",
            op_value: 'Mild (60)',
            div_val : 2
          },
          {
            op_key: "40",
            op_value: 'Moderate (40)',
            div_val : 2
          },
          {
            op_key: "20",
            op_value: 'Severe (20)',
            div_val : 2
          },
          {
            op_key: "0",
            op_value: 'Very severe (0)',
            div_val : 2
          },
        ]
      },
    },

    {
      type: configuration.FORM.FIELD.RADIO,
      label: '22. During the past 4 weeks, how much did pain interfere with your normal work (including both work outside the home and housework)?',
      name: 'during_the_past_4_weeks_how_much_did_pain_interfere_with_your_normal_work',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.RATING
      },

    },
    {
      type: configuration.FORM.FIELD.NOTE,
      label: "These questions are about how you feel and how things have been with you during the past 4 weeks. For each question, please give the one answer that comes closest to the way you have been feeling.",
      name: "",
      class: {
        labelDivClass: "col-md-6 form-inside-label"
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "23. Did you feel full of pep?",
      name: "did_you_feel_full_of_pep",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.ALL_TIME_100_0
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "24. Have you been a very nervous person?",
      name: "have_you_been_a_very_nervous_person",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.ALL_TIME_0_100
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "25. Have you felt so down in the dumps that nothing could cheer you up?",
      name: "have_you_felt_so_down_in_the_dumps_that_nothing_could_cheer_you_up",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.ALL_TIME_0_100
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "26. Have you felt calm and peaceful?",
      name: "have_you_felt_calm_and_peaceful",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.ALL_TIME_100_1
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "27. Did you have a lot of energy?",
      name: "did_you_have_a_lot_of_energy",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.ALL_TIME_100_0
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "28. Have you felt downhearted and blue?",
      name: "have_you_felt_downhearted_and_blue",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.ALL_TIME_0_100
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "29. Did you feel worn out?",
      name: "did_you_feel_worn_out",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.ALL_TIME_0_100_1
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "30. Have you been a happy person?",
      name: "have_you_been_a_happy_person",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.ALL_TIME_100_1
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "31. Did you feel tired?",
      name: "did_you_feel_tired",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.ALL_TIME_0_100_1
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "32. During the past 4 weeks, how much of the time has your physical health or emotional problems interfered with your social activities (like visiting with friends, relatives, etc.)?",
      name: "during_the_past_4_weeks",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.ALL_TIME_0_100_2
      },

    },
    {
      type: configuration.FORM.FIELD.NOTE,
      label: "How TRUE or FALSE is each of the following statements for you?",
      name: "",
      class: {
        labelDivClass: "col-md-6 form-inside-label"
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "33. I seem to get sick a little easier than other people.",
      name: "i_seem_to_get_sick_a_little_easier_than_other_people",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.TRUE_FALSE_0_100
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "34. I am as healthy as anybody I know.",
      name: "i_am_as_healthy_as_anybody_i_know",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.TRUE_FALSE_0_100
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "35. I expect my health to get worse.",
      name: "i_expect_my_health_to_get_worse",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.TRUE_FALSE_0_100_1
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "36. My health is excellent.",
      name: "my_health_is_excellent",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },

      options: {
        data: configuration.TRUE_FALSE_100_0
      },

    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Physical Functioning",
      name: "physical_functioning",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },

    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Role limitation due to physical health",
      name: "role_limitation_due_to_physical_health",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },

    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Role limitation due to emotional problem",
      name: "role_limitation_due_to_emotional_problem",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },

    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Energy/fatigue",
      name: "energy_fatigue",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },

    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Emotional well-being",
      name: "emotional_well_being",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },

    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Social functioning",
      name: "social_functioning",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },

    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Pain",
      name: "pain",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },

    {
      type: configuration.FORM.FIELD.TEXT,
      label: "General health",
      name: "general_health",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },
  ],
  // isSubmitButton: true,
  // isDisplayInlineError: true,
  // submitButton: {
  //   type: configuration.FORM.FIELD.BUTTON,
  //   label: 'Submit',
  //   name: 'submit',
  //   attr: {
  //     type: 'submit',
  //     color: configuration.FORM.BUTTON.COLOR.PRIMARY,
  //   },
  //   buttons: [],
  // },
};
export default FormKneePrimarySf36Score;

