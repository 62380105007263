<form [formGroup]="form">
    <div class="form-group row">
    	<div [ngClass]="field.class.labelDivClass">
        	<label *ngIf="field.isDisplayLabel">{{field.label}}
                <span *ngIf="isRequired()" class="text-danger">*</span>
			</label>
        </div>
        <div  [ngClass]="field.class.inputDivClass">
        	<div class="input-group">
            	<input type="password"  [ngClass]="field.isControlBig ? 'form-control-lg' : ''" class="form-control" [formControlName]="field.name" [placeholder]="field.placeholder" />
            	<i class="form-group__bar"></i>
        	</div>
        </div>
    </div>
	<ng-container *ngIf="field.isDisplayInlineError && isDisplayValidation && _form[field.name] && _form[field.name].errors">
        <ng-container *ngIf="_form[field.name].errors.required">
            <div class="row">
                <div [ngClass]="field.class.labelDivClass" *ngIf="field.isDisplayLabel"></div>
                <div [ngClass]="field.class.inputDivClass">
                    <span class="color-red">
                        This field is required!
                    </span>
                </div>
            </div>
        </ng-container>
    </ng-container>
</form>