import { Component, OnInit } from '@angular/core';
import TableViewKneePatient from '../helpers/tables/view-knee-patient-table';

@Component({
  selector: 'app-view-knee-patients',
  templateUrl: './view-knee-patients.component.html',
  styleUrls: ['./view-knee-patients.component.css']
})
export class ViewKneePatientsComponent implements OnInit {
  _tableViewKneePatient = TableViewKneePatient;
  constructor() { }

  ngOnInit(): void {
  }

}
